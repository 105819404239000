// 车牌号码验证
export const isLicensePlate = str => {
    return /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/.test(str)
}

// 车牌号码后五位验证
export const isLicensePlateFooter = str => {
    return /^(((([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/.test(str)
}

// 手机号码验证
export const isPhone = str => {
    return /^1[3456789]\d{9}$/.test(str)
}

// 身份证验证
export const isIdCardValidate = str => {
    const reg=/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return str?reg.test(str):reg
}

// 银行卡验证
export const isBankCard = str => {
    return /^([1-9]{1})(\d{14}|\d{18})$/.test(str)
}

//用户名昵称验证
export const IsName = str => {
    var ta = str.split(""), str_l = 0;
    var str_fa = Number(ta[0].charCodeAt());
    if ((str_fa >= 65 && str_fa <= 90) || (str_fa >= 97 && str_fa <= 122) || (str_fa > 255)) {
        for (var i = 0; i <= ta.length - 1; i++) {
            str_l++;
            if (Number(ta[i].charCodeAt()) > 255) { str_l++; }
        }
        if (str_l >= 4 && str_l <= 16) { return true; }
    }
    return false;
} 

// 密码验证
export const isPassword = str => {
    return /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,16}$/.test(str)
}

/**
 * 密码校验，是否包含数字和字母6-20位
 * @param { validataStr } str 
 * @returns Boole
 */
export const simplePassword = str => {
    return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(str)
}

//车架号验证
export const testVIN = str => {
    return /^[A-Za-z0-9]{17}$/.test(str)
}

//车架号验证
export const testEngine= str => {
    return /^[A-Za-z0-9]{7}$/.test(str)
}
