/**
  * @description: 用户模块
  * @author: 漆红 2020-10-10
  */

import ajax from './index'
import request from './request'

// 用户管理 -> 权限管理列表获取
export const getAuthPageList = (params) => {
    return ajax.post(`/auth/getAuthPage`, params)
}

// 用户管理 -> 删除权限
export const deleteAuth = (params) => {
    return ajax.post(`/auth/deleteAuth`, params)
}

// 用户管理 -> 权限下拉列表获取
export const getAuthList = (authType) => {
    return ajax.get(`/auth/getAuthList/${authType}`)
}

// 用户管理 -> 修改权限
export const updateAuth = (params) => {
    return ajax.post(`/auth/updateAuth`,params)
}

// 获取用户角色权限列表
export const getUserAuth = (authType) => {
    return ajax.get(`/auth/getAuthList/${authType}`)
}

// 用户管理 -> 权限下拉列表获取
export const addAuth = (params) => {
    return ajax.post(`/auth/addAuth`,params)
}

// 用户管理 -> 角色管理列表获取
export const getRoleList = (params) => {
    return ajax.post(`/role/getRolePage`, params)
}

// 用户管理 -> 角色管理删除角色
export const deleteRole = (params) => {
    return ajax.post(`/role/deleteRole`, params)
}

// 用户管理 -> 角色管理更新角色
export const updateRole = (params) => {
    return ajax.post(`/role/updateRole`, params)
}

// 用户管理 -> 角色管理添加角色
export const addRole = (params) => {
    return ajax.post(`/role/addRole`, params)
}

// 用户管理 -> 角色管理查询角色权限信息
export const getRoleAuthTree = (params) => {
    return ajax.post(`/role/getRoleAuthTree`, params)
}

// 用户管理 -> 角色管理查询角色权限信息
export const getPostAuthTree = (postId) => {
    return ajax.get(`/instance/getInstanceAuthTree/${postId}`)
}

// 用户管理 -> 角色管理查询角色权限信息
export const getPostList = () => {
    return ajax.get(`/instance/getInstanceList`)
}

// 用户管理 -> 角色管理查询角色权限信息
export const getUserPostList = (userId) => {
    return ajax.get(`/instance/getUserInstanceList/${userId}`)
}

// 用户管理 -> 角色管理查询角色权限信息
export const userAuthor = (params) => {
    return ajax.post(`/user/userAuthor`,params)
}

// 用户管理 -> 角色管理查询角色权限信息
export const updatePostAuth = (params) => {
    return ajax.post(`/role/updateRoleInstanceAuth`,params)
}

// 用户管理 -> 更新合作伙伴实例权限信息
export const updateOrgRoleInstanceAuth = (params) => {
    return ajax.post(`/role/updateOrgRoleInstanceAuth`, params)
}

// 用户管理 -> 查询所有角色列表
export const getAllRoleList = () => {
    return ajax.get(`/role/getAllRoleList`)
}

// 查询用户角色列表
export const getUserRoleList = () => {
    return ajax.post(`/user/getUserRoleList`)
}

// 获取用户角色权限列表
export const getUserAuthList = (params) => {
    return ajax.post(`/role/getUserRoleInstanceAuth`, params)
}

// 用户管理-添加合作伙伴
export const addOrg = (params) => {
    return ajax.post(`/org/addOrg`, params)
}

// 用户管理-添加合作伙伴
export const updateOrgManagerAccount = (params) => {
    return ajax.post(`/user/updateOrgManagerAccount`, params)
}

// 用户管理-获取合作伙伴管理员信息
export const getOrgManagerAccount = (orgId) => {
    return ajax.get(`/user/getOrgManager/${orgId}`)
}

// 用户管理-获取合作伙伴列表
export const getOrgListByLevel = (level) => {
    return ajax.get(`/org/getOrgList/${level}`)
}

// 用户管理-获取合作伙伴类型列表
export const getOrgTypeList = () => {
    return ajax.get(`/org/getOrgTypeList`)
}


// 用户管理-获取合作伙伴列表
export const getOrgPageList = (params) => {
    return ajax.post(`/org/getOrgPage`,params)
}

// 用户管理-获取合作伙伴列表
export const getDepartmentPageList = (params) => {
    return ajax.post(`/department/getDepartmentPage`,params)
}

// 用户管理-获取合作伙伴列表
export const addDepartment = (params) => {
    return ajax.post(`/department/addDepartment`,params)
}

// 用户管理-获取合作伙伴列表
export const updateDepartment = (params) => {
    return ajax.post(`/department/updateDepartment`,params)
}

// 用户管理-获取合作伙伴列表
export const getParentDepartmentList = (params) => {
    return ajax.post(`/department/getDepartmentByLevel`,params)
}

// 用户管理-获取合作伙伴列表
export const deleteDepartment = (departmentId) => {
    return ajax.get(`/department/deleteDepartment/${departmentId}`)
}

// 用户管理-岗位列表
export const getPostPage = (params) => {
    return ajax.post(`/instance/getInstancePage`,params)
}

// 用户管理-删除岗位
export const deletePost = (postId) => {
    return ajax.get(`/instance/deleteInstance/${postId}`)
}

// 用户管理-岗位添加
export const addPost = (params) => {
    return ajax.post(`/instance/addInstance`,params)
}

// 用户管理-岗位添加
export const updatePost = (params) => {
    return ajax.post(`/instance/updateInstance`,params)
}

// 用户管理-岗位添加
export const getCurrntOrgRoleList = () => {
    return ajax.get(`/role/getRoleList`)
}

// 用户管理-更新合作伙伴
export const updateOrg = (params) => {
    return ajax.post(`/org/updateOrg`,params)
}

// 用户管理-更新合作伙伴
export const getOrgManagerAuth = (orgId) => {
    return ajax.get(`/org/getOrgManagerAuth/${orgId}`)
}

// 用户管理-更新合作伙伴
export const getPostUser = (postId) => {
    return ajax.get(`/instance/getInstanceUser/${postId}`)
}

// 用户管理-更新合作伙伴
export const addUserToPost = (params) => {
    return ajax.post(`/instance/addUserToInstance`,params)
}

// 用户管理-更新合作伙伴
export const getDepartmentList = () => {
    return ajax.get(`/department/getDepartmentTree`)
}

// 用户管理-获取部门详情
export const getDepartmentDetail = (params) => {
    return ajax.get(`/department/getDepartmentDetail/${params}`)
}

// 用户管理-更新合作伙伴
export const getOrgCode = () => {
    return ajax.get(`/org/getOrgCode`)
}

// 用户管理-更新合作伙伴
export const orgManagerAuthor = (params) => {
    return ajax.post(`/org/orgManagerAuthor`,params)
}

// 用户管理-删除合作伙伴
export const deleteOrg = (params) => {
    return ajax.post(`/org/deleteOrg`,params)
}

// 用户管理-获取合作伙伴实例
export const getOrgRoleList = (orgId) => {
    return ajax.get(`/role/getOrgRoleList/${orgId}`)
}

// 用户管理-获取合作伙伴下所有实例
export const getOrgAllRoleList = (orgId) => {
    return ajax.get(`/role/getOrgAllRoleList/${orgId}`)
}

// 用户管理-获取用户列表
export const getUserPageList = (params) => {
    return ajax.post(`/user/getUserPage`,params)
}

// 用户管理-添加用户
export const addUser = (params) => {
    return ajax.post(`/user/addUser`,params)
}

// 用户管理-添加用户
export const updateUserReuse = (params) => {
    return ajax.post(`/user/changeUserCode`,params)
}

// 用户管理-修改用户
export const updateUser = (params) => {
    return ajax.post(`/user/updateUser`,params)
}

// 用户管理-修改用户
export const deleteUser = (params) => {
    return ajax.post(`/user/deleteUser`,params)
}

// 用户管理-用户详情
export const findUserById = (params) => {
    return ajax.post(`/user/findUserById`,params)
}

// 用户管理-用户详情
export const changeUserPassword = (params) => {
    return request.post(`/user/changeUserPassword`,params)
}

// 10001-获取存入ukey的token
export const getUkeyTokenByUserId = (userId) => {
    return request.get(`/v1/ukey/getUkeyTokenByUserId/${userId}`)
}

// 10002-保存用户的ukey
export const saveUserUkeyData = (data) => {
    return request.post(`/v1/ukey/saveUserUkeyData`,data)
}

// 10003-验证ukey
export const validateUkeyData = (data) => {
    return request.post(`/v1/ukey/validateUkeyData`,data)
}

// 10003-验证ukey是否绑定
export const userUkeyBindValidate = (data) => {
    return request.post(`/v1/ukey/userUkeyBindValidate`,data)
}

// 解绑Ukey
export const unbindUkeyByUserId = (userId) => {
    return request.post(`/v1/ukey/unbindUkeyByUserId/${userId}`)
}

