/**
 * @description: 系统配置
 * @author: 漆红 2020-11-5
 */

import Loadable from 'react-loadable'
import { Loading } from 'components'

const FinanceDeduct = Loadable({
    loader: () =>
        import ('./deduct'),
    loading: Loading
})


const FinanceResidual = Loadable({
    loader: () =>
        import ('./residual'),
    loading: Loading
})

const FinanceResidualPay = Loadable({
    loader: () =>
        import ('./pay'),
    loading: Loading
})

const PayPreAudit = Loadable({
    loader: () =>
        import ('./paypre'),
    loading: Loading
})

const FinanceResidualPayVolume = Loadable({
    loader: () =>
        import ('./pay/payVolume'),
    loading: Loading
})

const FinanceResidualDetail = Loadable({
    loader: () =>
        import ('./residual/detail'),
    loading: Loading
})

const DeductDetail = Loadable({
    loader: () =>
        import ('./deduct/detail'),
    loading: Loading
})

const FinanceEntrance = Loadable({
    loader: () =>
        import ('./entrance'),
    loading: Loading
})

const FinancePaySerial = Loadable({
    loader: () =>
        import ('./paySerial'),
    loading: Loading
})

const FinancePaySerialDetail = Loadable({
    loader: () =>
        import ('./paySerial/detail'),
    loading: Loading
})



export default [
    {
        pathname: "/admin/finance",
        title: '财务工作台',
        exact: true,
        roles: '',
        component: FinanceResidual
    },    
    {
        pathname: "/admin/finance/residual",
        title: '残值审核',
        exact: true,
        roles: '',
        component: FinanceResidual
    },
    {
        pathname: "/admin/finance/residual/check",
        title: '查验详情',
        exact: true,
        roles: '',
        component: FinanceResidualDetail
    },
    {
        pathname: "/admin/finance/deduct",
        title: '缺件核对',
        exact: true,
        roles: '',
        component: FinanceDeduct
    },
    // {
    //     pathname: "/admin/finance/paypre",
    //     title: "支付审核",
    //     exact: true,
    //     roles: "",
    //     component: PayPreAudit
    // },
    {
        pathname: "/admin/finance/pay",
        title: "残值支付",
        exact: true,
        roles: "",
        component: FinanceResidualPay
    },
    {
        pathname: "/admin/finance/pay/volume",
        title: "批量支付",
        exact: true,
        roles: "",
        component: FinanceResidualPayVolume
    },
    {
        pathname: "/admin/finance/paySerial",
        title: "残值流水",
        exact: true,
        roles: "",
        component: FinancePaySerial
    },
    {
        pathname: "/admin/finance/paySerial/detail",
        title: "流水详情",
        exact: true,
        roles: "",
        component: FinancePaySerialDetail
    },
    {
        pathname: "/admin/finance/entrance",
        title: "入场车辆",
        exact: true,
        roles: "",
        component: FinanceEntrance
    },
]