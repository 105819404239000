/**
 * @description: 用户模块
 * @author: 漆红 2020-10-10
 */

import ajax from './index'
import request from './request'


// 拖运-报废车信息-每日运营情况
export const queryCurrentDayFreightInfo = () => {
    return ajax.get(`v1/checkLogisticsCompany/queryCurrentDayFreightInfo`)
}


// 拖运-报废车信息-报废车-查询列表
export const getScrapCarPage = (params) => {
    return ajax.post(`v1/checkCarInfo/checkCarList`, params)
}



// 拖运-报废车信息-退回车辆列表
export const returnCarList = (params) => {
    return ajax.post(`/v1/companyFeedbackCar/checkFeedbackCarList`, params)
}

// 拖运-报废车信息-报废车 - 退回详情
export const returnCarDetail = (carId) => {
    return ajax.get(`/v1/companyFeedbackCar/getCheckCarFeedBackInfoByCarId/${carId}`)
}

// 拖运-报废车信息-报废车-装车详情
export const getCheckLoadingOrderCarDetail = (orderId, carId) => {
    return ajax.get(`v1/checkOrderCar/getCheckLoadingOrderCarDetail/${orderId}/${carId}`)
}

// 拖运-报废车信息-报废车-反馈问题
export const getCheckLoadingOrderCarQuestion = (orderId, carId) => {
    return ajax.get(`v1/checkOrderCar/getCheckLoadingOrderCarQuestion/${orderId}/${carId}`)
}

// 拖运-报废车信息-报废车-预约信息
export const getCheckLoadingOrderCarAppointment = (carId) => {
    return ajax.get(`v1/checkOrderCar/getCheckLoadingOrderCarAppointment/${carId}`)
}

// 拖运-报废车信息-报废车-证件信息
export const getConfiscateCertificateList = (orderId, carId) => {
    return ajax.get(`v1/certificate/getConfiscateCertificateList/${orderId}/${carId}`)
}


// 拖运-获取FTP服务器配置
export const getFTPConfig = (licenseNumber, orderId) => {
    return ajax.get(`v1/ftp/queryFtpConfig/${licenseNumber}/${orderId}`)
}

// 拖运-报废车信息-报废车-公司端-查询列表
export const getCompanyCarList = (params) => {
    return ajax.post(`v1/checkCarInfo/companyCarList`, params)
}

// 拖运-基础信息-报废车-保存报废车信息
export const saveCheckCar = (params) => {
        return request.post(`v1/checkCarInfo/saveCheckCar`, params)
    }
    // 拖运-基础信息-报废车-根据ID获取报废车信息
export const getCheckCarDetail = (id) => {
    return ajax.get(`v1/checkCarInfo/getCheckCarDetail/${id}`)
}

// 拖运-基础信息-报废车-发布报废车
export const dispatchCar = (params) => {
    return request.post(`v1/checkCarInfo/dispatchCar`, params)
}

// 拖运-基础信息-报废车-发布报废车
export const publishCar = (params) => {
    return request.post(`v1/checkCarInfo/publishCar`, params)
}

// 拖运-基础信息-报废车-发布报废车抢单
export const publishRobCar = (params) => {
    return request.post(`v1/checkCarInfo/publishRobCar`, params)
}

// 拖运-基础信息-报废车-删除报废车
export const deleteCheckCarByIds = (ids) => {
    console.log(ids);
    return ajax.post(`v1/checkCarInfo/deleteCheckCar`, ids)
}

// 拖运-基础信息-报废车-根据公司ID查询拖车列表
export const getTrailerByCompanyId = (params) => {
    return ajax.get(`v1/checkLogisticsTrailer/getCompanyTrailer`, params)
}

export const getTrailerWeight = () => {
        return request.get(`v1/codeTable/queryChildrenCode/trailer_weight`)
    }
    // 拖运-基础信息-报废车-根据拖车ID查询司机列表
export const getDriverByTrailerId = (id) => {
    return ajax.get(`v1/checkLogisticsDriver/getDriverByTrailerId/${id}`)
}

// 显示拖运公司下的拖运司机列表
export const showCheckDriverList = (companyId) => {
    return request.get(`v1/checkLogisticsDriver/showCheckDriverList/${companyId}`)
}

// 拖运-基础信息-报废车-获取拖运终点列表
export const getAddressList = () => {
    return ajax.get(`v1/checkAddress/listAllAddress`)
}

// 拖运-基础信息-报废车-获取报废车信息
export const getCheckCarByIds = (ids) => {
    return ajax.post(`v1/checkCarInfo/listCheckCarByIds`, ids)
}

// 拖运-基础信息-报废车-发布运单
export const publishCheckCar2Order = (params) => {
    return ajax.post(`v1/checkCarInfo/publishOrder`, params)
}

// 拖运-基础信息-查询省、市、区树形结构数据
export const getAreaTree = () => {
    return ajax.get(`v1/checkArea/getAreaTree`)
}

// 拖运-基础信息-拖运公司-查询列表
export const getLogisticsCompanyPageList = (params) => {
        return ajax.post(`v1/checkLogisticsCompany/checkQueryCompanyList`, params)
    }
    // 拖运-基础信息-拖运公司-保存公司信息
export const saveLogisticsCompany = (params) => {
        return ajax.post(`v1/checkLogisticsCompany/saveOrUpdateCompany`, params)
    }
    // 拖运-基础信息-拖运公司-获取公司信息
export const getCompanyDetail = (id) => {
        return ajax.get(`v1/checkLogisticsCompany/getCheckCompanyDetail/${id}`)
    }
    // 拖运-基础信息-拖运公司-删除拖运公司
export const deleteLogisticsCompany = (params) => {
    return ajax.post(`v1/checkLogisticsCompany/deleteLogisticsCompany`, params)
}


//  2514-获取拖运公司终点的配置信息
export const geTargetAddressTypeVO = (parasm) => {
    return request.post(`v1/checkOrder/geTargetAddressTypeVO`, parasm)
}



// 拖运-基础信息-拖运货车-查询列表
export const getLogisticsTrailerPageList = (params) => {
    return ajax.post(`v1/checkLogisticsTrailer/getTrailerList`, params)
}

// 拖运-基础信息-货车信息-保存货车信息
export const saveOrUpdateTrailer = (params) => {
    return request.post(`v1/checkLogisticsTrailer/saveOrUpdateTrailer`, params)
}

// 拖运-基础信息-货车信息-获取货车详情信息
export const getTrailerDetail = (id) => {
    return ajax.get(`v1/checkLogisticsTrailer/getTrailerDetail/${id}`)
}

// 拖运-基础信息-货车信息-删除车辆
export const deleteLogisticsTrailer = (params) => {
    console.log(params);
    return ajax.post(`v1/checkLogisticsTrailer/deleteTrailer`, params)
}

// 拖运-基础信息-货车信息-根据当前登录用户获取nvr设备列表
export const getCurrentNvrList = () => {
    return ajax.get(`v1/checkNvr/getCurrentNvrList`)
}

// 拖运-基础信息-货车信息-获取公司列表
export const getCompanyList = () => {
    return ajax.get(`v1/checkLogisticsCompany/getCompanySelectList`)
}


// 拖运-基础信息-货车信息-获取公司列表
export const getDispatchCompanyList = () => {
    return ajax.get(`v1/checkLogisticsCompany/getDispatchCompanyList
    `)
}

// -web-报废车辆-回收公司-集中拖运管理-列表
export const platformPageConsolidationCarList = (params) => {
    return request.post(`v1/consolidation/platformPageConsolidationCarList`, params)
}

// 拖运-基础信息-货车信息-根据公司ID获取司机列表
export const getDriverListByCompanyId = () => {
    return ajax.get(`v1/checkLogisticsDriver/getCompanyDriver`)
}

// 拖运-基础信息-货车信息-获取货车载重信息
export const getTrailerList = () => {
    return ajax.get(`v1/codeTable/queryChildrenCode/trailer_weight`)
}



// 拖运-基础信息-司机-查询司机列表
export const getLogisticsDriverPageList = (params) => {
        return ajax.post(`v1/checkLogisticsDriver/getDriverList`, params)
    }
    // 拖运-基础信息-司机-获取司机详情信息
export const getDriverDetail = (id) => {
        return ajax.get(`v1/checkLogisticsDriver/getDriverDetail/${id}`)
    }
    // 拖运-基础信息-司机-保存司机信息
export const saveOrUpdateDriver = (params) => {
        return request.post(`v1/checkLogisticsDriver/saveOrUpdateDriver`, params)
    }
    // 拖运-基础信息-司机-删除司机
export const deleteLogisticsDriver = (id) => {
    return ajax.get(`v1/checkLogisticsDriver/deleteCheckCheckLogisticsDriver/${id}`)
}


// 拖运-基础信息-设备-获取sim卡列表
export const querySim = (params) => {
    return request.post(`v1/checkNvr/getNvrPageList`, params)
}

// 拖运-基础信息-设备-添加sim卡
export const addSim = (params) => {
    return ajax.post(`v1/checkNvr/getNvrPageList`, params)
}


// 拖运-基础信息-设备-添加sim短息模板
export const editSimMsg = (params) => {
    return ajax.post(`v1/checkNvr/getNvrPageList`, params)
}

// 拖运-基础信息-设备-查询设备列表
export const getNvrPageList = (params) => {
        return ajax.post(`v1/checkNvr/getNvrPageList`, params)
    }
    // 拖运-基础信息-设备-获取设备详情信息
export const getNvrDetail = (id) => {
        return ajax.get(`v1/checkNvr/getNvrDetail/${id}`)
    }
    // 拖运-基础信息-设备-保存设备信息
export const saveOrUpdateNvr = (params) => {
        return ajax.post(`v1/checkNvr/saveOrUpdateNvr`, params)
    }
    // 拖运-基础信息-设备-删除设备
export const deleteNvr = (id) => {
        return ajax.post(`v1/checkNvr/deleteNvr/${id}`)
    }
    // 拖运-基础信息-设备-获取拖车列表
export const getTrailerTree = (params) => {
    return ajax.get(`v1/checkLogisticsTrailer/getTrailerTree`)
}

// 拖运-基础信息-设备-绑定拖车
export const bindLogisticsTrailer = (params) => {
    return request.post(`v1/checkNvr/bindLogisticsTrailer`, params)
}

// 拖运-基础信息-设备-解绑拖车
export const unbindLogisticsTrailer = (id) => {
    return request.post(`v1/checkNvr/unbindLogisticsTrailer/${id}`)
}






// 拖运-拖运行程-列表
export const getTaskPageList = (params) => {
        return ajax.post(`v1/checkTask/checkQueryTaskList`, params)
    }
    // 拖运-拖运行程-详情
export const getTaskDetail = (id) => {
        return ajax.get(`v1/checkTask/getCheckTaskDetail/${id}`)
    }
    // 拖运-拖运行程-行程统计
export const travelStatistics = (params) => {
        return ajax.post(`v1/checkTask/travelStatistics`, params.condition)
    }
    // 拖运-拖运行程-行程差统计
export const getMileageTaskList = (params) => {
    return ajax.post(`v1/checkTask/getMileageTaskList`, params)
}

// 8501-获取车身部位列表
export const getCarPositionList = (params) => {
    return request.get(`v1/carPosition/getCarPositionList`, params)
}



// 拖运管理-根据运单ID查询同路推荐的报废车辆-列表
export const getSameDestinationCar = (params) => {
    return ajax.get(`v1/checkOrder/getSameDestinationCar/${params}`)
}

// 拖运管理-根据拖运终点获取同路车辆-轮询
export const getSameAddressCar = (params) => {
    return ajax.get(`v1/car/getSameAddressCar/${params}`)
}

// WEB-公司端-手动退回车辆给erp
export const returnErpCarList = (params) => {
    return request.post(`v1/checkCarInfo/returnErpCarList`, params)
}

// 拖运管理-派遣车辆给待拖运的运单
export const dispatchCarToOrder = (params) => {
    return ajax.post(`v1/checkOrder/dispatchCarToOrder`, params)
}

// 拖运管理-拖运运单-列表
export const getOrderPageList = (params) => {
    return ajax.post(`v1/checkOrder/towOrderPage`, params)
}

// 拖运管理-拖运中运单-详情
export const getCheckOrderDetail = (id) => {
    return ajax.get(`v1/checkOrder/getTowOrderDetail/${id}`)
}

// 拖运管理-拖运中运单-确认移交SD卡
export const modifyOrderStatus = (orderId, status, SDNumber) => {
        return ajax.post(`v1/checkOrder/modifyOrderStatus/${orderId}/${status}/${SDNumber}`, )
    }
    // 

// 运费管理-拖运货车运费信息
export const queryFreightPage = (params) => {
        return ajax.post(`v1/freight/queryTrucksFreightPage`, params)
    }
    // 运费管理-拖运货车运费信息 - 确认运费运单车辆信息
export const queryTrucksCarDetail = (params) => {
        return ajax.get(`v1/freight/queryTrucksCarDetail/${params}`, )
    }
    // 运费管理-拖运货车运费信息 - 确认运费审核
export const auditTrucksFreight = (params) => {
    return ajax.post(`v1/freight/auditTrucksFreight`, params)
}

// 运费管理-修改拖运货车运费
export const modifyTrucksFreight = (params) => {
        return ajax.post(`v1/freight/modifyTrucksFreight`, params)
    }
    // 运费管理-拖运货车运费信息-详情
export const queryTrucksFreightDetail = (id) => {
        return ajax.get(`v1/freight/queryTrucksFreightDetail/${id}`)
    }
    // 运费管理-拖运货车运费信息-运单详情
export const getSettleCarDetail = (id) => {
        return ajax.get(`v1/freight/getSettleCarDetail/${id}`)
    }
    // 运费管理-查询运费结算列表
export const getSettleOrderList = (params) => {
        return ajax.post(`v1/freight/getSettleOrderList`, params)
    }
    // 运费管理-查询运费结算 拖车结算详情车辆一级列表
export const getSettleStatisticalList = (params) => {
        return ajax.post(`v1/freight/getSettleStatisticalList`, params)
    }
    // 运费管理-查询运费结算 拖车结算详情车辆二级列表
export const queryFreightSettlementTaskDetail = (params) => {
    return ajax.post(`v1/freight/queryFreightSettlementTaskDetail`, params)
}

// 运费管理-生成流水
export const createSettle = (params) => {
    return request.post(`v1/settlementCost/createSettle`, params)
}

// 运费管理-生成流水
export const removeSettleOrder = (params) => {
    return ajax.post(`v1/freight/removeSettleOrder`, params)
}

// 运费管理-查询运费结算 结算
export const settlementOrder = (params) => {
    return ajax.post(`v1/freight/settlementOrder`, params)
}



// 运费管理-运费结算查询-列表
export const settlePage = (params) => {
    return ajax.post(`v1/freight/settlePage`, params)
}

// 运费管理-运费结算查询-列表
export const getTrailerOrderList = (params) => {
    return ajax.post(`v1/freight/getTrailerOrderList`, params)
}

// 运费管理-运费结算查询-流水结算
export const settleFlow = (params) => {
    return ajax.post(`v1/freight/settle/${params}`, )
}

// 运费管理-运费结算查询-详情 明细
export const getSettleDetail = (id) => {
        return ajax.get(`v1/freight/getSettleDetail/${id}`)
    }
    // 运费管理 - 列表 - 视频详情
export const queryOrderFileList = (params) => {
    return ajax.get(`/v1/ftp/queryOrderFileList/${params.licenseNumber}/${params.orderId}`)
}

// 运费管理 - 列表 - 视频详情
export const unfreeze = (params) => {
        return ajax.get(`/v1/ftp/unfreeze/${params.id}`)
    }
    // 运费管理 - 列表 - 视频详情 - 下载视频
export const download = (params) => {
    return ajax.post(`/v1/ftp/download`, params)
}

// 运费管理 - 运费信息 - 运费确认 - 视频图片截图
export const getCapturePicture = (taskId) => {
    return ajax.get(`/v1/capturePicture/getCapturePictureByTaskId/${taskId}`)
}

// 操作日志 - 列表
export const queryFreightLogPage = (params) => {
    return ajax.post(`/v1/freight/queryFreightLogPage`, params)
}




// 运费结算、生成流水列表 
export const getSettlementCost = (params) => {
        return request.post(`v1/settlementCost/getSettlementCost`, params)
    }
    // 运费结算、生成流水列表 - 详情
export const getSettlementDetail = (orderId) => {
    return request.get(`v1/settlementCost/getSettlementDetail/${orderId}`)
}

// 运费结算列表 - 结算
export const settleOrders = (params) => {
    return request.post(`v1/settlementCost/settleOrders`, params)
}

// 生成流水列表 - 生成流水
export const createSettles = (params) => {
    return request.post(`v1/settlementCost/createSettle`, params)
}

// 发起流水审批、流水审批、支付审批、财务系统列表 
export const querySettleFinancialPage = (params) => {
    return request.post(`/v1/checkSettle/querySettleFinancialPage`, params)
}

// 发起流水审批、流水审批、支付审批、财务系统列表 - 详情 
export const querySettleDetail = (settleId) => {
    return ajax.get(`/v1/checkSettle/querySettleDetail/${settleId}`)
}

// 发起流水审批 - 添加流水备注
export const addSettleRemarks = (params) => {
    return request.post(`/v1/checkSettle/addSettleRemarks`, params)
}

// 发起流水审批 - 发起流水审批
export const createSettleCheck = (settleId) => {
    return request.get(`/v1/checkSettle/createSettleCheck/${settleId}`)
}

// 流水审批 - 发起支付审批
export const createSettlePayCheck = (settleId) => {
    return request.get(`/v1/checkSettle/createSettlePayCheck/${settleId}`)
}

// 流水审批 - 解散流水
export const deleteSettle = (settleId) => {
    return request.get(`/v1/checkSettle/deleteSettle/${settleId}`)
}

// 支付审批 - 发送财务系统
export const createSettleFinancialCheck = (settleId) => {
    return request.get(`/v1/checkSettle/createSettleFinancialCheck/${settleId}`)
}



// 拖运-系统设置-获取系统设置
export const getConfig = () => {
    return ajax.get(`v1/config/getCheckConfig`)
}

// 拖运-系统设置-修改系统配置
export const updateConfig = (params) => {
    return ajax.post(`v1/config/updateSystemConfig`, params)
}

// 拖运-系统设置-获取拖运终点列表
export const getAddressPageList = (params) => {
    return ajax.get(`v1/checkAddress/getCheckAddress/${params.pageSize}/${params.pageNum}`, { params: params.condition })
}

// 拖运-系统设置-删除拖运终点
export const deleteAddress = (id) => {
    return ajax.get(`v1/checkAddress/deleteAddress/${id}`)
}

// 拖运-系统设置-获取拖运终点详情
export const getAddressDetail = (id) => {
    return ajax.get(`v1/checkAddress/getCheckAddressDetail/${id}`)
}

// 拖运-系统设置-保存拖运终点
export const addAddress = (params) => {
    console.log(params)
    return request.post(`v1/checkAddress/saveOrUpdateAddress`, params)
}

// 拖运-拖运系统-获取拖车实时位置
export const getTrailerActualSite = (id) => {
    return ajax.get(`v1/checkLogisticsTrailer/getTrailerActualSite/${id}`)
}

// 运单-根据运单ID查询同路推荐的报废车辆位置
export const getSamePath = (id) => {
    return ajax.get(`v1/checkOrder/getSamePath/${id}`)
}

// 运单-根据运单ID查询行程轨迹
export const getTaskPath = (id) => {
    return ajax.get(`v1/checkOrder/getTaskPath/${id}`)
}

// 拖运-系统设置-公司端-获取超时报废车列表
export const getOvertimeCarList = (params) => {
    return ajax.post(`v1/checkCarInfo/getOvertimeCarList`, params)
}

// 运营端|公司端-获取超时报废车详情
export const timeoutCarDetail = (id) => {
    return ajax.get(`v1/checkCarInfo/getOvertimeCarDetail/${id}`)
}



// 数据统计 - 数据查看 - 准点率图表
export const queryDeliveryChart = (params) => {
    return ajax.post(`/v1/company/statistic/queryCompanyOnTime`, params)
}
// 获取所有公司的准达率(柱形图)
export const queryAllCompanyCirCleOnTime = (params) => {
    return request.post(`/v1/company/statistic/queryAllCompanyCirCleOnTime`, params)
}



// 获取所有公司的准达率
export const queryAllCompanyOnTime = (params) => {
    return request.post(`/v1/company/statistic/queryAllCompanyOnTime`, params)
}

// 数据统计 - 数据查看 - 通过率图表
export const queryPassChart = (params) => {
    return ajax.post(`/v1/company/statistic/queryCompanyCheckPass`, params)
}

// 数据统计 - 数据查看 - 装载率图表
export const queryLoadChart = (params) => {
    return ajax.post(`/v1/company/statistic/queryCompanyCheckLoad`, params)
}

// 数据统计 - 公司统计列表
export const queryCompanyStatisticList = (params) => {
    return ajax.post(`/v1/company/statistic/queryCompanyStatisticPage`, params)
}

// 银行卡列表
export const getBankTypeList = (params) => {
    return ajax.post(`/bank/getBankTypeList`, params)
}


// 拖运系统-web-发起支付
export const startPay = (settleId) => {
    return ajax.post(`/v1/freight/startPay/${settleId}`)
}

// 拖运系统-web-发起支付
export const changeCompanyEnableStatus = (companyId, enableStatus) => {
    return ajax.post(`/v1/checkLogisticsCompany/changeCompanyEnableStatus/${companyId}/${enableStatus}`)
}

// 拖运系统-web-公司端-手动同步erp的数据
export const syncErp = (params) => {
    return request.post(`v1/checkCarInfo/syncErp`, params)
}


// 拖运系统-web-运营端-更新报废车地址
export const updateCarSite = (params) => {
    return request.post(`v1/checkCarInfo/updateCarSite`, params)
}

// 拖运系统-web-运营端-更新报废车地址
export const addInstruction = (params) => {
    return request.post(`v1/checkCarInfo/addInstruction`, params)
}

export const returnTimeJudgment  = (params) => {
    return request.post(`v1/checkCarInfo/returnTimeJudgment`, params)
}
// 拖运系统-web-运营端-更新报废车地址
export const vehicleControlOthersn = (params) => {
    return request.post(`v1/checkNvr/vehicleControlOthers`, params)
}

//  结算车辆查询
export const getSettleCar = (params) => {
    return request.post(`v1/settleCar/getSettleCar`, params)
}



// 查询每月未结算报废车的数量
export const getCarNumber = (params) => {
    return request.post(`v1/settleCar/getCarNumber`, params)
}



// 通过司机查拖车
export const getTrailerByDriverId = (driverId) => {
    return request.get(`v1/checkLogisticsDriver/getTrailerByDriverId/${driverId}`)
}


//  标记异常
export const markAbnormal = (params) => {
    return request.post(`v1/settleCar/markAbnormal`, params)
}

//  恢复结算
export const restoreSettlement = (params) => {
    return request.post(`v1/settleCar/restoreSettlement`, params)
}

// 车辆结算 根据carId
export const settleCar = (params) => {
    return request.post(`v1/settleCar/settleCar`, params)
}

// 12501-扣款车款-查询列表
export const getDeductCarListByConditionPage = (params) => {
    return request.post(`v1/orderCarDeduct/getDeductCarListByConditionPage`, params)
}

// 12502-扣款车款-取消扣款
export const cancelDeductRecord = (params) => {
    return request.post(`v1/orderCarDeduct/cancelDeductRecord`, params)
}


// 12503-根据车辆的id获取车辆扣款车款
export const getCancelDeductDetailByCarId = (carId) => {
    return request.get(`v1/orderCarDeduct/getCancelDeductDetailByCarId/${carId}`)
}

// 12503-根据车辆的id获取车辆扣款车款
export const getActualBackTimeProof = (carId) => {
    return request.get(`v1/checkCarInfo/getActualBackTimeProof/${carId}`)
}
// 获取报废车辆的图片信息
export const getCarPhotosByCheckCarId = (carId) => {
    return request.get(`v1/checkCarInfo/getCarPhotosByCheckCarId/${carId}`)
}

// 根据托运车辆的id获取车辆在时间线
export const getBundlingXyidcCarStatusChangeByCheckCarId = (checkCarId) => {
    return request.get(`v1/checkCarInfo/getBundlingXyidcCarStatusChangeByCheckCarId/${checkCarId}`)
}

//web-报废车管理-报废车信息/导出
export const exportCheckCar = (params) => {
    return request.post(`/v1/checkCarInfo/exportCheckCar`, params)
}

export const getCheckCarErpInfoByCheckCarId = (carId) => {
    return request.get(`/v1/checkCar/getCheckCarErpInfoByCheckCarId/${carId}`)
}


// 12801_web-车辆的操作记录
export const getTBundlingCarOperationLog = (params) => {
        return request.post(`v1/carOperationLog/getTBundlingCarOperationLog`, params)
    }
    // // 7501-生成流水-查询列表
    // export const getSettlementCost = (params) => {
    //     return request.post(`v1/settlementCost/getSettlementCost`,params)
    // }

// // 7504-运费结算-创建结算流水
// export const createSettle = (params) => {
//     return request.post(`v1/settlementCost/createSettle`,params)
// }

//web-报废车管理-报废车信息/导出
export const showCarDetail = (feedBackId) => {
    return request.get(`v1/checkCarInfo/showCarDetail/${feedBackId}`)
}

//web - 运单管理 - 作废运单
export const invalidOrder = (params) => {
    return request.post(`/v1/checkOrder/invalidOrder`, params)
}

//web - 手动结束
export const webFinishCheckOrder = (params) => {
    return request.post(`/v1/checkOrder/webFinishCheckOrder`, params)
}

//web - 强制结束
export const foreFinishOrderByIds = (params) => {
    return request.post(`/v1/checkOrder/foreFinishOrderByIds`, params)
}

//web - 获取运费信息
export const getCheckCarCarriageDetail = (erpId) => {
    return request.get(`/v1/checkCarInfo/getCheckCarCarriageDetail/${erpId}`)
}



//202205-12
export const getServiceAreaList = (erpId) => {
    return request.get(`/v1/checkServiceArea/getServiceAreaList`)
}

//批量修改车辆的托运目的站
export const changeCheckCarAddress = (params) => {
    return request.post(`/v1/checkCarInfo/changeCheckCarAddress`, params)
}