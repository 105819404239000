import React, { useState, useEffect, useImperativeHandle } from "react";
import {
  Input,
  Button,
  Form,
  message,
  Modal,
  Row,
  InputNumber,
  Radio,
} from "antd";
import * as _ from "underscore";

import {
  changeUserPassword,
} from "requests/user";
import { craPassword } from 'common'
import { simplePassword } from "utils/validate"

// import "./index.less";

export default function UseOemAddForm(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewpassword] = useState("");
  const [visible, setVisible] = useState(false);


  useEffect(() => {
    return () => {};
  }, []);

  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(props.cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    showModal: () => {
      setVisible(true);
    },
  }));


  const formFinish = (datas) => {
    let values = { ...datas };
    values.userId=window.sessionStorage.userId;
    values.password=craPassword(values.password)
    values.newPasswd=craPassword(values.newPasswd)
    setLoading(true)
    changeUserPassword(values).then((res) => {
      if (res.code === "00000") {
        message.success("修改成功");
        setLoading(false)
        modalCancel();
        props.loginStore.loginOut()
      }
    });
  };
  const formSubmit = () => {
    form.submit();
  };
  const modalCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  /**
   * 
   * @param {*} rule 
   * @param {*} value 
   * @param {*} callback 
   * @returns 
   */
  const repeatPassword = (rule,value,callback) => {

    if (value===undefined||value===""||value===null){
      return Promise.reject("请输入密码")
    }
    else if (value.length<6|| value.length>20) {
      return Promise.reject("密码只能为6-20位")
    }
    
    else if (newPassword !== value) {
      return Promise.reject("请重复密码")
    } else {
        return Promise.resolve()
    }
}

const validatePassword = (rule,value,callback) => {

  if (value===undefined||value===""||value===null){
    return Promise.reject("请输入密码")
  }
  else if (value.length<6|| value.length>20) {
    return Promise.reject("密码只能为6-20位")
  } else if ( !simplePassword(value) ) {
    return Promise.reject("密码需要包含数字和字母")
  } else {
    return Promise.resolve()
  }
}

const minLength = (rule,value,callback) => {
  if (value===undefined||value===""||value===null){
    return Promise.reject("请输入密码")
  }else if (value&&value.length<6) {
    return Promise.reject("密码长度不能低于6位数")
  } else {
      return Promise.resolve()
  }
}
  return (
    <Modal
      visible={visible}
      title="修改密码"
      width={440}
      maskClosable={false}
      onCancel={modalCancel}
      cancelText="取消"
      okText="确认"
      okButtonProps={{ "disabled": loading,"loading":loading }}
      onOk={() => formSubmit()}
    >
      <Form form={form} onFinish={formFinish}>
        <div className="xy-addform">
          <Form.Item
            label="旧密码"
            name="password"
            rules={[
              {
                required:true,
                validator: (rule, value, callback) => minLength(rule, value, callback)
              },
            ]}
          >
            <Input.Password
              style={{ width: 220 }}
              maxLength={20}
              placeholder="请输入旧密码"
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="newPasswd"
            rules={[
              {
                required:true,
                validator: (rule, value, callback) => validatePassword(rule, value, callback)
              },
            ]}
          >
            <Input.Password
              style={{ width: 220 }}
              maxLength={40}
              // prefix={<PsdIcon />}
              placeholder="请输入新密码"
              onChange={e=>{setNewpassword(e.currentTarget.value)}}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="comfirmPassword"
            rules={[
              {
                required: true,
                validator: (rule, value, callback) => repeatPassword(rule, value, callback)
              }
            ]}
          >
            <Input.Password
              style={{ width: 220 }}
              maxLength={40}
              placeholder="请输入新密码"
              // prefix={<PsdIcon />}
            />
          </Form.Item>

        </div>
      </Form>
    </Modal>
  );
}
