import LoginStore from "./loginStore";
import Freight from "./Freight"
import Common from "./Common"
import carDispatch from "./carDispatch"
import carAppoint from "./carAppoint"
import cashBack from "./cashBack"
import vehicleStorage from "./vehicleStorage"
import weighingRecord from "./weighingRecord"
import certificatesCheck from "./certificatesCheck"
import unweighted from "./unweighted"
import carProblem from "./carProblem"
import responsibility from "./responsibility"
import reportform from "./reportform"

let loginStore = new LoginStore();
let FreightStore = new Freight();
let carDispatchStore = new carDispatch();
let vehicleStorageStore = new vehicleStorage();
let weighingRecordStore = new weighingRecord();
let certificatesCheckStore = new certificatesCheck();
let carProblemStore = new carProblem();
let responsibilityStore = new responsibility();
let reportformStore = new reportform();
let unweightedStore = new unweighted();
let CommonStore = new Common();
let CarAppointStore = new carAppoint();
let CashBackStore = new cashBack();

const stores = {
    loginStore,
    FreightStore,
    CommonStore,
    carDispatchStore,
    CarAppointStore,
    CashBackStore,
    vehicleStorageStore,
    weighingRecordStore,
    certificatesCheckStore,
    unweightedStore,
    certificatesCheckStore,
    carProblemStore,
    responsibilityStore,
    reportformStore
};
/// 默认导出接口
export default stores;