/**
 * @description: 渠道管理
 * @author: 漆红 2021-2-1
 */
 
import ajax from './index'

// 企业分页查询列表
export const companyPage = (params) => {
    return ajax.post(`/v1/scrape/company/companyPage`, params)
}

// 添加企业
export const addCompany = (params) => {
    return ajax.post(`/v1/scrape/company/addCompany`, params)
}

// 编辑企业
export const updateCompany = (params) => {
    return ajax.post(`/v1/scrape/company/updateCompany`, params)
}

// 获取银行的列表
export const getBankTypeList = () => {
    return ajax.post(`/bank/getBankTypeList`)
}

// 企业详情
export const companyDetail = (companyId) => {
    return ajax.get(`/v1/scrape/company/companyDetail/${companyId}`)
}

// 添加渠道
export const addChannel = (params) => {
    return ajax.post(`/v1/scrape/company/channel/addChannel`, params)
}

// 修改渠道
export const updateChannel = (params) => {
    return ajax.post(`/v1/scrape/company/channel/updateChannel`, params)
}

// 渠道详情
export const channelDetail = (channelId) => {
    return ajax.get(`/v1/scrape/company/channel/channelDetail/${channelId}`)
}

// 渠道分页列表
export const channelPage = (params) => {
    return ajax.post(`/v1/scrape/company/channel/channelPage`, params)
}

// 渠道返现列表
export const getCashBackCheckCar = (params) => {
    return ajax.post(`/v1/scrape/car/getCashBackCheckCar`, params)
}

// 二维码下载
export const downloadQrcode = (params) => {
    return ajax.post(`/v1/qrcode/downloadQrcode`, params)
}

// 获取二维码
export const createChannelQrCode = (channelId) => {
    return ajax.get(`v1/scrape/company/channel/createChannelQrCode/${channelId}`)
}