import moment from "moment";
import { isEmpty } from "underscore";
import qs from "querystring"
import { eunmeration } from "./const";
/**
 * 格式化返回参数，聚合分页信息
 * @param {
 *
 * } data
 * @returns
 */
export function formatResponse(data) {
  let resData = data.data.data;
  let pageModal = {};
  if (resData) {
    if (resData.current !== undefined) {
      resData.current = parseInt(resData.current);
      pageModal.page = resData.current;
    }
    if (resData.size !== undefined) {
      resData.size = parseInt(resData.size);
      pageModal.pageSize = resData.size;
    }
    if (resData.total !== undefined) {
      resData.total = parseInt(resData.total);
      pageModal.total = resData.total;
    }
    if (pageModal && Object.keys(pageModal).length !== 0) {
      resData.pageModal = pageModal;
    }
  }
  resData && (data.data.data = resData);
  return data;
}
/**
 * 
 * @param {*} data 
 */
export function formateReqData(data){
  if(data&&data.pageModal){
    data.pageNum=data.pageModal.page;
    data.pageSize=data.pageModal.pageSize;
    data.pageModal=undefined;
  }
  return data
}
/**
 * 格式化对象中数组时间段为字符串
 * @param { object } formData -- {...,date:[date:date]}
 * @param { array } param1 -- [formateDateName1,formateDateName1]
 * @param { string } key --  names
 * @returns formate formDate
 */
// 1.格式化时间段，返回字符串
export function FormatRangePickerData(
  formData,
  [momentStart = "startDate", momentEnd = "endDate"],
  key
) {
    if(formData&&key){
      if(formData[key]&&formData[key].length===2){
        const current =formData[key];
        formData[momentStart] = moment(current[0]).format("yyyy-MM-DD");
        formData[momentEnd] = moment(current[1]).format("yyyy-MM-DD");
        delete formData[key];
      }
    return formData;
  }
  else if (formData && Object.keys(formData).length !== 0) {
    Object.keys(formData).forEach((key) => {
      const current = formData[key];
      if (
        current instanceof Array &&
        current.length === 2 &&
        current[0]._isAMomentObject &&
        current[1]._isAMomentObject
      ) {
        formData[momentStart] = moment(current[0]).format("yyyy-MM-DD");
        formData[momentEnd] = moment(current[1]).format("yyyy-MM-DD");
        delete formData[key];
      }
    });
  }
  return formData;
}

export function FormatDate(formData) {
  Object.keys(formData).forEach((key) => {
    const current = formData[key];
    if (current && current._isAMomentObject) {
      formData[key] = moment(current).format("yyyy-MM-DD");
    }
  });
  return formData;
}

export function FutureDays(day = 5) {
  const dayList = [];
  for (let i = 0; i <= day; i++) {
    const current = moment().add(i, "days").format("YYYY-MM-DD");
    dayList.push({
      label: `${moment(current).format("dddd")}   ${current}`,
      value: current,
    });
  }
  return dayList;
}

export const statusColor = {
  未发布: "#feac6c",
  未审核: "#feac6c",
  未结算: "#feac6c",
  未预约: "#feac6c",
  否: "#feac6c",

  待抢车: "#2196f3",
  待派遣: "#2196f3",
  待拖运: "#2196f3",
  待移交SD卡: "#2196f3",
  待上传视频: "#2196f3",
  视频待备份: "#2196f3",
  正在上传视频: "#2196f3",
  待成单: "#2196f3",
  待接单: "#2196f3",
  待审核: "#2196f3",
  待核查: "#2196f3",

  拖运中: "#fbc02d",
  处理中: "#fbc02d",
  支付中: "#fbc02d",
  审核中: "#fbc02d",
  核查中: "#fbc02d",
  精品车申请中: "#fbc02d",
  未启用: "#fbc02d",

  审核通过: "#67c27a",
  已预约: "#67c27a",
  是: "#67c27a",
  正常运单: "#67c27a",
  拖运完成: "#67c27a",
  已完成: "#67c27a",
  已发布: "#67c27a",
  已成单: "#67c27a",
  启用: "#67c27a",
  完成: "#67c27a",
  已结算: "#67c27a",
  完成结算: "#67c27a",
  在线: "#67c27a",
  正常拖运: "#67c27a",
  已核查: "#67c27a",
  已解决: "#67c27a",
  已提醒处理: "#67c27a",
  通过: "#67c27a",
  1: "#67c27a",
  已启用: "#67c27a",

  审核不通过: "#f56c6c",
  审核驳回: "#f56c6c",
  拒绝: "#f56c6c",
  审核拒绝: "#f56c6c",
  运单超时: "#f56c6c",
  运单取消: "#f56c6c",
  拒绝运单: "#f56c6c",
  异常运单: "#f56c6c",
  禁用: "#f56c6c",
  审核未通过: "#f56c6c",
  离线: "#f56c6c",
  暂未绑定设备: "#f56c6c",
  无法拖运: "#f56c6c",
  可以拖运: "#f56c6c",
  核查拒绝: "#f56c6c",
  支付失败: "#f56c6c",
  已超时: "#f56c6c",
  已停用: "#f56c6c",
  0: "#f56c6c",
};

//节流   //默认一秒只能执行一次fn函数
export const throttle = (fn, timeout = 1000) => {
  let lastTime = Date.now();
  return function (event) {
    let nowTime = Date.now();
    event.persist && event.persist();
    if (nowTime - lastTime >= timeout) {
      fn.apply(event);
      lastTime = Date.now();
    }
  };
};

//防抖
export const debounce = (fn, wait = 600) => {
  let timeout; // 定时器变量
  return function (event) {
    clearTimeout(timeout); // 每次触发时先清除上一次的定时器,然后重新计时
    event && event.persist && event.persist(); //保留对事件的引用
    //const event = e && {...e}   //深拷贝事件对象
    timeout = setTimeout(() => {
      fn.apply(event);
    }, wait);
  };
};

export const formatEnumToText = (id, enumerationName) => {
  console.log(
    "formatEnumToText",
    id,
    enumerationName,
    eunmeration[enumerationName]
  );
  if (eunmeration[enumerationName] === undefined) {
    return "";
  } else {
    const result = eunmeration[enumerationName].filter((item) => item.id == id);
    if (result && result[0] && result[0].name) {
      return result[0].name;
    } else {
      return "";
    }
    // return result&&result[0].name
  }
};

export const findValuesByIdInArr = (arr, key, keyValue, findKey) => {
  const result = arr.filter((item) => {
    if (item[key] === keyValue) {
      return item[findKey];
    }
  });
  console.log("result---", result);
  return result[0][findKey];
};

export const checkDataTimeAfterToday = (rule, value) => {
  if (value === undefined) {
    return Promise.reject("请选择正确的日期");
  } else if (moment(value).valueOf() <= new Date().getTime()) {
    return Promise.reject("请选择当前时刻之后的日期");
  } else {
    return Promise.resolve();
  }
};

/**
 * 根据name返回枚举值id
 * @param {* string} 枚举名- name 
 * @param {* array} 枚举 - list=[{id:0,name:"名称"}] 
 * @param {* string } 默认返回 - defaultValue
 * @returns 枚举值id
 */
export const findEnumerIdByName = (name, list,defaultValue=undefined) => {
  if (name && list&&list.length) {
    const res = list.filter(item=>{
      if(item.name===name){
        return item.id
      }
    })
    return res[0]&&res[0].id||defaultValue
  } else {
    return defaultValue
  }
};

/**
 * 根据id返回枚举值名称
 * @param {* number || string } 枚举值 - id 
 * @param {* array } 枚举 - list=[{id:0,name:"名称"}] 
 * @param {* string } 默认返回 - defaultValue
 * @returns 枚举名
 */
export const findEnumerNameById = (id, list, defaultValue=undefined) => {
  console.log("id",id,list)
  if (hasValue(id) && list&&list.length) {
    console.log("hasValue")
    const res = list.filter(item=>{
      if(item.id===id){
        return item.name
      }
    })
    return res[0]&&res[0].name||defaultValue
    
  } else {
    return defaultValue
  }
};

export const hasValue = text =>{
  if(text===null||text===undefined||text===""){
    return false
  }else{
    return true
  }
}

export const validateMaxLenght = (rule, value="", maxLenght = 10) => {
  console.log("value", value, value.length, maxLenght);
  if (value && value.length > maxLenght) {
    return Promise.reject(`长度不能超过${maxLenght}位`);
  } else {
    return Promise.resolve();
  }
};

export const openWithNewTab = (pathname) => {
  const url = window.location.href;
  const baseUrl = url.slice(0, url.indexOf("#") + 1);
  console.log("baseUrl+pathname", baseUrl + pathname);
  window.open(baseUrl + pathname);
};

export const reEmpytObjKeys = (formData) =>{
  Object.keys(formData).forEach(item=>{
    if(isEmpty(formData[item])){
      delete formData[item];
    }
  })
  return formData
};

export const formatUrlParams = () =>{
  let url=window.location.href;
  let index=url.indexOf("?")+1
  if(index>0){
    const params= qs.parse(url.slice(index))
    return params
  }else{return false}
}


/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export const accAdd = (arg1, arg2) => {
  if( !hasValue(arg1)||!hasValue(arg2)){
    return 0
  }
  var r1, r2, m, c;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", "")) * cm;
    } else {
      arg1 = Number(arg1.toString().replace(".", "")) * cm;
      arg2 = Number(arg2.toString().replace(".", ""));
    }
  } else {
    arg1 = Number(arg1.toString().replace(".", ""));
    arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
};


/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accSub(arg1, arg2) {
  if( !hasValue(arg1)||!hasValue(arg2)){
    return 0
  }
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
export function accMul(arg1, arg2) {
  if( !hasValue(arg1)||!hasValue(arg2)){
    return 0
  }
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

export function getPagingData(arr,totalPage,pageSizes) {
    let newArr = []
    if(totalPage > 0){
      for(let i = 1;i<=totalPage;i++){
          newArr[i-1] = [];
          arr.forEach((e,ix)=>{
            if(ix < i*pageSizes && ix >= (i -1) *pageSizes){
                  newArr[i-1].push(e)
            }
          })
      }
  }
  return newArr;
}
