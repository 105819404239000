/**
 * @description: 拖运监管
 * @author: 漆红 2020-10-10
 */

import Loadable from 'react-loadable'
import { Loading } from 'components'


const CheckHome = Loadable({
    loader: () =>
        import ('./Home/index'),
    loading: Loading
})
const CheckCarInfoList = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarList'),
    loading: Loading
})

const CheckCarCentralize = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarCentralize'),
    loading: Loading
})

const CheckCarCentralizeComp = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarCentralizeComp'),
    loading: Loading
})

const CheckCarCentralizeCompDispatch = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarCentralizeComp/publish'),
    loading: Loading
})
const CompanyCarInfoList = Loadable({
    loader: () =>
        import ('./CheckCar/CompanyCarList'),
    loading: Loading
})

const CheckCarInfoDetail = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarDetail'),
    loading: Loading
})

const ReturnCar = Loadable({
    loader: () =>
        import ('./CheckCar/ReturnCar'),
    loading: Loading
})

const CheckCarInfoAdd = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarAdd'),
    loading: Loading
})

const CheckCarOrder = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarPublish'),
    loading: Loading
})

const CheckCarQuickPublish = Loadable({
    loader: () => import('./CheckCar/CheckCarPublish/quick'),
    loading: Loading
})

const CheckCaptureList = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarCapture/index'),
    loading: Loading
})

const CheckCaptureDetail = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarCapture/detail'),
    loading: Loading
})

const CheckCapturePublish = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarCapture/publish'),
    loading: Loading
})

const CheckTimeout = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarTimeout/index'),
    loading: Loading
})

const CheckTimeoutComp = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarTimeout/listComp'),
    loading: Loading
})

const CheckTimeoutDetail = Loadable({
    loader: () =>
        import ('./CheckCar/CheckCarTimeout/detail'),
    loading: Loading
})


const CheckLogisticsCompanyList = Loadable({
    loader: () =>
        import ('./CheckLogisticsCompany/CheckLogisticsCompanyList'),
    loading: Loading
})

const LogisticsCompanyAdd = Loadable({
    loader: () =>
        import ('./CheckLogisticsCompany/LogisticsCompanyAdd'),
    loading: Loading
})

const LogisticsCompanyDetail = Loadable({
    loader: () =>
        import ('./CheckLogisticsCompany/LogisticsCompanyAdd/detail'),
    loading: Loading
})

const CheckLogisticsTrailerList = Loadable({
    loader: () =>
        import ('./CheckLogisticsTrailer/CheckLogisticsTrailerList'),
    loading: Loading
})

const LogisticsTrailerAdd = Loadable({
    loader: () =>
        import ('./CheckLogisticsTrailer/LogisticsTrailerAdd'),
    loading: Loading
})

const LogisticsTrailerActualDetail = Loadable({
    loader: () =>
        import ('./CheckLogisticsTrailer/LogisticsTrailerActualDetail'),
    loading: Loading
})

const CheckLogisticsDriverList = Loadable({
    loader: () =>
        import ('./CheckLogisticsDriver/CheckLogisticsDriverList'),
    loading: Loading
})

const LogisticsDriverAdd = Loadable({
    loader: () =>
        import ('./CheckLogisticsDriver/LogisticsDriverAdd'),
    loading: Loading
})

const CheckLogisticsNvrList = Loadable({
    loader: () =>
        import ('./CheckLogisticsNvr/CheckLogisticsNvrList'),
    loading: Loading
})

const CheckLogisticsSimList = Loadable({
    loader: () =>
        import ('./Home/SIM'),
    loading: Loading
})


const LogisticsNvrAdd = Loadable({
    loader: () =>
        import ('./CheckLogisticsNvr/LogisticsNvrAdd'),
    loading: Loading
})

const CheckTaskList = Loadable({
    loader: () =>
        import ('./CheckTask/CheckTaskList'),
    loading: Loading
})

const CheckHangUp = Loadable({
    loader: () =>
        import ('./CheckTask/HangUp'),
    loading: Loading
})

const CheckTaskStatistics = Loadable({
    loader: () =>
        import ('./CheckTask/CheckTaskStatistics'),
    loading: Loading
})


const CheckConfig = Loadable({
    loader: () =>
        import ('./CheckConfig/Config'),
    loading: Loading
})

const AddAddress = Loadable({
    loader: () =>
        import ('./CheckConfig/Address/AddAddress'),
    loading: Loading
})

const SystemAddress = Loadable({
    loader: () =>
        import ('./CheckConfig/Address'),
    loading: Loading
})

const DistributeAddress = Loadable({
    loader: () =>
        import ('./CheckConfig/Distribute'),
    loading: Loading
})

const CheckOrderList = Loadable({
    loader: () =>
        import ('./CheckOrder/Order'),
    loading: Loading
})


export const OrderForceList = Loadable({
    loader: () =>
        import ('./CheckOrder/OrderForce'),
    loading: Loading
})

const CheckSystemExceptional = Loadable({
    loader: () =>
        import ('./Exceptional/list'),
    loading: Loading
})

const CheckSystemDeadhead = Loadable({
    loader: () =>
        import ('./Deadhead'),
    loading: Loading
})

const CheckSystemDeadheadCompany = Loadable({
    loader: () =>
        import ('./Deadhead/company'),
    loading: Loading
})




const CheckSystemApproval = Loadable({
    loader: () =>
        import ('./Exceptional/Approval'),
    loading: Loading
})

const CheckSystemHandOver = Loadable({
    loader: () =>
        import ('./HandOver/index'),
    loading: Loading
})

const CheckSystemHandOverPublish = Loadable({
    loader: () =>
        import ('./HandOver/publish'),
    loading: Loading
})

const CheckSystemExceptionalDetail = Loadable({
    loader: () =>
        import ('./Exceptional/detail'),
    loading: Loading
})


const VideoDetail = Loadable({
    loader: () =>
        import ('./CheckOrder/Order/VideoDetail'),
    loading: Loading
})
const CheckOrderDetail = Loadable({
    loader: () =>
        import ('./CheckOrder/CheckOrderDetail'),
    loading: Loading
})
const CarLackDetail = Loadable({
    loader: () =>
        import ('./CheckOrder/CheckOrderDetail/lackDetail'),
    loading: Loading
})
const CheckTaskDetail = Loadable({
    loader: () =>
        import ('./CheckTask/TaskDetail'),
    loading: Loading
})
const CheckTaskPath = Loadable({
    loader: () =>
        import ('./CheckTask/CheckTaskPath'),
    loading: Loading
})

const CheckTaskDiffence = Loadable({
    loader: () =>
        import ('./CheckTask/CheckTaskDiffence'),
    loading: Loading
})
const FreightIndex = Loadable({
    loader: () =>
        import ('./Freight/index'),
    loading: Loading
})
const FreightList = Loadable({
    loader: () =>
        import ('./Freight/FreightList'),
    loading: Loading
})

//结算车辆
const SettlementFreightList = Loadable({
    loader: () =>
        import ('./Freight/SettlementFreightList'),
    loading: Loading
})

//生成流水
const SettlementSerial = Loadable({
    loader: () =>
        import ('./Freight/Serial'),
    loading: Loading
})

//流水审批
const SettlementSerialApproval = Loadable({
    loader: () =>
        import ('./Freight/SerialApproval'),
    loading: Loading
})

//结算异常车辆
const SettlementException = Loadable({
    loader: () =>
        import ('./Freight/Exception'),
    loading: Loading
})

//扣款车辆
const SettlementDeduct = Loadable({
    loader: () =>
        import ('./Freight/Deduct'),
    loading: Loading
})

const ConfirmSettle = Loadable({
    loader: () =>
        import ('./Freight/FreightList/ConfirmSettle'),
    loading: Loading
})
const FreightDetail = Loadable({
    loader: () =>
        import ('./Freight/FreightList/FreightDetail'),
    loading: Loading
})

const SettlementFreightDetail = Loadable({
    loader: () =>
        import ('./Freight/SettlementFreightList/SettlementFreightDetail'),
    loading: Loading
})

const SettlementQueryList = Loadable({
    loader: () =>
        import ('./Freight/SettlementQueryList'),
    loading: Loading
})

const OrderRemove = Loadable({
    loader: () =>
        import ('./Freight/SettlementQueryList/OrderRemove'),
    loading: Loading
})

const SettlementDetails = Loadable({
    loader: () =>
        import ('./Freight/SettlementQueryList/SettlementDetails'),
    loading: Loading
})

const SettleCarModalDetails = Loadable({
    loader: () =>
        import ('./Freight/SettlementQueryList/SettleCarModalDetails'),
    loading: Loading
})

const SettleOrderDetails = Loadable({
    loader: () =>
        import ('./Freight/SettlementQueryList/SettleOrderDetails'),
    loading: Loading
})

const RecommendRoute = Loadable({
    loader: () =>
        import ('./CheckOrder/CheckOrderDetail/recommendRoute'),
    loading: Loading
})

const DataIndicators = Loadable({
    loader: () =>
        import ('./DataIndicators'),
    loading: Loading
})

const DataLook = Loadable({
    loader: () =>
        import ('./DataIndicators/DataLook'),
    loading: Loading
})

const DeliveryRate = Loadable({
    loader: () =>
        import ('./DataIndicators/DeliveryRate'),
    loading: Loading
})

const PassRate = Loadable({
    loader: () =>
        import ('./DataIndicators/PassRate'),
    loading: Loading
})

const LoadFactorRate = Loadable({
    loader: () =>
        import ('./DataIndicators/LoadFactorRate'),
    loading: Loading
})

const OperationLogList = Loadable({
    loader: () =>
        import ('./Freight/OperationLogList'),
    loading: Loading
})

const ApprovalDetail = Loadable({
    loader: () =>
        import ('./Freight/ApprovalDetail'),
    loading: Loading
})

const OrderDetail = Loadable({
    loader: () =>
        import ('./Freight/OrderDetail'),
    loading: Loading
})

const LoadFail = Loadable({
    loader: () =>
        import ('./CheckOrder/LoadFail'),
    loading: Loading
})

const QuickEmit = Loadable({
    loader: () =>
        import ('./QuickEmit/index'),
    loading: Loading
})

const CarAreaSetting = Loadable({
    loader: () =>
        import ('./CarAreaSetting/index'),
    loading: Loading
})

export {
    CheckHome,
    LoadFail,
    SystemAddress,
    DistributeAddress,
    CheckCarInfoList,
    CheckCarCentralize,
    CheckCarCentralizeComp,
    CheckCarCentralizeCompDispatch,
    CompanyCarInfoList,
    CheckCarInfoDetail,
    CheckCarInfoAdd,
    CheckCarOrder,
    CheckCarQuickPublish,
    CheckCaptureList,
    CheckCapturePublish,
    CheckCaptureDetail,
    CarAreaSetting,
    CheckTimeout,
    CheckTimeoutComp,
    CheckTimeoutDetail,
    CheckLogisticsCompanyList,
    LogisticsCompanyAdd,
    LogisticsCompanyDetail,
    CheckLogisticsTrailerList,
    LogisticsTrailerAdd,
    LogisticsTrailerActualDetail,
    CheckLogisticsDriverList,
    LogisticsDriverAdd,
    CheckLogisticsNvrList,
    CheckLogisticsSimList,
    LogisticsNvrAdd,
    CheckTaskList,
    CheckHangUp,
    CheckTaskDiffence,
    CheckConfig,
    CheckOrderList,
    CheckSystemExceptional,
    CheckSystemDeadhead,
    CheckSystemDeadheadCompany,
    CheckSystemApproval,
    CheckSystemHandOver,
    CheckSystemHandOverPublish,
    CheckSystemExceptionalDetail,
    CheckOrderDetail,
    CarLackDetail,
    CheckTaskDetail,
    CheckTaskPath,
    FreightList,
    SettlementFreightList,
    SettlementSerial,
    SettlementSerialApproval,
    SettlementException,
    SettlementDeduct,
    FreightIndex,
    AddAddress,
    FreightDetail,
    SettlementFreightDetail,
    SettlementQueryList,
    CheckTaskStatistics,
    RecommendRoute,
    VideoDetail,
    OrderRemove,
    SettlementDetails,
    SettleCarModalDetails,
    SettleOrderDetails,
    ConfirmSettle,
    PassRate,
    DeliveryRate,
    DataIndicators,
    DataLook,
    LoadFactorRate,
    OperationLogList,
    ApprovalDetail,
    OrderDetail,
    ReturnCar,
    //快捷下发
    QuickEmit,
}