/**
 * @description: 大模块
 * @author: 漆红 2020-10-10
 */

//最顶上 模块
export const headNavRoutes = [{
        pathname: '/admin/home',
        title: '首页',
        roles: '',
    }, {
        pathname: '/admin/usermanagement',
        title: '用户管理',
        roles: ['user'],
    }, {
        pathname: '/admin/scrapchannel',
        title: '报废渠道',
        roles: ['scrape']
    },
    {
        pathname: 'admin/recycle',
        title: '车辆回收',
        roles: ['scrape_manager'],
    },
    {
        pathname: '/admin/checkSystem',
        title: '拖运监管',
        roles: ['check'],
    }, {
        pathname: '/admin/message',
        title: '模板消息',
        roles: ['message_news_center', 'message_news', 'message_news_category'],
    }, {
        pathname: '/admin/admission',
        title: '入场查验',
        roles: ''
    },
    {
       pathname: '/admin/reuse/index',
       title: '回用件管理',
       roles: ''
    }, 
    // {
    //     pathname: '/admin/platform',
    //     title: '称重平台',
    //     roles: ''
    // },
    {
        pathname: '/admin/finance',
        title: '财务工作台',
        roles: ['pay']
    },
    {
        pathname: '/admin/commonModule',
        title: '系统配置',
        roles: ['public']
    },
    {
        pathname: '/admin/operational',
        title: '证明分发',
        roles: ['prove_distribute']
    },
    //     {
    //     pathname: '/admin/website',
    //     title: '官网管理',
    //     roles: ['website']
    // }
];