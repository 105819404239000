import { observable, action, makeObservable } from "mobx"
import { getSettlementCost, settleOrders, querySettleFinancialPage,    getDispatchCompanyList,
  showCheckDriverList,
  getTrailerByDriverId, } from 'requests/haul'
import { message } from 'antd'
import moment from "moment";

class Freight {
  constructor() {
    makeObservable(this)
  }
  @observable companyList = []
  @observable companyId = ''

  // 结算流水
  @observable settlementList = []
  @observable settleSelectedRowKeys = []
  @observable settlePageModal = {
    page: 1,
    pageSize: 10,
    total: 0
  }
  @observable settleCondition = {
    companyId: undefined,
    backDate: moment().startOf('month').subtract(1,"months").format('YYYY-MM'),
    queryType: 1
  }

  // 生成流水
  @observable serialmentList = []
  @observable serialSelectedRowKeys = []
  @observable serialPageModal = {
    page: 1,
    pageSize: 10,
    total: 0
  }
  @observable serialCondition = {
    companyId: undefined,
    backDate: moment().startOf('month').subtract(1,"months").format('YYYY-MM'),
    queryType: 2
  }

  // 发起审批
  @observable serialApprovalList = []
  @observable serialApprovalPageModal = {
    page: 1,
    pageSize: 10,
    total: 0
  }
  @observable serialApprovalCondition = {
    companyId: undefined,
    settleTime: moment().startOf('month').subtract(1,"months").format('YYYY-MM'),
		pageType: 1,
  }

  // 流水审批
  @observable waterApprovalList = []
  @observable waterApprovalPageModal = {
    page: 1,
    pageSize: 10,
    total: 0
  }
  @observable waterApprovalCondition = {
    companyId: undefined,
    settleTime: moment().startOf('month').subtract(1,"months").format('YYYY-MM'),
    checkSettleStatus: null,
		pageType: 2,
  }

  // 支付审批
  @observable payApprovalList = []
  @observable payApprovalPageModal = {
    page: 1,
    pageSize: 10,
    total: 0
  }
  @observable payApprovalCondition = {
    companyId: undefined,
    settleTime: moment().startOf('month').subtract(1,"months").format('YYYY-MM'),
    checkSettleStatus: null,
		pageType: 3,
  }

  // 财务结算
  @observable financialList = []
  @observable financialPageModal = {
    page: 1,
    pageSize: 10,
    total: 0
  }
  @observable financialCondition = {
    companyId: undefined,
    settleTime: moment().startOf('month').subtract(1,"months").format('YYYY-MM'),
		pageType: 4,
		settleStatus: null,
  }

  // 获取运费结算列表
  @action fetchSettlement(pageModal, condition) {
    // 生成流水
    const { page, pageSize } = pageModal || this.settlePageModal
    getSettlementCost({
      pageNum: page,
      pageSize,
      condition: condition || this.settleCondition
    }).then(resp => {
      if (resp && !resp.code) {
        this.settleSelectedRowKeys = []
        this.settlementList = resp.data ? resp.data : [];
        this.settlePageModal = resp.pageModal
      }
    })
  }

  // 获取生成流水列表
  @action fetchSerialment(pageModal, condition) {
    // 生成流水
    const { page, pageSize } = pageModal || this.serialPageModal

    getSettlementCost({
      pageNum: page,
      pageSize,
      condition: condition || this.serialCondition
    }).then(resp => {
      if (resp && !resp.code) {
        this.serialSelectedRowKeys = []
        this.serialmentList = resp.data ? resp.data : [];
        this.serialPageModal = resp.pageModal
      }
    })
  }

  // 发起流水审批
  @action fetchSerialApproval(pageModal, condition) {
    const { page, pageSize } = pageModal || this.serialApprovalPageModal

    querySettleFinancialPage({
      pageNum: page,
      pageSize,
      condition: condition || this.serialApprovalCondition
    }).then(resp => {
      if (resp && !resp.code) {
        this.serialApprovalList = resp.data ? resp.data : [];
        this.serialApprovalPageModal = resp.pageModal
      }
    })
  }

  // 流水审批进程
  @action fetchWaterApproval(pageModal, condition) {
    const { page, pageSize } = pageModal || this.waterApprovalPageModal

    querySettleFinancialPage({
      pageNum: page,
      pageSize,
      condition: condition || this.waterApprovalCondition
    }).then(resp => {
      if (resp && !resp.code) {
        this.waterApprovalList = resp.data ? resp.data : [];
        this.waterApprovalPageModal = resp.pageModal
      }
    })
  }

  // 支付审批进程
  @action fetchpayApproval(pageModal, condition) {
    const { page, pageSize } = pageModal || this.payApprovalPageModal

    querySettleFinancialPage({
      pageNum: page,
      pageSize,
      condition: condition || this.payApprovalCondition
    }).then(resp => {
      if (resp && !resp.code) {
        this.payApprovalList = resp.data ? resp.data : [];
        this.payApprovalPageModal = resp.pageModal
      }
    })
  }

  // 财务结算进程
  @action fetchFinancial(pageModal, condition) {
    const { page, pageSize } = pageModal || this.financialPageModal

    querySettleFinancialPage({
      pageNum: page,
      pageSize,
      condition: condition || this.financialCondition
    }).then(resp => {
      if (resp && !resp.code) {
        this.financialList = resp.data ? resp.data : [];
        this.financialPageModal = resp.pageModal
      }
    })
  }

  // 获取公司列表
  @action setCompanyInfo = (data) => {
    this.companyList = data
    this.companyId = data[0].id

    this.settleCondition = {
      ...this.settleCondition,
      companyId: this.companyId
    }

    this.serialCondition = {
      ...this.serialCondition,
      companyId: this.companyId
    }

    this.serialApprovalCondition = {
      ...this.serialApprovalCondition,
      companyId: this.companyId
    }

    this.waterApprovalCondition = {
      ...this.waterApprovalCondition,
      companyId: this.companyId
    }

    this.payApprovalCondition = {
      ...this.payApprovalCondition,
      companyId: this.companyId
    }

    this.financialCondition = {
      ...this.financialCondition,
      companyId: this.companyId
    }
  }

  //  重置或查询 修改condition
  @action resetForm = (condition, key,resetPage=true) => {
    if (key === 1) {
      this.settleCondition = condition
      this.settlePageModal = {
        page: 1,
        pageSize: resetPage?10:this.settlePageModal.pageSize
      }
    }
    if (key === 2) {
      this.serialCondition = condition
      this.serialPageModal = {
        page: 1,
        pageSize: resetPage?10:this.serialPageModal.pageSize
      }
    }
    if (key === 3) {
      this.serialApprovalCondition = condition
      this.serialApprovalPageModal = {
        page: 1,
        pageSize: resetPage?10:this.serialApprovalPageModal.pageSize
      }

    }
    if (key === 4) {
      this.waterApprovalCondition = condition
      this.waterApprovalPageModal = {
        page: 1,
        pageSize: resetPage?10:this.waterApprovalPageModal.pageSize
      }
    }
    if (key === 5) {
      this.payApprovalCondition = condition
      this.payApprovalPageModal = {
        page: 1,
        pageSize: resetPage?10:this.payApprovalPageModal.pageSize
      }
    }
    if (key === 6) {
      this.financialCondition = condition
      this.financialPageModal = {
        page: 1,
        pageSize: resetPage?10:this.financialPageModal.pageSize
      }
    }
  }

  // 修改当前点击行的id
  @action changeSelectedRowKeys = (selectedRowKeys, key) => {
    if (key === 1) this.settleSelectedRowKeys = selectedRowKeys
    if (key === 2) this.serialSelectedRowKeys = selectedRowKeys
  }

  // 结算列表 选择
  @action settleOrderSelected = (orderId) => {
    const obj = {
      ids: this.settleSelectedRowKeys,
      type: 1 //结算类型 0-全部结算；1-勾选结算
    }
    settleOrders(obj).then(resp => {
      if (resp.code==="00000") {
        this.settleSelectedRowKeys=[]
        this.fetchSettlement();
        message.success("生成成功")
      }
    })
  }
  // 结算列表 全部
  @action settleOrderAll = (orderId) => {
    const obj = {
      ...this.settleCondition,
      type:0//结算类型 0-全部结算；1-勾选结算
    };
    settleOrders(obj).then(resp => {
      if (resp.code==="00000") {
        this.settleSelectedRowKeys=[]
        this.fetchSettlement();
        message.success("生成成功")
      }
    })
  }

    /**
   * 获取派遣单位-公司列表
   */
  @action getCompanyList = () => {
   return getDispatchCompanyList().then(resp => {
      return resp

    })
  }

    /**
   * 根据公司ID获取拖运货车-货车列表
   */
    @action  getTrailerList = (driverId) => {
      return getTrailerByDriverId(driverId).then(resp => {
        return resp.data

      })

  }

  /**
   * 根据拖车ID查询拖运司机-司机列表
   */
  @action getDriverList = (companyId) => {

      return showCheckDriverList(companyId).then(resp => {
        return resp.data
      })
  }

}



export default Freight;