import { observable, action, makeObservable } from "mobx"
import { appointmentPage } from 'requests/recycle'

class carDispatch {
    constructor() {
        makeObservable(this)
    }
    // 待派遣
    @observable toBeList = []
    @observable toBePageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable toBeCondition = {
        applyTime: '',
        carType: null,
        ownerType: null,
        source: null,
        dispatchStatus: 1
    }

    // 已派遣
    @observable dispatchedList = []
    @observable dispatchedPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable dispatchedCondition = {
        applyTime: '',
        carType: null,
        ownerType: null,
        source: null,
        dispatchStatus: 2
    }

    // 获取运费结算列表
    @action getToBeList(pageModal, condition) {
        // 生成流水
        const { page, pageSize } = pageModal || this.toBePageModal

        appointmentPage({
            pageNum: page,
            pageSize,
            condition: condition || this.toBeCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.toBeList = resp.data ? resp.data : [];
                this.toBePageModal = resp.pageModal
            }
        })
    }

    // 获取生成流水列表
    @action getDispatchedList(pageModal, condition) {
        // 生成流水
        const { page, pageSize } = pageModal || this.dispatchedPageModal

        appointmentPage({
            pageNum: page,
            pageSize,
            condition: condition || this.dispatchedCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.dispatchedList = resp.data ? resp.data : [];
                this.dispatchedPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.toBeCondition = {
                ...condition,
                dispatchStatus: 1
            }
            this.toBePageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.dispatchedCondition = {
                ...condition,
                dispatchStatus: 2
            }
            this.dispatchedPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default carDispatch