import { observable, action, makeObservable } from "mobx"
import { getInspection } from 'requests/weighingPlatform'

class vehicleStorage {
    constructor() {
        makeObservable(this)
    }
    // 待扣杂
    @observable toBeList = []
    @observable toBePageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable toBeCondition = {
        boutiqueCar: null,
        carBrand: "",
        licensePlate: "",
        type: 1,
    }

    // 待入库
    @observable tobeConfirmList = []
    @observable tobeConfirmPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable tobeConfirmCondition = {
        "boutiqueCar": null,
        "carBrand": "",
        "licensePlate": "",
        "type": 2,
    }

    // 已入库
    @observable confirmedList = []
    @observable confirmedPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable confirmedCondition = {
        "boutiqueCar": null,
        "carBrand": "",
        "licensePlate": "",
        "inStorageTime": "",
        "warehouseId": "",
        "type": 3,
    }

    // 待扣杂
    @action getToBeList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.toBePageModal

        getInspection({
            pageNum: page,
            pageSize,
            condition: condition || this.toBeCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.toBeList = resp.data ? resp.data : [];
                this.toBePageModal = resp.pageModal
            }
        })
    }

    // 待入库
    @action getTobeConfirmList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.tobeConfirmPageModal

        getInspection({
            pageNum: page,
            pageSize,
            condition: condition || this.tobeConfirmCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.tobeConfirmList = resp.data ? resp.data : [];
                this.tobeConfirmPageModal = resp.pageModal
            }
        })
    }

    // 已入库
    @action getConfirmedList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.confirmedPageModal

        getInspection({
            pageNum: page,
            pageSize,
            condition: condition || this.confirmedCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.confirmedList = resp.data ? resp.data : [];
                this.confirmedPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.toBeCondition = {
                ...condition,
                type: 1
            }
            this.toBePageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.tobeConfirmCondition = {
                ...condition,
                type: 2
            }
            this.tobeConfirmPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 3) {
            this.confirmedCondition = {
                ...condition,
                type: 3
            }
            this.confirmedPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default vehicleStorage