import { JSEncrypt } from 'jsencrypt'
import LoginStore from "../store/loginStore";
/** 
 * 检查按钮是否拥有权限
 * @param {String} auth 按钮的code
 * @return {Boolean}   返回权限列表是否含有当前按钮的code true为含有 false不含有
 */

// console.log(new LoginStore(), "+++++++++++++++234++++++++++++")
export const hasAuth = (auth) => {
    const authList = new LoginStore().authList && new LoginStore().authList
    if (auth) {
        // 如果当前按钮有权限
        let hasAuth = false;
        // console.log("authList-------",authList)
        let authListArr = authList ? typeof(authList) === 'string' ? JSON.parse(authList) : authList : []
        authListArr.map(item => {
            if (item === auth) {
                hasAuth = true
            }
        })
        return hasAuth
    } else {
        // 当前按钮没有权限， 任何角色都可以使用
        return true
    }
}

/**
 * 下拉树移除父节点
 * @param {Array} list 下拉树的所有节点
 * @param {Array} nodeList 下拉树的选中节点
 */
export const recursionChildren = (list, nodeList) => {
    // 树形控件移除传回来的父子节点
    list.map(item => {
        if (item.children && item.children.length > 0) {
            recursionChildren(item.children, nodeList)
            if (!item.children.every(i => nodeList.includes(i.id))) {
                // 如果只有部分子元素被选中， 那么父元素不被选中
                if (nodeList.indexOf(item.id) != -1) {
                    nodeList.splice(nodeList.indexOf(item.id), 1)
                }
            }
        }
    })
    return nodeList
}

/**
 * 下拉树增加父节点
 * @param {Array} list 下拉树的所有节点
 * @param {Array} nodeList 下拉树的选中节点
 */
export const recursionReset = (list, nodeList) => {
    // 树形控件 直接提交找回子节点的父节点
    list.map(item => {
        if (item.children && item.children.length > 0) {
            if (item.children.some(i => nodeList.includes(i.id))) {
                // 如果最后选中的是某个子元素 那么他的父元素也要被选中
                // 并且 如果选中的父元素不存在列表里 才push
                if (!nodeList.includes(item.id)) nodeList.push(item.id)
            }

            item.children.forEach(obj => {
                if (obj.children.some(i => nodeList.includes(i.id))) {
                    if (!nodeList.includes(obj.id)) nodeList.push(obj.id)
                    if (!nodeList.includes(item.id)) nodeList.push(item.id)
                }

                if (obj.children && obj.children.length > 0) {
                    obj.children.forEach(ele => {
                        if (ele.children.some(i => nodeList.includes(i.id))) {
                            if (!nodeList.includes(ele.id)) nodeList.push(ele.id)
                            if (!nodeList.includes(obj.id)) nodeList.push(obj.id)
                            if (!nodeList.includes(item.id)) nodeList.push(item.id)
                        }
                    })
                }
            })
            recursionReset(item.children, nodeList)
        }
    })
}

/**
 * 特殊字段加密
 * @param {String} fieldPsw 需要加密的字段
 * @return {String} fieldPsw  加密之后的字段
 */
export const craPassword = fieldPsw => {
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQ335gDR/KNsRnbWo7W4f0azS8xGZt6KAEZWpSgZYUrr5SKTAAowwAMJ2uJc777+GNHL5vRyPg7VUuLXgAxz6Y0WiwFlIGaxG5ZLB4Vk2OBB0QFML/jbfwmvu39tyTecM23kcvaLa9e36mxXHPL66CUwgQF/f/47NqHIIdPP9eQQIDAQAB');
    return encrypt.encrypt(fieldPsw)
}


//价格大写
export const capitalAmount = amount => {
    // 汉字的数字
    const cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    // 基本单位
    const cnIntRadice = ["", "拾", "佰", "仟"];
    // 对应整数部分扩展单位
    const cnIntUnits = ["", "万", "亿", "兆"];
    // 对应小数部分单位
    const cnDecUnits = ["角", "分", "毫", "厘"];
    // 整数金额时后面跟的字符
    const cnInteger = "整";
    // 整型完以后的单位
    const cnIntLast = "元";
    // 最大处理的数字
    const maxNum = 9999999999999999.99;
    // 金额整数部分
    let integerNum;
    // 金额小数部分
    let decimalNum;
    // 输出的中文金额字符串
    let chineseStr = "";
    // 分离金额后用的数组，预定义
    let parts;
    if (amount === "") { return ""; }
    amount = parseFloat(amount);
    if (amount >= maxNum) {
        // 超出最大处理数字
        return "";
    }
    if (amount === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    // 转换为字符串
    amount = amount.toString();
    if (amount.indexOf(".") === -1) {
        integerNum = amount;
        decimalNum = "";
    } else {
        parts = amount.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
            const n = integerNum.substr(i, 1);
            const p = IntLen - i - 1;
            const q = p / 4;
            const m = p % 4;
            if (n === "0") {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                // 归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n, 10)] + cnIntRadice[m];
            }
            if (m === 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    // 小数部分
    if (decimalNum !== "") {
        const decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            const n = decimalNum.substr(i, 1);
            if (n !== "0") {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr === "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum === "") {
        chineseStr += cnInteger;
    }
    return chineseStr;
}

/** 
 * 表单取消enter键默认提交事件
 * @param event 事件对象本身
 */
export const onkeydown = (event) => {
    if (event.keyCode == 13) event.preventDefault();
}

/** 
 * 验证车架号是否输入正确
 * @param value 输入的值
 */
export const checkVehicleIdentificationNumber = (rule, value, callback) => {
    let regExp = new RegExp(/^[0-9A-Za-z]{10}[A-Za-z]{1}[0-9A-Za-z]{6}$/);
    if (value && regExp.test(value)) {
        return Promise.resolve();
    } else {
        return Promise.reject("请输入17位字符，并且第十一位必须是字母");
    }
}

/** 
 * 验证车发动机号是否输入正确
 * @param value 输入的值
 */
export const checkEngine = (rule, value, callback) => {
    let regExp = new RegExp(/^[A-Za-z0-9]{7,8}$/);
    if (value && regExp.test(value)) {
        return Promise.resolve();
    } else {
        return Promise.reject("请输入正确的发动机号");
    }
}