/**
  * @description: 拖运监管
  * @author: 漆红 2020-10-10
  */

import Loadable from 'react-loadable'
import { Loading } from 'components'


const BannerIndex = Loadable({
  loader: () => import('./Banner'),
  loading: Loading
})
const BannerAdd = Loadable({
  loader: () => import('./Banner/add'),
  loading: Loading
})

const ServiceIndex = Loadable({
  loader: () => import('./Service'),
  loading: Loading
})

const ServiceAdd = Loadable({
  loader: () => import('./Service/add'),
  loading: Loading
})

const FansFeedbackIndex = Loadable({
  loader: () => import('./FansFeedback'),
  loading: Loading
})

const FansFeedbackAdd = Loadable({
  loader: () => import('./FansFeedback/add'),
  loading: Loading
})

const NewsIndex = Loadable({
  loader: () => import('./News'),
  loading: Loading
})

const NewsAdd = Loadable({
  loader: () => import('./News/add'),
  loading: Loading
})
const Dipose = Loadable({
  loader: () => import('./OnlineMessage/dipose'),
  loading: Loading
})

const CertificationIndex = Loadable({
  loader: () => import('./Certification'),
  loading: Loading
})

const  CertificationAdd = Loadable({
  loader: () => import('./Certification/add'),
  loading: Loading
})

const RecycledpartsList = Loadable({
  loader: () => import('./Recycledparts/RecycledpartsList'),
  loading: Loading
})

const RecycledpartsType = Loadable({
  loader: () => import('./Recycledparts/RecycledpartsType'),
  loading: Loading
})

const RecycledpartsAdd = Loadable({
  loader: () => import('./Recycledparts/RecycledpartsListAdd'),
  loading: Loading
})

const CompanyProfile = Loadable({
  loader: () => import('./CompanyProfile'),
  loading: Loading
})

const ProfessionalTeam = Loadable({
  loader: () => import('./CompanyProfile/ProfessionalTeam'),
  loading: Loading
})

const CompanyProfileAdd = Loadable({
  loader: () => import('./CompanyProfile/add'),
  loading: Loading
})

const OnlineMessage = Loadable({
  loader: () => import('./OnlineMessage'),
  loading: Loading
})

const AppointmentCar = Loadable({
  loader: () => import('./AppointmentCar'),
  loading: Loading
})


export {
  BannerIndex,
  BannerAdd,
  ServiceIndex,
  ServiceAdd,
  FansFeedbackIndex,
  FansFeedbackAdd,
  NewsIndex,
  NewsAdd,
  Dipose,
  CertificationIndex,
  CertificationAdd,
  RecycledpartsList,
  RecycledpartsType,
  RecycledpartsAdd,
  CompanyProfile,
  CompanyProfileAdd,
  OnlineMessage,
  AppointmentCar,
  ProfessionalTeam
}