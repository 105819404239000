import { observable, action, makeObservable } from "mobx"
import { getOrderWeightListPage, getAutoBackListPage } from 'requests/entranceInspection'

class weighingRecord {
    constructor() {
        makeObservable(this)
    }
    // 上门拖运
    @observable pendingList = []
    @observable pendingPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable pendingCondition = {
        "hasWeight": 0,
		"licensePlate": "",
    }

    // 自行回厂
    @observable acceptList = []
    @observable acceptPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable acceptCondition = {
        "boutiqueCar": null,
		"carBrand": "",
		"hasWeight": 0,
		"licensePlate": "",
		"poundDate": ""
    }

    // 上门拖运
    @action getPendingList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.pendingPageModal

        getOrderWeightListPage({
            pageNum: page,
            pageSize,
            condition: condition || this.pendingCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.pendingList = resp.data ? resp.data : [];
                this.pendingPageModal = resp.pageModal
            }
        })
    }

    // 自行回厂
    @action getAcceptList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.acceptPageModal

        getAutoBackListPage({
            pageNum: page,
            pageSize,
            condition: condition || this.acceptCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.acceptList = resp.data ? resp.data : [];
                this.acceptPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.pendingCondition = {
                ...condition,
                hasWeight: 0
            }
            this.pendingPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.acceptCondition = {
                ...condition,
                hasWeight: 0
            }
            this.acceptPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default weighingRecord