export const calculateTypeList = [
  {
    id:1,
    name:"整备质量"
  },
  {
    id:2,
    name:"载客数量"
  }
]

export const priceUnitList = [
  {
    id:1,
    name:"元/吨"
  },
  {
    id:2,
    name:"元/台"
  }
]

export const greaterSymbolsList = [
  {
    id:1,
    name:"<"
  },
  {
    id:2,
    name:"<="
  }
]

export const lessSymbolList = [
  {
    id:1,
    name:"<"
  },
  {
    id:2,
    name:"<= 于"
  }
]

export const  eunmeration = {
  calculateType:calculateTypeList,
  priceUnit:priceUnitList,
  greaterSymbols:greaterSymbolsList,
  lessSymbol:lessSymbolList
}



