

 import Loadable from 'react-loadable'
 import { Loading } from 'components'
 
 
 const proveOnline = Loadable({
     loader: () =>
         import ('./proveOnline/index'),
     loading: Loading
 })
 
 const ProveOffline = Loadable({
    loader: () =>
        import ('./proveOffline/index'),
    loading: Loading
})

 const hasDistribute = Loadable({
    loader: () =>
        import ('./proveOnline/hasDistribute'),
    loading: Loading
})

 export default [
     {
         pathname: "/admin/operational",
         title: '证明分发',
         exact: true,
         roles: '',
         component: proveOnline
     },    
     {
         pathname: "/admin/operational/distribute",
         title: '手续文档列表',
         exact: true,
         roles: '',
         component: proveOnline
     },    
     {
         pathname: "/admin/operational/hasDistribute",
         title: '线上分发列表',
         exact: true,
         roles: '',
         component: hasDistribute
     },    
     {
         pathname: "/admin/operational/proveOffline",  
         title: '线下分发列表',
         exact: true,
         roles: '',
         component: ProveOffline
     }
 ]