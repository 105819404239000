import axios from 'axios'
import { message } from 'antd'
import store from 'store'
import { formatResponse } from '../utils/utils'
import baseUrl from 'common/config'

const ajax = axios.create({
    baseURL: baseUrl
})

ajax.interceptors.request.use(config => {
        config.headers.token = sessionStorage.getItem('authToken') || ''
        store.loginStore.toggelIsLoading()
        config.timeout = 30000
        return config
    },
    error => {
        console.log("error----------------", error)
        store.loginStore.toggelIsLoading()
        message.error("网络请求失败")
    })

ajax.interceptors.response.use(resp => {
    store.loginStore.toggelIsLoading()
    if (resp.data.code === '00000') {
        resp = formatResponse(resp)
        let resData = resp.data.data
        return resData
    } else if (resp.data.code === '10003') {
        // token失效
        // message.error('用户登录失效，请重新登录')
        store.loginStore.loginOut()
        return false
    } else {
        message.error(resp.data.msg)
        return false
    }
}, error => {
    console.log("error----------------", error)
    store.loginStore.toggelIsLoading()
        // message.error("网络请求失败")
})

export default ajax