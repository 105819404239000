import { observable, flow, values, makeObservable } from "mobx";
import { loginRequests, getLoginMsg, getCurrentUserInfo } from 'requests/login'
import { getUserRoleList, getUserAuthList } from 'requests/user'
import { message } from 'antd'
import { adminRoutes } from 'routes'
console.log("adminRoutes", adminRoutes)

class Login {
    constructor() {
        makeObservable(this)
    }
        // 角色列表
        // @observable roleList = window.sessionStorage.getItem('roleList') || [];
        // 用户姓名
    @observable name = window.sessionStorage.getItem('name') || '';
    // 用户头像
    @observable logo = window.sessionStorage.getItem('logo') || '';
    // 角色列表
    @observable roleList = window.sessionStorage.getItem('roleList') || [];
    // 角色Id
    @observable roleId = window.sessionStorage.getItem('roleId') || '';
    // 权限列表
    @observable authList = window.sessionStorage.getItem('authList') || [];
    @observable promiseList = window.sessionStorage.getItem('promiseList') || [];
    // 是否展示角色列表选择
    @observable isShowRole = false;
    @observable userId = window.sessionStorage.getItem('userId') || '';
    @observable orgId = window.sessionStorage.getItem('orgId') || '';
    @observable isLogin = Boolean(window.localStorage.getItem('authToken')) || Boolean(window.sessionStorage.getItem('authToken') && window.sessionStorage.getItem('authToken') !== 'undefined');
    // @observable isLogin = false;
    @observable isLoading = false;

    changeStatusModal = () => {
        this.isShowRole = !this.isShowRole
    }

    toggelIsLoading = () => {
        // this.isLoading = !this.isLoading
    }

    closeModal = () => {
        this.isShowRole = false
    }

    // 查询当前用户角色列表
    getRoleList = flow(function*() {
        try {
            const roleList = yield getUserRoleList()
            this.roleList = JSON.stringify(roleList)
                // window.sessionStorage.setItem('roleList', JSON.stringify(roleList))
            if (roleList.length) {
                window.sessionStorage.setItem('roleList', JSON.stringify(roleList))
                if (roleList.length > 1) {
                    this.isShowRole = true
                    this.isLoading = false
                } else {
                    this.isShowRole = false
                    this.getAuthList(roleList[0].id)
                }
            } else {
                this.loginOut()
                this.closeModal()
                message.warning('您没有任何权限可以进入此系统');
            }
        } catch (error) {
            this.isLoading = false
                // message.error('网络异常')
        }
    }.bind(this))

    // 查询当前用户权限列表
    getAuthList = flow(function*(roleId) {
        try {
            window.sessionStorage.setItem('roleId', roleId)
            const authList = yield getUserAuthList({
                "roleId": roleId,
                "userId": this.userId
            })
            let adminRoutesList = JSON.parse(JSON.stringify(adminRoutes))

            const promiseList = adminRoutesList.filter(item => {
                return this.hasPromise(item, authList.authCodeList)
            })
            console.log("promiseList", promiseList, adminRoutesList, authList)
            this.isLoading = false
            if(promiseList===undefined||promiseList&&promiseList.length===0){
                message.error("登录失败，请重试")
                return false;
            }

            this.promiseList = JSON.stringify(promiseList)

            this.authList = JSON.stringify(authList.authCodeList)
            window.sessionStorage.setItem('authList', JSON.stringify(authList.authCodeList))
            window.sessionStorage.setItem('promiseList', JSON.stringify(promiseList))
            if (authList && authList.authCodeList.includes('login_web_login')) {
                // 修改登录状态
                setTimeout(() => {
                    this.isLogin = true
                }, 300)
                this.closeModal()

                // 获取当前用户的信息
                const userMsg = yield getCurrentUserInfo()
                this.name = userMsg.name
                this.logo = userMsg.logo
                this.orgId = userMsg.orgId
                window.sessionStorage.setItem('name', userMsg.name)
                window.sessionStorage.setItem('logo', userMsg.logo)
                window.sessionStorage.setItem('orgId', userMsg.orgId)
            } else {
                this.loginOut()
                this.closeModal()
                message.warning('该角色没有权限可以进入此系统');
            }
        } catch (error) {
            this.isLoading = false
                // message.error('网络异常')
        }
    }.bind(this))

    loginIn = flow(function*(value) {
        try {
            this.isLoading = true
            const loginUser = yield loginRequests({...value })
            console.log(loginUser)
                // 异步代码块会被自动包装成动作并修改状态

            this.isLoading = false
            if (loginUser && loginUser.token) {
                window.sessionStorage.setItem('authToken', loginUser.token)
                window.sessionStorage.setItem('userId', loginUser.userId)
                this.userId = loginUser.userId
                yield this.getRoleList()
            }
            // message.success('登录成功')
        } catch (error) {
            this.isLoading = false
                // message.error('网络异常')
        }
    }.bind(this))

    loginInQR = flow(function*(value) { // <- 注意*号，这是生成器函数！
        try {
            this.isLoading = true
            const loginUser = yield getLoginMsg(value)
            this.isLoading = false
            if (loginUser.status === 2) {
                // 取消登录/登录失败 返回当前状态值
                return loginUser.status
            } else if (loginUser.status === 3) {
                // 登录成功
                window.sessionStorage.setItem('authToken', loginUser.token)
                if (loginUser.token) {
                    // 修改登录状态
                    this.isLogin = true
                }
            }
        } catch (error) {
            this.isLoading = false
                // message.error('网络异常')
        }
    }.bind(this))


    loginOut = () => {
        // 退出登录 或者token失效
        sessionStorage.clear()
        this.authList = []
        this.isLogin = false
    }

    hasPromise(item, authList) {
        if (item.roles) {
            if (this.isIntersection(item.roles, authList)) {
                if (item.menus) {
                    const menus = item.menus.filter(items => { return this.hasPromise(items, authList) })
                    item.menus = menus
                    return item
                } else if (item.children && item.children.length > 0) {
                    const children = item.children.filter(items => { return this.hasPromise(items, authList) })
                    item.children = children
                    return item
                } else return item
            } else return false;
        } else {
            if (item.menus) {
                const menus = item.menus.filter(items => { return this.hasPromise(items, authList) })
                item.menus = menus
                return item
            } else if (item.children && item.children.length > 0) {
                const children = item.children.filter(items => { return this.hasPromise(items, authList) })
                item.children = children
                return item
            } else return item
        }
    }

    //数组是否有交集
    isIntersection(a, b) {
        const c = a.filter(v => b.includes(v))
        return c && c.length > 0 ? true : false
    }
}
export default Login;