/**
  * @description: 其他模块
  * @author: 漆红 2020-10-10
  */

import Loadable from 'react-loadable'
import {Loading} from '../components'

const Home = Loadable({
    loader: () => import('./Home'),
    loading: Loading
})

const Message = Loadable({
    loader: () => import('./Message'),
    loading: Loading
})

const Login = Loadable({
    loader: () => import('./Login'),
    loading: Loading
})

const NoAuth = Loadable({
    loader: () => import('./NoAuth'),
    loading: Loading
})

const NotFound = Loadable({
    loader: () => import('./NotFound'),
    loading: Loading
})

export {
    Login,
    NotFound,
    NoAuth,
    Home,
    Message,
}