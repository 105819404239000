import { observable, action, makeObservable } from "mobx";
import { getTrailerList, } from "requests/haul";
import { getCarTypeList,getDispatchCompanyList, getReuseCarPartTypeCategorys } from "requests/common";

class Common {
  constructor() {
    makeObservable(this);
  }
  @observable trailer_weight_List =
    window.sessionStorage.getItem("trailer_weight_List") || [];
  @observable carTypeList =  [];
  @observable menuCollapsed =
    window.sessionStorage.getItem("menuCollapsed") || false;
  @observable companyList = window.sessionStorage.getItem("companyList") || [];
  @observable reuseCategoryList = window.sessionStorage.getItem("reuseCarPartTypeCategorys") || [];
  
  // 获取运费结算列表
  @action getTrailerWeight() {
    if (this.trailer_weight_List.length === 0) {
      getTrailerList().then((resp) => {
        this.trailer_weight_List = resp;
      });
    }
  }

  // 折叠
  @action toggleMenuCollapsed(collapsed) {
    console.log("toggleMenuCollapsed", this.menuCollapsed);
    this.menuCollapsed = collapsed || !this.menuCollapsed;
  }

  @action featchCarTypeList = () => {
    if (this.carTypeList && this.carTypeList.length > 0) {
      return this.carTypeList;
    }
    return getCarTypeList().then((res) => {
      if (res.code === "00000") {
        this.carTypeList = res.data;
        return res.data;
      }
    });
  };
  @action featchCompanyList = () => {
    if (this.companyList && this.companyList.length > 0) {
      return this.companyList;
    }
    return getDispatchCompanyList().then((res) => {
      console.log("res--",res)
      if (res.code === "00000") {
        this.companyList = res.data;
        return res.data;
      }
    });
  };

    // 获取运费结算列表
    @action getReuseCategorys() {
      if (this.trailer_weight_List.length === 0) {
        getReuseCarPartTypeCategorys().then((res) => {
          if(res.code==="00000"){
            this.reuseCategoryList = res.data;
          }
        });
      }
    }
  

}

export default Common;
