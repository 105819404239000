/**
  * @description: 车辆回收
  * @author: 漆红 2020-10-10
  */

import Loadable from 'react-loadable'
import { Loading } from 'components'

const RecycleAppointment = Loadable({
  loader: () => import('./Car/Appointment'),
  loading: Loading
})

const DispatchDetail = Loadable({
  loader: () => import('./Car/Appointment/dispatchDetail'),
  loading: Loading
})

const DispatchList = Loadable({
  loader: () => import('./Car/Applied/tobedispatched'),
  loading: Loading
})

const RecycleApplied = Loadable({
  loader: () => import('./Car/Applied'),
  loading: Loading
})

const AppliedDetail = Loadable({
  loader: () => import('./Car/Applied/appliedDetail'),
  loading: Loading
})

const EditCarMsg = Loadable({
  loader: () => import('./Car/Applied/editCarMsg'),
  loading: Loading
})

const CheckCarDetail = Loadable({
  loader: () => import('./Car/Applied/checkCarDetail'),
  loading: Loading
})

const OemDetail = Loadable({
  loader: () => import('./Car/Applied/oemDetail'),
  loading: Loading
})

const DeterminationMoney = Loadable({
  loader: () => import('./Car/Applied/determinationMoney'),
  loading: Loading
})

const CheckCarMsg = Loadable({
  loader: () => import('./Car/Applied/CheckCarMsg'),
  loading: Loading
})

// const RecycleAccepted = Loadable({
//   loader: () => import('./Accepted'),
//   loading: Loading
// })

const RecycleAcceptedPadding = Loadable({
  loader: () => import('./Accepted/pending.js'),
  loading: Loading
})

const RecycleAccepted = Loadable({
  loader: () => import('./Accepted/accept.js'),
  loading: Loading
})

const RecycleBalance = Loadable({
  loader: () => import('./Balance'),
  loading: Loading
})

export {
  RecycleAppointment,
  RecycleApplied,
  RecycleAccepted,
  RecycleAcceptedPadding,
  RecycleBalance,
  DispatchList,
  DispatchDetail,
  AppliedDetail,
  EditCarMsg,
  CheckCarDetail,
  OemDetail,
  CheckCarMsg,
  DeterminationMoney
}