/**
 * @description: 回收车辆
 * @author: 漆红 2021-1-25
 */

import ajax from './index'
import request from './request'

// 预约报废分页列表
export const appointmentPage = (params) => {
    return ajax.post(`/v1/scrape/appointment/appointmentPage`, params)
}

// 预约报废 -> 车辆详情
export const appointmentDetail = (appointmentId) => {
    return ajax.get(`/v1/scrape/appointment/appointmentDetail/${appointmentId}`)
}

// 预约报废 -> 派遣
export const dispatch = (params) => {
    return ajax.post(`/v1/scrape/appointment/dispatch`, params)
}

// 预约报废 -> 车辆顾问列表
export const getScrapeCarConsultant = () => {
    return ajax.get(`/instance/getScrapeCarConsultant`)
}

// 申请报废分页列表
export const carPage = (params) => {
    return ajax.post(`/v1/scrape/car/carPage`, params)
}

// 申请报废 -> 车辆详情
export const carDetail = (carId) => {
    return ajax.get(`/v1/scrape/car/carDetail/${carId}`)
}

// 申请报废 -> 派遣
export const dispatchCarResidual = (params) => {
    return ajax.post(`/v1/scrape/car/dispatch`, params)
}

// 申请报废 -> 车辆残值认定人员列表
export const getScrapeCarResidual = () => {
    return ajax.get(`/instance/getScrapeCarResidual`)
}

// 申请报废 -> 获取初始化证件信息列表
export const getCertificateList = (ownerType) => {
    return ajax.get(`/v1/certificate/getCertificateList/${ownerType}`)
}

// 申请报废 -> 获取车辆五大总成信息
export const getCarFiveAssembly = () => {
    return ajax.get(`/v1/oem/getCarFiveAssembly`)
}

// 申请报废 -> 获取车辆非五大总成信息
export const getCarOemInfo = () => {
    return ajax.get(`/v1/oem/getCarOemInfo`)
}

// 申请报废 -> 报废车零件信息核查
export const checkCarOemInfo = (params) => {
    return ajax.post(`/v1/scrape/car/checkCarOemInfo`, params)
}

// 申请报废 -> 信息核查
export const checkCarInfo = (params) => {
    return ajax.post(`/v1/scrape/car/checkCarInfo`, params)
}

// 申请报废 -> 获取车辆信息核查详情
export const carInfoCheckDetail = (carId) => {
    return ajax.get(`/v1/scrape/car/carInfoCheckDetail/${carId}`)
}

// 申请报废 -> 获取车辆零件核查详情
export const carPartInfoCheckDetail = (carId) => {
    return ajax.get(`/v1/scrape/car/carPartInfoCheckDetail/${carId}`)
}

// 申请报废 -> 申请精品车
export const applyBoutiqueCar = (params) => {
    return ajax.post(`/v1/scrape/car/applyBoutiqueCar`, params)
}

// 申请报废 -> 认定车辆残值款
export const cognizanceCarResidual = (params) => {
    return ajax.post(`/v1/scrape/car/cognizanceCarResidual`, params)
}

// 申请报废 -> 认定车辆残值款详情
export const cognizanceCarResidualDetail = (carId) => {
    return ajax.get(`/v1/scrape/car/cognizanceCarResidualDetail/${carId}`)
}

// 申请报废 -> 编辑车辆基本信息
export const updateCar = (params) => {
    return ajax.post(`/v1/scrape/car/updateCar`, params)
}

// 受理报废 -> 批量受理报废车
export const batchAcceptCar = (params) => {
    return ajax.post(`/v1/scrape/car/batchAcceptCar`, params)
}

// 受理报废 -> 设置取车时间
export const setCarAppointmentTime = (params) => {
    return ajax.post(`/v1/scrape/car/setCarAppointmentTime`, params)
}

// 待认定 -> 完善信息 -> 车辆使用详情
export const getScrapeList = () => {
    return ajax.get(`/v1/scrape/codeType/getScrapeList/5`)
}

//  获取车辆部位非五大总成信息
export const getCarPositionTypeOemInfo = (params) => {
    return request.get(`v1/oem/getCarPositionTypeOemInfo`, params)
}


export const carPositionPartInfoCheckDetail = (cartId) => {
    return request.get(`v1/scrape/car/carPositionPartInfoCheckDetail/${cartId}`)
}

//  获取车辆部位非五大总成信息
export const returnCar = (params) => {
    return request.post(`v1/scrape/car/returnCar`, params)
}


//  获取第三方的车辆列表
export const getThirdCarPageList = (params) => {
    return request.post(`v1/scrape/car/getThirdCarPageList`, params)
}


//  分页查询用户列表
export const getErpUserInfo = (params) => {
    return request.post(`/v1/erpUserInfo/getErpUserInfo`, params)
}
//  新增或则是修改 异常
export const removeErpUser = (params) => {
    return request.post(`/v1/erpUserInfo/deleteErpUser/` + params.id)
}

//  新增或则是修改 异常
export const saveOrUpdateErpUser = (params) => {
    return request.post(`/v1/erpUserInfo/saveOrUpdateErpUser`, params)
}