/**
  * @description: 报废通道
  * @author: 漆红 2020-10-10
  */

 import Loadable from 'react-loadable'
 import { Loading } from 'components'

// 渠道概览
const ChannelOverview = Loadable({
    loader: () => import('./ChannelOverview'),
    loading: Loading
})


// 渠道管理
const ChannelManagement = Loadable({
    loader: () => import('./ChannelManagement'),
    loading: Loading
})

// 渠道管理 - 新增编辑
const EnterpriseAdd = Loadable({
    loader: () => import('./ChannelManagement/enterpriseAdd'),
    loading: Loading
})

// 渠道管理 - 渠道列表
const Channel = Loadable({
    loader: () => import('./ChannelManagement/channel'),
    loading: Loading
})

// 我的企业
const MyBusiness = Loadable({
    loader: () => import('./MyChannel/myBusiness'),
    loading: Loading
})

// 渠道管理
const ChannelManage = Loadable({
    loader: () => import('./MyChannel/channelManage'),
    loading: Loading
})

// 渠道管理 - 渠道新增
const ChannelAdd = Loadable({
    loader: () => import('./ChannelManagement/ChannelAdd'),
    loading: Loading
})

// 返现管理
const CashBack = Loadable({
    loader: () => import('./CashBack'),
    loading: Loading
})

// 返现失败详情
const FailDetail = Loadable({
    loader: () => import('./CashBack/failDetail'),
    loading: Loading
})

// 活动管理
const EventManagement = Loadable({
    loader: () => import('./EventManagement'),
    loading: Loading
})

// 活动管理 - 活动新增
const AddEvent = Loadable({
    loader: () => import('./EventManagement/AddEvent'),
    loading: Loading
})

// 活动管理 - 活动编辑
const EditEvent = Loadable({
    loader: () => import('./EventManagement/EditEvent'),
    loading: Loading
})

// 活动管理
const EventList = Loadable({
    loader: () => import('./EventManagement/EventList'),
    loading: Loading
})

// 活动管理 - 活动详情
const EventDetail = Loadable({
    loader: () => import('./EventManagement/EventDetail'),
    loading: Loading
})


// 渠道车辆-线上提交
const FirstCheck = Loadable({
    loader: () => import('./Cars/FirstCheck'),
    loading: Loading
})

// 渠道车辆-线下提交
const ScrapStore = Loadable({
    loader: () => import('./Cars/Store'),
    loading: Loading
})

// 渠道车辆-用户拒绝
const RefuseCars = Loadable({
    loader: () => import('./Cars/Refuse'),
    loading: Loading
})


// 渠道车辆-提报日志
const SecondPusLog = Loadable({
    loader: () => import('./Cars/SecondPusLog'),
    loading: Loading
})

//提交车辆账户管理
const BundlingErpDataPushConfig = Loadable({
    loader: () => import('./BundlingErpDataPushConfig'),
    loading: Loading
})


export {
    ChannelOverview,
    ChannelManagement,
    EnterpriseAdd,
    MyBusiness,
    CashBack,
    EventManagement,
    ChannelAdd,
    AddEvent,
    EditEvent,
    EventList,
    EventDetail,
    Channel,
    ChannelManage,
    FirstCheck,
    ScrapStore,
    RefuseCars,
    SecondPusLog,
    FailDetail,
    BundlingErpDataPushConfig
}