/**
 * @description: 入场查验
 * @author: 漆红 2021-3-11
 */

import ajax from './index'
import request from './request'

// 称重管理-自行回厂
export const getAutoBackListPage = (params) => {
    return ajax.post(`/v1/inspection/car/getAutoBackCarWeightListPage`, params)
}

// 称重管理-上门拖车
export const getOrderWeightListPage = (params) => {
    return ajax.post(`/v1/inspection/order/getOrderWeightListPage`, params)
}

// 证件查验-列表
export const getCertificateCarListPage = (params) => {
    return ajax.post(`/v1/inspection/car/getCertificateCarListPage`, params)
}

// 证件查验-车辆问题原因列表
export const getProblemReasonList = (params) => {
    return ajax.get(`/v1/inspection/code/getProblemReasonList/1`, params)
}

// 证件查验-查验
export const checkCarCertificate = (params) => {
    return ajax.post(`/v1/mini/car/checkCarCertificate`, params)
}

// 11207-小程序-申请报废-判定车辆是否存在
export const identityCar = (params) => {
    return request.post(`/v1/inspection/car/identityCar`, params)
}


// 车辆详情
export const getCarDetail = (carId) => {
    return ajax.get(`/v1/inspection/car/getCarDetail/${carId}`)
}

// 车辆查验-列表
export const getCheckCarListPage = (params) => {
    return ajax.post(`/v1/inspection/car/getCheckCarListPage`, params)
}

// 处理报废车辆问题
export const dealCarCheckResultStatus = (carId, checkResultStatus) => {
    return ajax.post(`/v1/inspection/car/dealCarCheckResultStatus/${carId}/${checkResultStatus}`)
}

// 缺件扣款-列表
export const getMissPartCarListPage = (params) => {
    return ajax.post(`/v1/inspection/car/getMissPartCarListPage`, params)
}

// 缺件扣款 - 认定
export const getMissPartDetail = (carId) => {
    return ajax.get(`/v1/inspection/car/getMissPartDetail/${carId}`)
}

// 缺件扣款-扣款报表
export const getDeductMoneyReport = (params) => {
    return ajax.post(`/v1/inspection/car/getDeductMoneyReport`, params)
}

// 缺件扣款-扣款报表
export const saveMissPartDeductMoney = (params) => {
    return ajax.post(`/v1/inspection/car/saveMissPartDeductMoney`, params)
}

// 查验详情
export const getCheckCarInfo = (carId) => {
    return ajax.get(`/v1/mini/car/getCheckCarInfo/${carId}`)
}
