import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { totalRoutes } from './routes'
import { observer, inject } from "mobx-react";
import './App.less'
import Frame from './components/Frame'

@inject("loginStore")
@observer
class App extends Component {
  render() {
    const { promiseList } = this.props.loginStore
    return (
      this.props.loginStore.isLogin && promiseList
        ?
        <Frame>
          
          <Switch>
            {
              totalRoutes.map((route, index) => {
                return (
                  <Route
                    key={route.pathname + index}
                    path={route.pathname}
                    exact={route.exact}
                    render={(routerProps) => {
                      if (route.roles) {
                        const hasPromission = route.roles.some(role => this.props.loginStore.authList.includes(role))
                        return hasPromission ? <route.component {...routerProps} /> : <Redirect to='/admin/noauth' />
                      } else {
                        return <route.component {...routerProps} />
                      }
                    }} />
                )
              })
            }
            <Redirect from="/admin" to="/admin/home" />
            <Redirect to="/404" />
          </Switch>
        </Frame>
        :
        <Redirect to='/login' />
    )
  }
}
export default App
