/**
  * @description: 左侧顶部容器 导航栏
  * @author: 漆红
  */
import React, { Component } from 'react'
import { Layout, Menu, Breadcrumb, Dropdown, Typography, message, Modal, Select, Spin } from 'antd'
import { withRouter,Link } from 'react-router-dom'
import { observer, inject } from "mobx-react";
import {
    CaretDownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    CaretRightOutlined,
    LogoutOutlined,
    SwapOutlined,
} from '@ant-design/icons';
import {headNavRoutes, totalRoutes } from 'routes'
import './frame.less'
import logo from 'assets/images/title-img.png'
import IconFont from 'components/Iconfont'
import ChangePassword from "./ChangePassword"

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

const urlTitle = {
    '/admin/usermanagement': '用户管理',
    '/admin/scrapchannel': '报废渠道',
    '/admin/checkSystem': '拖运监管',
    '/admin/message': '模板消息',
    '/admin/commonModule': '系统配置',
    '/admin/website': '官网管理',
    '/admin/recycle': '车辆回收',
    '/admin/admission': '入场查验',
    '/admin/platform': '称重平台',
}
totalRoutes.forEach(item => {
    urlTitle[item.pathname] = item.title
})

const getArrayProps = (array, key) => {
    // 面包屑 -> 将路由转换
    key = key || "value";
    const res = [];
    if (array) {
        array.forEach(function (t) {
            res.push(t[key]);
        });
    }
    return res;
}

@withRouter
@inject("loginStore")
@inject("CommonStore")
@observer
class Frame extends Component {
    //模块路由地址
    rootSubmenuKeys = getArrayProps(headNavRoutes, 'pathname')

    constructor() {
        super();
        this.state = {
            // 左侧侧边栏当前是收起还是展开的状态
            collapsed: false,
            // 当前展开的二级菜单
            // openKeys: openKeys
            openKeys: [],
            // 选择角色弹框
            isShowRole: false,
            // 角色Id
            roleId: '',
            promiseList: [],
            promiseListArr: [],
            selectedArr:[],
            selectedArrTop:[]
        }
    }

   changePasswordRef = React.createRef()

    componentDidMount(){
        const {
            pathname,
            search
        } = this.props.location

        this.calculatePath(pathname)

    }

    calculatePath(pathname){
        const{ search } = this.props.location

        let selectedArr = pathname.split('/')
        const selectedArrTop = pathname.split('/')
        selectedArrTop.length = 3
        selectedArr.length = selectedArr.length>=4?(selectedArr.length-(search===""?0:1)):3
        this.setState({
            selectedArrTop,
            selectedArr
        })
        console.log("selectedArrTop",selectedArrTop,selectedArr)
    }

    /**
     * 菜单管理
     */
    onMenuClick = ({ key }) => {
        if (key === '/loginOut') {
            confirm({
                title: '确认退出登录？',
                content: '',
                okText: "确认",
                cancelText: "取消",
                onOk: () => {
                    this.props.loginStore.loginOut()
                    message.success('退出成功');
                }
            });
        } else if (key === '/checkRole') {
            this.props.loginStore.changeStatusModal()
        } else if (key === '/changePassword'){
            this.changePasswordRef.current.showModal()
        }
         else {
            this.props.history.push(key)
            this.calculatePath(key)
        }
    }

    /**
     * 切换角色弹框确认选择
     */
    handleOk = () => {
        const { roleId } = this.state
        roleId
            ?
            this.props.loginStore.getAuthList(roleId)
            :
            message.info('请选择一个角色进入')
    }

    /**
     * 切换角色弹框取消选择
     */
    handleCancel = () => {
        this.props.loginStore.changeStatusModal()
    }

    /**
     * 切换角色
     */
    handleChange = value => {
        this.setState({
            roleId: value
        })
    }

    filterChildren = (children) => {
        const { authList } = this.props.loginStore
        return children.filter(item => item.roles === '' || item.roles.some(role => authList.includes(role)))
    }

    /**
     * 点击顶部模块触发操作
     */
    onHeadMenuClick = ({ key }) => {
        const promiseListstr = this.props.loginStore.promiseList || sessionStorage.getItem('promiseList')
        const promiseList = promiseListstr.length ? JSON.parse(promiseListstr) : []
        // 修改左侧tabbar二级菜单开启状态
        if (promiseList.some(ele => ele.pathname === key)) {
            promiseList.filter(ele => ele.pathname === key).forEach(menu => {
                if (Array.isArray(menu.menus) && menu.menus.length > 0) {
                    if (menu.menus) {
                        if (Array.isArray(menu.menus[0].children) && menu.menus[0].children.length > 0) {
                            this.props.history.push(menu.menus[0].children[0].pathname)
                            this.calculatePath(menu.menus[0].children[0].pathname)
                        } else {
                            this.props.history.push(menu.menus[0].pathname)
                            this.calculatePath(menu.menus[0].pathname)
                        }
                    }
                } else {
                    this.props.history.push(key)
                }
            })
        } else {
            // 点击左侧tabbar
            this.props.history.push(key)
        }
    };

    /*
     * 切换左侧侧边栏状态
     */
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
        this.props.CommonStore.toggleMenuCollapsed()
    };

    /**
     * 检查
     */
    onOpenChange = openKeys => {
        console.log("openKeys",openKeys)
        if (Array.isArray(openKeys) && openKeys.length > 0) {
            const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
            console.log("latestOpenKey",latestOpenKey)
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.setState({ openKeys:[latestOpenKey] });
            } else {
                this.setState({
                    openKeys: latestOpenKey ? [latestOpenKey] : [],
                });
            }
        } else {
            console.warn("一级菜单");
            this.setState({
                openKeys: [],
            });
        }
    };

    renderMenu = (roleList) => (
        <Menu
            style={{ minWidth: "140px" }}
            onClick={this.onMenuClick}
        >
            {
                roleList.length >= 2
                    ?
                    <Menu.Item
                        key="/checkRole"
                        icon={<SwapOutlined />}
                    >
                        <span >
                            切换角色 <CaretRightOutlined style={{ color: '#ccc', float: 'right',height:28,lineHeight:"28px" }} />
                        </span>
                    </Menu.Item>
                    :
                    ''
            }
            <Menu.Item
                key="/changePassword"
                // icon={<LogoutOutlined />}
            >
                <span className="">
                    修改密码 
                </span>
            </Menu.Item>
            <Menu.Item
                key="/loginOut"
                icon={<LogoutOutlined />}
            >
                <span className="">
                    退出登录 
                </span>
            </Menu.Item>
        </Menu>
    )

    render() {
        const promiseListstr = this.props.loginStore.promiseList || sessionStorage.getItem('promiseList')
        const {menuCollapsed}=this.props.CommonStore
        const {selectedArrTop,selectedArr}=this.state
        let promiseList = []
        if(promiseListstr.length) promiseList = JSON.parse(promiseListstr)
        const {
            pathname,
            search
        } = this.props.location
        // console.log("currentPath----",this.props.location)
        const { loginStore } = this.props
        // let selectedArr = pathname.split('/')
        // const selectedArrTop = pathname.split('/')
        // selectedArrTop.length = 3
        // selectedArr.length = selectedArr.length>=4?(selectedArr.length-(search===""?0:1)):3

        const pathSnippets = pathname.split('/').filter(i => i);
        //将切分的路径读出来，形成面包屑，存放到this.extraBreadcrumbItems
        const breadcrumbItems = pathSnippets.map((_, index) => {
            if (index > 0) {
                const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
                return (
                    <Breadcrumb.Item key={url}>
                        {index>1&&pathSnippets.length!==index+1?
                        <Link to={url}  className="clickabled">
                            {urlTitle[url]}
                        </Link>:urlTitle[url]
                        }
                    </Breadcrumb.Item>
                );
            } else {
                return null
            }

        });

        let authList = sessionStorage.getItem('authList') || loginStore.authList
        if (authList.length) authList = JSON.parse(authList)

        let roleList = sessionStorage.getItem('roleList') || loginStore.roleList
        if (roleList.length) roleList = JSON.parse(roleList)


        const menuLeft = i => {
            return (
                <SubMenu
                    key={i.pathname}
                    icon={
                        <IconFont type={i.icon || "iconqingdan"} style={{ fontSize: '20px' }}></IconFont>
                    }
                    title={i.title}
                >
                    {
                        i.children.map(m => {
                            return (
                                <Menu.Item
                                    key={m.pathname}
                                >
                                    {m.title}
                                </Menu.Item>
                            )
                        })
                    }
                </SubMenu>
            )
        };

        const menuItems = item => {
            return (
                <Menu.Item
                    key={item.pathname}
                >
                    {item.title}
                </Menu.Item>
            )
        }

        const menuItemsOneRange = item => {
            return (
                <Menu.Item
                    key={item.pathname}
                    icon={<IconFont type={item.icon || "iconqingdan"} style={{ fontSize: '20px' }}></IconFont>}
                >
                    {item.title}
                </Menu.Item>
            )
        }

        return (
            <Layout className="page_wrapPer" style={{ minHeight: '100vh' }}>
                <Modal
                    title="角色选择"
                    visible={loginStore.isShowRole}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Select
                        style={{ width: 280 }}
                        onChange={this.handleChange}
                        placeholder="请选择当前进入角色"
                    >
                        {
                            roleList.map(item => {
                                return <Option key={item.id} value={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Modal>
                <Header className="site-layout-background header">
                    <div className="header_left_logo">
                        <img src={logo} alt="成都兴原数字化管理平台" />
                        <Text>成都兴原</Text>
                    </div>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        onSelect={this.onHeadMenuClick}
                        selectedKeys={[selectedArrTop.join('/')]}
                    >
                        {
                            promiseList && promiseList.map(item => {
                                return menuItems(item)
                            })
                        }
                    </Menu>
                    <div className="" style={{cursor:"pointer"}}>
                        <Dropdown overlay={this.renderMenu(roleList)} trigger={['click']}>
                            <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {this.props.loginStore.name} <CaretDownOutlined style={{ color: '#fff' }} />
                            </span>
                        </Dropdown>
                        {/* <MoreOutlined style={{ color: '#fff' }} /> */}
                    </div>
                </Header>
                <Layout className="site-layout" >
                    <Sider collapsible trigger={null} collapsed={menuCollapsed} className="site-layout-background sider" width={227}>
                        {/* 菜单 */}
                        <div className="leftmenu" style={{
                            height: '100%',
                            overflowX: 'hidden'
                        }}>
                            <Menu
                                theme="dark"
                                mode="inline"
                                style={{ height: '100%', borderRight: 0, fontSize: '16px',cursor:"pointer" }}
                                defaultSelectedKeys={[this.state.openKeys]}
                                onClick={this.onMenuClick}
                                onOpenChange={this.onOpenChange}
                                selectedKeys={[selectedArr.join('/')]}
                                openKeys={this.state.openKeys}
                            >
                                {
                                    promiseList && promiseList.filter(
                                        item => {
                                            const { openKeys } = this.state;
                                            const latestOpenKey = [pathname].find(key => this.state.openKeys.indexOf(key) === -1) || ''
                                            const keys = openKeys ? openKeys : latestOpenKey ? latestOpenKey : '/admin/home'
                                            return item.pathname === keys.toString() || latestOpenKey.includes(item.pathname);
                                        }
                                    ).map(
                                        item => {
                                            return item.menus;
                                        }
                                    ).map(item => {
                                        return item.map(i => {
                                            if (Array.isArray(i.children) && i.children.length > 0) {
                                                return menuLeft(i)
                                            } else {
                                                return menuItemsOneRange(i)
                                            }
                                        })
                                    })
                                }
                            </Menu>
                        </div>
                    </Sider >
                    <Layout className="contain_layout">
                        <div className="header_bread">
                            {React.createElement(menuCollapsed? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: this.toggle,
                            })}
                            <Breadcrumb separator=">">
                                {breadcrumbItems}
                            </Breadcrumb>
                            {/* {
                                selectedArr.length >= 5
                                    ?
                                    <Button style={{ right: 20, position: "absolute" }} type="text" size="small" icon={<LeftOutlined />} onClick={() => { this.props.history.goBack() }}>返回</Button>
                                    :
                                    ''
                            } */}
                        </div>
                        <Content className="chilren_content">
                            <Spin spinning={loginStore.isLoading}>
                                {this.props.children}
                            </Spin>
                        </Content>
                    </Layout>
                </Layout >
                <ChangePassword cRef={this.changePasswordRef} loginStore={this.props.loginStore}/>
            </Layout >
        )
    }
}

export default Frame
