/**
 * @description: 系统配置
 * @author: louis 201-8-16
 */

import Loadable from 'react-loadable'
import { Loading } from 'components'

const CheckCarInfoDetail = Loadable({
    loader: () =>
        import ('./CarDetail'),
    loading: Loading
})



const PrintTable = Loadable({
    loader: () =>
        import ('./PrintTable'),
    loading: Loading
})

const PrintPayWater = Loadable({
    loader: () =>
        import ('./PrintTable/mergeCellTable'),
    loading: Loading
})

const UkeyManage = Loadable({
    loader: () =>
        import ('./Ukeymanage'),
    loading: Loading
})

export { CheckCarInfoDetail, PrintTable, PrintPayWater, UkeyManage };