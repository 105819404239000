/**
 * @description: 所有路由配置
 * @author: 漆红 2020-10-10
 */

import {
    NoAuth,
    Home,
} from 'views'

import {
    MessageList,
    MessageTemplate,
    MessageTypes
} from 'views/Message'

import CommonModuleRoutes from "views/CommonModule"

import {
    BannerIndex,
    BannerAdd,
    ServiceIndex,
    ServiceAdd,
    FansFeedbackIndex,
    FansFeedbackAdd,
    NewsIndex,
    NewsAdd,
    Dipose,
    ProfessionalTeam,
    RecycledpartsList,
    RecycledpartsType,
    RecycledpartsAdd,
    CompanyProfile,
    CompanyProfileAdd,
    OnlineMessage,
    AppointmentCar,
    CertificationIndex,
    CertificationAdd,
} from 'views/Website'

import {
    carProblem,
    carProblemPending,
    carProblemAccept,
    recoveryCarPadding,
    certificatesCheck,
    certificatesCheckPeding,
    certificatesCheckAccept,
    certificatesCheckConfirmed,
    recoveryCar,
    unweighted,
    weightRecord,
    Responsibility,
    ResponsibilityPadding,
    ResponsibilityAccept,
    confirmResponsibility,
    Reportform,
    checkCarDetails,
    check
} from 'views/EntranceInspection'

import {
    RecycleAppointment,
    RecycleApplied,
    RecycleAccepted,
    RecycleBalance,
    DispatchDetail,
    DispatchList,
    AppliedDetail,
    EditCarMsg,
    CheckCarDetail,
    OemDetail,
    CheckCarMsg,
    DeterminationMoney
} from 'views/Recycle'

import {
    CheckHome,
    DistributeAddress,
    SystemAddress,
    CheckCarInfoList,
    CheckCarCentralize,
    CheckCarCentralizeComp,
    CheckCarCentralizeCompDispatch,
    CompanyCarInfoList,
    CheckCaptureList,
    CheckCapturePublish,
    CheckCaptureDetail,
    CheckTimeout,
    CheckTimeoutComp,
    CheckTimeoutDetail,
    CheckCarInfoAdd,
    CheckCarOrder,
    CheckCarQuickPublish,
    CheckLogisticsCompanyList,
    LogisticsCompanyAdd,
    LogisticsCompanyDetail,
    CheckLogisticsTrailerList,
    LogisticsTrailerAdd,
    LogisticsTrailerActualDetail,
    CheckLogisticsDriverList,
    LogisticsDriverAdd,
    CheckLogisticsNvrList,
    CheckSystemApproval,
    CheckLogisticsSimList,
    LogisticsNvrAdd,
    CheckTaskList,
    CheckHangUp,
    CheckConfig,
    CheckOrderList,
    OrderForceList,
    CheckSystemExceptional,
    CheckSystemDeadhead,
    CheckSystemDeadheadCompany,
    LoadFail,
    CheckSystemHandOver,
    CheckSystemHandOverPublish,
    CheckOrderDetail,
    CarLackDetail,
    CheckTaskDetail,
    CheckTaskPath,
    CheckTaskDiffence,
    FreightList,
    AddAddress,
    CarAreaSetting,
    FreightDetail,
    FreightIndex,
    ApprovalDetail,
    OrderDetail,
    SettlementFreightDetail,
    SettlementQueryList,
    SettlementFreightList,
    SettlementSerial,
    SettlementSerialApproval,
    SettlementException,
    SettlementDeduct,
    CheckTaskStatistics,
    RecommendRoute,
    VideoDetail,
    OrderRemove,
    SettlementDetails,
    SettleCarModalDetails,
    SettleOrderDetails,
    ConfirmSettle,
    PassRate,
    DeliveryRate,
    DataIndicators,
    DataLook,
    LoadFactorRate,
    OperationLogList,
    ReturnCar,
    QuickEmit
} from 'views/CheckSystem'

import {
    ChannelOverview,
    ChannelManagement,
    MyBusiness,
    CashBack,
    RefuseCars,
    FirstCheck,
    ScrapStore,
    EventManagement,
    ChannelAdd,
    AddEvent,
    EditEvent,
    EventList,
    EventDetail,
    EnterpriseAdd,
    Channel,
    ChannelManage,
    FailDetail,
    SecondPusLog,
    BundlingErpDataPushConfig
} from 'views/ScrapChannel'

import {
    carDetail,
    weightDetail,
} from 'views/WeighingPlatform'

import FinanceRoutes from 'views/Finance'

import OperationalRoutes from 'views/Operational'

import {ReuseRoutes} from 'views/Reuse'

import {
    User,
    UserReuse,
    UserReuseEdit,
    UserAdd,
    UserUpdate,
    Auth,
    Role,
    RoleAdd,
    RoleUpdate,
    RoleView,
    Org,
    OrgAdd,
    OrgUpdate,
    OrgManagerAuthor,
    AuthAdd,
    UserAuthor,
    PostAuthUpdate,
    Department,
    AuthUpdate
} from 'views/UserManagement'

import {
    CheckCarInfoDetail,
    PrintTable,
} from "views/CommonPage"


export const totalRoutes = [{
        pathname: '/admin/home',
        component: Home,
        title: '首页',
        roles: '',
    },
    {
        pathname: '/admin/usermanagement/org',
        component: Org,
        title: '合作伙伴管理',
        roles: ['user_org_query'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/org/orgAdd',
        component: OrgAdd,
        title: '新增合作伙伴',
        roles: ['user_org_add'],
    }, {
        pathname: '/admin/usermanagement/org/orgUpdate',
        component: OrgUpdate,
        title: '修改合作伙伴',
        roles: ['user_org_update'],
    }, {
        pathname: '/admin/usermanagement/org/orgManagerAuthor',
        component: OrgManagerAuthor,
        title: '合作伙伴管理员授权',
        roles: ['user_org_author'],
    }, {
        pathname: '/admin/usermanagement/department',
        component: Department,
        title: '部门管理',
        roles: ['user_department_query'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/role',
        component: Role,
        title: '角色管理',
        roles: ['user_role_query'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/role/roleAdd',
        component: RoleAdd,
        title: '添加角色',
        roles: ['user_role_add'],
    }, {
        pathname: '/admin/usermanagement/role/roleUpdate',
        component: RoleUpdate,
        title: '更新角色',
        roles: ['user_role_update'],
    }, {
        pathname: '/admin/usermanagement/role/roleView',
        component: RoleView,
        title: '查看角色',
        roles: ['user_role_query'],
    }, {
        pathname: '/admin/usermanagement/auth',
        component: Auth,
        title: '权限管理',
        roles: ['user_auth_query'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/auth/authAdd',
        component: AuthAdd,
        title: '新增权限',
        roles: ['user_auth_add'],
    }, {
        pathname: '/admin/usermanagement/auth/authUpdate',
        component: AuthUpdate,
        title: '修改权限',
        roles: ['user_auth_update'],
    }, {
        pathname: '/admin/usermanagement/department/postAuthUpdate',
        component: PostAuthUpdate,
        title: '岗位权限调整',
        roles: ['user_post_author'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/reuseuser',
        component: UserReuse,
        title: '回用件拆解人员',
        roles: ['user_user_query'],
        exact: true
    },  {
        pathname: '/admin/usermanagement/user',
        component: User,
        title: '用户管理',
        roles: ['user_user_query'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/user/userAdd',
        component: UserAdd,
        title: '新增用户',
        roles: ['user_user_add'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/user/userUpdate',
        component: UserUpdate,
        title: '修改用户',
        roles: ['user_user_update'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/reuseuser/userReuseEdit',
        component: UserReuseEdit,
        title: '修改回用件用户',
        roles: ['user_user_update'],
        exact: true
    }, {
        pathname: '/admin/usermanagement/user/userAuthor',
        component: UserAuthor,
        title: '用户授权',
        roles: ['user_user_author'],
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/channelOverview',
        component: ChannelOverview,
        title: '渠道概览',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/channelmanagement',
        component: ChannelManagement,
        title: '渠道管理',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/channelmanagement/enterpriseAdd',
        component: EnterpriseAdd,
        title: '新增/编辑企业',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/channelmanagement/channelList',
        component: Channel,
        title: '渠道列表',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/channelmanagement/channelList/channelAdd',
        component: ChannelAdd,
        title: '新增/编辑渠道',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/business',
        component: MyBusiness,
        title: '我的企业',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/channelManage',
        component: ChannelManage,
        title: '渠道管理',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/channelManage/channeladd',
        component: ChannelAdd,
        title: '新增/编辑渠道',
        roles: '',
    }, {
        pathname: '/admin/scrapchannel/cashback',
        component: CashBack,
        title: '返现管理',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/cashbacks',
        component: CashBack,
        title: '返现管理',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/userConfig',
        component: BundlingErpDataPushConfig,
        title: '提交车辆账户管理',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/online',
        component: FirstCheck,
        title: '线上提交',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/store',
        component: ScrapStore,
        title: '线下提交',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/refuse',
        component: RefuseCars,
        title: '用户拒绝',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/pushlog',
        component: SecondPusLog,
        title: '提交记录',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/refuse/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/scrapchannel/cashback/detail',
        component: FailDetail,
        title: '返现失败详情',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/scrapchannel/eventmanagement',
        component: EventManagement,
        title: '活动管理',
        roles: ['scrape_active_query'],
        exact: true
    }, {
        pathname: '/admin/scrapchannel/eventmanagement/addevent',
        component: AddEvent,
        title: '创建活动',
        roles: ['scrape_active_add'],
    }, {
        pathname: '/admin/scrapchannel/eventmanagement/editevent',
        component: EditEvent,
        title: '编辑活动',
        roles: ['scrape_active_update'],
    }, {
        pathname: '/admin/scrapchannel/eventlist',
        component: EventList,
        title: '活动列表',
        roles: ['scrape_active_list_query'],
        exact: true
    }, {
        pathname: '/admin/scrapchannel/eventlist/eventdetail',
        component: EventDetail,
        title: '活动详情',
        roles: ['scrape_active_list_query'],
    },
    {
        pathname: '/admin/checkSystem/home', //一级菜单
        title: '公司概览',
        component: CheckHome,
        exact: true,
        roles: ['check_car_company_list'],
    },
    {
        pathname: '/admin/checkSystem/CarInfo',
        component: CheckCarInfoList,
        title: '报废车信息',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/centralize',
        component: CheckCarCentralize,
        title: '集中式拖运管理',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/centralizeComp',
        component: CheckCarCentralizeComp,
        title: '集中式拖运管理',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/centralizeComp/dispatch',
        component: CheckCarCentralizeCompDispatch,
        title: '派遣',
        roles: "",
        exact: true
    },

    {
        pathname: '/admin/checkSystem/centralize/publish',
        component: CheckCarInfoList,
        title: '发布',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/companyCarInfo',
        component: CompanyCarInfoList,
        title: '派遣报废车辆',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/CarInfo/carInfoAdd',
        component: CheckCarInfoAdd,
        title: '新增/编辑报废车',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/CarInfo/detail',
        component: CheckCarInfoDetail,
        title: '报废车详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/companyCarInfo/detail',
        component: CheckCarInfoDetail,
        title: '报废车详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/checkCarQuickPublish/detail',
        component: CheckCarInfoDetail,
        title: '报废车详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/companyCarInfo/checkCarOrder',
        component: CheckCarOrder,
        title: '拖运派遣',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/checkCarQuickPublish',
        component: CheckCarQuickPublish,
        title: '快速派遣',
        roles: "",
        exact: true
    },

    {
        pathname: '/admin/checkSystem/checkCarOrder',
        component: CheckCarOrder,
        title: '拖运派遣',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/capture',
        component: CheckCaptureList,
        title: '罚没车管理',
        roles: "",
        exact: true
    },
    {
        pathname: `/admin/checkSystem/capture/publish`,
        component: CheckCapturePublish,
        title: '罚没车发布',
        roles: "",
        exact: true
    },
    {
        pathname: `/admin/checkSystem/capture/detail`,
        component: CheckCaptureDetail,
        title: '罚没车详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/returnCar',
        component: ReturnCar,
        title: '退回审批',
        roles: "",
        exact: true
    }, {
        pathname: '/admin/checkSystem/returnCar/detail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/timeout',
        component: CheckTimeout,
        title: '超时预警',
        roles: ["check_overtime_car"],
        exact: true
    },
    {
        pathname: '/admin/checkSystem/timeoutCompany',
        component: CheckTimeoutComp,
        title: '超时预警',
        roles: ["check_company_overtime_car"],
        exact: true
    },
    {
        pathname: `/admin/checkSystem/timeout/:id`,
        component: CheckTimeoutDetail,
        title: '超时预警详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/company',
        component: CheckLogisticsCompanyList,
        title: '拖运公司',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/company/detail',
        component: LogisticsCompanyDetail,
        title: '拖运公司详情',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/company/companyAdd',
        component: LogisticsCompanyAdd,
        title: '新增/编辑拖运公司',
        exact: true,
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/trailer',
        component: CheckLogisticsTrailerList,
        title: '货车信息',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/trailer/trailerAdd',
        component: LogisticsTrailerAdd,
        title: '新增/编辑货车信息',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/trailer/trailerActualDetail',
        component: LogisticsTrailerActualDetail,
        title: '实时监控',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/driver',
        component: CheckLogisticsDriverList,
        title: '司机信息',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/driver/driverAdd',
        component: LogisticsDriverAdd,
        title: '新增/编辑司机信息',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/nvr',
        component: CheckLogisticsNvrList,
        title: 'NVR设备管理',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/sim',
        component: CheckLogisticsSimList,
        title: 'SIM卡信息',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/nvr/nvrAdd',
        component: LogisticsNvrAdd,
        title: '新增/编辑设备信息',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/allOrder',
        component: CheckOrderList,
        title: '运单列表',
        roles: ['check_order'], // 平台运营
        exact: true
    },
    {
        pathname: '/admin/checkSystem/allOrderForce',
        component: OrderForceList,
        title: '运单强制结束',
        roles: ['check_order_force_finish'], // 平台运营
        exact: true
    },
    {
        pathname: '/admin/checkSystem/allOrder/monitoring',
        component: LogisticsTrailerActualDetail,
        title: '实时监控',
        roles: '', // 平台运营
        exact: true
    },
    {
        pathname: '/admin/checkSystem/allOrder/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        exact: true
    },
    {
        pathname: '/admin/finance/deduct/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        exact: true
    },
    
    {
        pathname: '/admin/checkSystem/exceptional',
        component: CheckSystemExceptional,
        title: '问题车辆',
        roles: ['check_exception_car', 'check_company_exception_car'], // 平台运营
        exact: true
    },
    {
        pathname: '/admin/checkSystem/approval',
        component: CheckSystemApproval,
        title: '异常审批',
        roles: ['check_car_list'], // 平台运营
        exact: true
    },
    {
        pathname: '/admin/checkSystem/deadhead',
        component: CheckSystemDeadhead,
        title: '放空审批',
        roles: ['check_car_list'], // 平台运营
        exact: true
    },
    {
        pathname: '/admin/checkSystem/deadheadCompany',
        component: CheckSystemDeadheadCompany,
        title: '增补审批',
        roles: ['check_company_exception_car'], // 平台运营
        exact: true
    },

    {
        pathname: '/admin/checkSystem/loadFail',
        component: LoadFail,
        title: '装车日志',
        // roles: ['check_order_system_exception','check_order_company_exception'], // 平台运营
        roles: '',
        exact: true
    },
    {
        pathname: '/admin/checkSystem/handOver',
        component: CheckSystemHandOver,
        title: '转出拖运',
        roles: ['check_company_order'], // 平台运营
        exact: true
    },
    {
        pathname: '/admin/checkSystem/handOver/checkCarOrder',
        component: CheckSystemHandOverPublish,
        title: '转出派遣',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/approval/detail',
        component: CheckCarInfoDetail,
        title: '异常车辆详情',
        roles: ['check_car_list'], // 平台运营
        exact: true
    },
    // {
    //     pathname: '/admin/checkSystem/exceptional',
    //     component: CheckSystemExceptional ,
    //     title: '问题车辆',
    //     roles: ['check_car_company_list'], // 公司运营
    //     exact: true
    // },
    {
        pathname: '/admin/checkSystem/exceptional/detail',
        component: CheckCarInfoDetail,
        title: '问题车辆详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/deadhead/detail',
        component: CheckCarInfoDetail,
        title: '放空车辆详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/deadheadCompany/detail',
        component: CheckCarInfoDetail,
        title: '增补车辆详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/allOrder/videoDetail',
        component: VideoDetail,
        title: '视频详情',
        roles: '',
        exact: true
    }, {
        pathname: '/admin/checkSystem/allOrder/detail',
        component: CheckOrderDetail,
        title: '运单详情',
        roles: "",
        exact: true
    }, {
        pathname: '/admin/checkSystem/allOrder/lackInfo',
        component: CarLackDetail,
        title: '缺件详情',
        roles: "",
        exact: true
    }, {
        pathname: '/admin/checkSystem/carriageStandard/lackInfo',
        component: CarLackDetail,
        title: '缺件详情',
        roles: "",
        exact: true
    }, {
        pathname: '/admin/checkSystem/allOrder/detail/recommend',
        component: RecommendRoute,
        title: '同路推荐',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/task',
        component: CheckTaskList,
        title: '行程轨迹',
        roles: "",
        exact: true
    },

    {
        pathname: '/admin/checkSystem/hangUp',
        component: CheckHangUp,
        title: '挂起审批',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/hangUp/orderDetail',
        component: CheckOrderDetail,
        title: '运单详情',
        roles: "",
        exact: true
    },


    {
        pathname: '/admin/checkSystem/task/detail',
        component: CheckTaskDetail,
        title: '行程详情',
        roles: ""
    }, {
        pathname: '/admin/checkSystem/task/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        roles: ""
    }, {
        pathname: '/admin/checkSystem/task/path',
        component: CheckTaskPath,
        title: '行程轨迹',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/scheduleStatistics',
        component: CheckTaskStatistics,
        title: '行程统计',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/scheduleDifferenceStatistics',
        component: CheckTaskDiffence,
        title: '路程差值统计',
        params: { id: '11' },
        role: '',
        exact: true,
    },
    {
        pathname: '/admin/checkSystem/scheduleDifferenceStatistics/detail',
        component: CheckTaskDetail,
        title: '行程详情',
        params: { id: '11' },
        role: '',
    },
    {
        pathname: '/admin/checkSystem/scheduleDifferenceStatistics/path',
        component: CheckTaskPath,
        title: '行程轨迹',
        params: { id: '11' },
        role: '',
    },
    {
        pathname: '/admin/checkSystem/nvrVideoBack',
        component: EventDetail,
        title: '监控视频备份',
        roles: "",
    }, {
        pathname: '/admin/checkSystem/carriageIndex',
        component: FreightIndex,
        title: '运费管理',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageIndex/approvalDetail',
        component: ApprovalDetail,
        title: '审批详情',
        roles: "",
    }, {
        pathname: '/admin/checkSystem/carriageIndex/orderDetail',
        component: OrderDetail,
        title: '运单详情',
        roles: "",
    }, {
        pathname: '/admin/checkSystem/carriageList/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        roles: "",
        exact: true

    }, {
        pathname: '/admin/checkSystem/carriageStandard',
        component: FreightList,
        title: '运费信息',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageList',
        component: SettlementFreightList,
        title: '结算车辆',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageDeduct',
        component: SettlementDeduct,
        title: '扣款车辆',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageDeduct/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageException',
        component: SettlementException,
        title: '异常车辆',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageException/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageSerial',
        component: SettlementSerial,
        title: '生成流水',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageSerial/carDetail',
        component: CheckCarInfoDetail,
        title: '车辆详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageSerialApprove',
        component: SettlementSerialApproval,
        title: '发起流水审批',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageStandard/confirmSettle',
        component: ConfirmSettle,
        title: '运费确认',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/carriageStandard/freightDetail',
        component: FreightDetail,
        title: '运费详情',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/carriageDetail',
        component: SettlementFreightDetail,
        title: '运费结算详情',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/carriageQueryList',
        component: SettlementQueryList,
        title: '结算流水',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageQueryList/orderRemove',
        component: OrderRemove,
        title: '修改运单',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageQueryList/settlementDetails',
        component: SettlementDetails,
        title: '结算明细',
        roles: "",
        exact: true
    }, {
        pathname: '/admin/checkSystem/operationLogList',
        component: OperationLogList,
        title: '操作日志',
        roles: '',
    },
    {
        pathname: '/admin/checkSystem/carriageQueryList/settleCarModalDetails',
        component: SettleCarModalDetails,
        title: '结算车型详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/carriageQueryList/settleOrderDetails',
        component: SettleOrderDetails,
        title: '结算运单详情',
        roles: "",
        exact: true
    },
    {
        pathname: '/admin/checkSystem/checkSystemConfig',
        component: CheckConfig,
        title: '拖运信息配置',
        roles: "",
    }, {
        pathname: '/admin/checkSystem/checkAddress',
        component: SystemAddress,
        title: '拖运终点列表',
        roles: "",
        exact: true
    }, {
        pathname: '/admin/checkSystem/checkAddress/addAddress',
        component: AddAddress,
        title: '拖运终点列表',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/carAreaSetting',
        component: CarAreaSetting,
        title: '车辆区域配置',
        roles: "",
    },
    {
        pathname: '/admin/checkSystem/dataIndicators',
        component: DataLook,
        title: '公司绩效',
        roles: '',
        exact: true
    }, 
    {
        pathname: '/admin/checkSystem/dataIndicators/dataLook',
        component: DataLook,
        title: '数据查看',
        roles: '',
    }, {
        pathname: '/admin/checkSystem/dataIndicators/deliveryRate',
        component: DeliveryRate,
        title: '准点送达率',
        roles: '',
    }, {
        pathname: '/admin/checkSystem/dataIndicators/passRate',
        component: PassRate,
        title: '审核通过率',
        roles: '',
    }, {
        pathname: '/admin/checkSystem/dataIndicators/loadFactorRate',
        component: LoadFactorRate,
        title: '货车满载率',
        roles: '',
    }, {
        pathname: '/admin/checkSystem/distributeAddress',
        component: DistributeAddress,
        title: '集散地管理',
        roles: "",
    },
    // {
    //     pathname: '/admin/scrapchannel/payList',
    //     component: PayCheckList,
    //     title: '支付审核',
    //     roles: "",
    // }
    {
        pathname: '/admin/message/list',
        component: MessageList,
        title: '消息中心',
        // roles: ['message_news_center'],
        roles: '',
    },
    {
        pathname: '/admin/message/template',
        component: MessageTemplate,
        title: '模板消息',
        roles: '',
    },
    {
        pathname: '/admin/message/types',
        component: MessageTypes,
        title: '消息类型',
        // roles: ['message_news'],
        roles: '',
    },


    {
        pathname: '/admin/website/banner',
        component: BannerIndex,
        title: 'banner管理',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/banner/edit',
        component: BannerAdd,
        title: '编辑banner',
        roles: '',
        exact: true,
    },

    {
        pathname: '/admin/website/service',
        component: ServiceIndex,
        title: '服务点滴',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/service/edit',
        component: ServiceAdd,
        title: '编辑服务点滴',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/fansFeedback',
        component: FansFeedbackIndex,
        title: '车友故事',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/fansFeedback/add',
        component: FansFeedbackAdd,
        title: '新增/编辑车友故事',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/news',
        component: NewsIndex,
        title: '新闻资讯',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/news/add',
        component: NewsAdd,
        title: '新增/编辑新闻资讯',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/online/dipose',
        component: Dipose,
        title: '用户留言处理',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/certification',
        component: CertificationIndex,
        title: '荣誉资质',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/certification/add',
        component: CertificationAdd,
        title: '新增/编辑荣誉资质',
        roles: '',
        exact: true,
    },

    {
        pathname: '/admin/website/recycledPartsList', //二级菜单
        title: '回用件列表',
        component: RecycledpartsList,
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/recycledPartsType', //二级菜单
        title: '回用件类别',
        component: RecycledpartsType,
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/recycledPartsList/add',
        component: RecycledpartsAdd,
        title: '新增/编辑回用件',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/professionalTeam',
        title: '专业团队',
        component: ProfessionalTeam,
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/companyProfile',
        title: '公司简介',
        component: CompanyProfile,
        roles: '',
    },
    {
        pathname: '/admin/website/professionalTeam/add',
        component: CompanyProfileAdd,
        title: '新增/编辑专业团队',
        roles: '',
        exact: true,
    },
    {
        pathname: '/admin/website/appointmentCar',
        title: '预约申报',
        component: AppointmentCar,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/website/onlineMessage',
        title: '在线留言',
        component: OnlineMessage,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/recycle/onlineMessage',
        title: '在线留言',
        component: OnlineMessage,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/recycle/appointment',
        title: '管理预约报废',
        component: RecycleAppointment,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/recycle/appointment/detail',
        title: '车辆详情',
        component: DispatchDetail,
        roles: ""
    },
    {
        pathname: '/admin/recycle/dispatch/detail',
        title: '车辆详情',
        component: AppliedDetail,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/recycle/dispatch',
        title: '待派遣',
        component: DispatchList,
        roles: ""
    },
    {
        pathname: '/admin/recycle/applied',
        title: '管理申请报废',
        component: RecycleApplied,
        roles: "",
        exact: true,
    }, {
        pathname: '/admin/recycle/applied/checkCarMsg',
        title: '初审核查',
        component: CheckCarMsg,
        roles: "",
        exact: true,
    }, {
        pathname: '/admin/recycle/applied/determinationMoney',
        title: '残值款认定',
        component: DeterminationMoney,
        roles: "",
        exact: true,
    }, {
        pathname: '/admin/recycle/applied/detail',
        title: '车辆详情',
        component: AppliedDetail,
        roles: "",
        exact: true,
    }, {
        pathname: '/admin/recycle/applied/detail/edit',
        title: '编辑车辆信息',
        component: EditCarMsg,
        roles: ""
    }, {
        pathname: '/admin/recycle/applied/detail/oemDetail',
        title: '零件查验详情',
        component: OemDetail,
        roles: ""
    }, {
        pathname: '/admin/recycle/applied/detail/checkDetail',
        title: '核查详情',
        component: CheckCarDetail,
        roles: ""
    },
    {
        pathname: '/admin/recycle/accepted',
        title: '受理报废车辆',
        component: RecycleAccepted,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/recycle/firstCheck',
        title: '渠道车辆',
        component: FirstCheck,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/recycle/accepted/detail',
        title: '车辆详情',
        component: AppliedDetail,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/recycle/balance',
        title: '结算残值款',
        component: RecycleBalance,
        roles: "",
        exact: true,
    },
    {
        pathname: '/admin/noauth',
        component: NoAuth,
        title: '无权限',
        // roles: ['message_news_category'],
        roles: '',
    },
    {
        pathname: '/admin/admission/weight/unweighted/detail', //二级菜单
        title: '车辆详情', // 平台运营
        roles: "",
        component: carDetail,
    },
    {
        pathname: '/admin/admission/weight/unweighted/weightDetail', //二级菜单
        title: '称重详情', // 平台运营
        roles: "",
        component: weightDetail,
    },
    {
        pathname: '/admin/admission/weight/unweighted/weightDetail/detail', //二级菜单
        title: '车辆详情', // 平台运营
        roles: "",
        component: carDetail,
    },

    {
        pathname: '/admin/admission/weight/record/detail', //二级菜单
        title: '车辆详情', // 平台运营
        roles: "",
        component: carDetail,
    },
    {
        pathname: '/admin/admission/weight/unweighted', //二级菜单
        title: '未称重', // 平台运营
        roles: "",
        component: unweighted,
    },

    {
        pathname: 'admin/admission/weight/record/weightDetail', //一级菜单
        title: '称重详情',
        roles: '',
        component: weightDetail,
    },
    {
        pathname: '/admin/admission/weight/record/weightDetail/detail', //一级菜单
        title: '车辆详情',
        roles: '',
        component: carDetail,
    },
    {
        pathname: '/admin/admission/weight/record', //二级菜单
        title: '称重记录', // 平台运营
        roles: "",
        component: weightRecord,
    },
    {
        pathname: '/admin/admission/weight', //二级菜单
        title: '称重管理', // 平台运营
        roles: "",
        component: unweighted,
    },
    {
        pathname: '/admin/admission/certificates', //一级菜单
        title: '证件查验',
        roles: '',
        component: certificatesCheck,
        exact: true
    },
    {
        pathname: '/admin/admission/certificates/unchek', //一级菜单
        title: '未查验',
        roles: '',
        component: certificatesCheckPeding,
        exact: true
    },
    {
        pathname: '/admin/admission/certificates/unEntry', //一级菜单
        title: '未入场',
        component: certificatesCheckAccept,
        exact: true
    },
    {
        pathname: '/admin/admission/certificates/entry', //二级菜单
        title: '已入场', // 平台运营
        component: certificatesCheckConfirmed,
    },

    {
        pathname: '/admin/admission/certificates/detail', //一级菜单
        title: '车辆详情',
        roles: '',
        component: carDetail,
    },
    {
        pathname: '/admin/admission/certificates/check', //一级菜单
        title: '查验',
        roles: '',
        component: check,
    },
    {
        pathname: '/admin/admission/recovery/uncheck', //二级菜单
        title: '未查验', // 平台运营
        roles: [''],
        component: recoveryCarPadding,
        exact: true
    },
    {
        pathname: '/admin/admission/recovery/check', //二级菜单
        title: '已查验', // 平台运营
        roles: [''],
        component: recoveryCar,
        exact: true
    },
    {
        pathname: '/admin/admission/recovery', //二级菜单
        title: '车辆查验', // 平台运营
        roles: [''],
        component: recoveryCar,
        exact: true
    },
    {
        pathname: '/admin/admission/problem', //二级菜单
        title: '问题车辆', // 平台运营
        roles: [''],
        component: carProblem,
        exact: true
    },
    {
        pathname: '/admin/admission/problem/undisposed', //二级菜单
        title: '未处理', // 平台运营
        roles: [''],
        component: carProblemPending,
        exact: true
    },
    {
        pathname: '/admin/admission/problem/disposed', //二级菜单
        title: '已处理', // 平台运营
        roles: [''],
        component: carProblemAccept,
        exact: true
    },
    {
        pathname: '/admin/admission/problem/detail', //二级菜单
        title: '车辆详情', // 平台运营
        roles: [''],
        component: carDetail,
    },
    {
        pathname: '/admin/admission/problem/checkDetails', //二级菜单
        title: '查验详情', // 平台运营
        roles: [''],
        component: checkCarDetails,
    },
    {
        pathname: '/admin/admission/recovery/carDetail', //一级菜单
        title: '车辆详情',
        roles: '',
        component: carDetail,
    }, {
        pathname: '/admin/admission/problem/carDetail', //一级菜单
        title: '车辆详情',
        roles: '',
        component: carDetail,
    }, {
        pathname: '/admin/admission/refuse/carDetail', //一级菜单
        title: '车辆详情',
        roles: '',
        component: carDetail,
    }, {
        pathname: '/admin/admission/certificates/carDetail', //一级菜单
        title: '车辆详情',
        roles: '',
        component: carDetail,
    },
    {
        pathname: '/admin/admission/responsibility', //一级菜单
        title: '认定缺件责任',
        roles: '',
        component: Responsibility,
        exact: true
    }, {
        pathname: '/admin/admission/responsibility/carDetail', //一级菜单
        title: '车辆详情',
        roles: '',
        component: carDetail,
    }, {
        pathname: '/admin/admission/responsibility/checkDetail', //一级菜单
        title: '查验详情',
        roles: '',
        component: checkCarDetails,
    },
    {
        pathname: '/admin/admission/responsibility/comfirm', //一级菜单
        title: '缺件责任认定',
        roles: '',
        exact: true,
        component: confirmResponsibility
    },
    {
        pathname: '/admin/admission/deduction/unIdentified', //一级菜单
        title: '待认定',
        roles: '',
        component: ResponsibilityPadding
    },
    {
        pathname: '/admin/admission/deduction/identified', //一级菜单
        title: '已认定',
        roles: '',
        component: ResponsibilityAccept
    },
    {
        pathname: '/admin/admission/responsibility/comfirmDetail', //一级菜单
        title: '缺件责任认定详情',
        roles: '',
        component: confirmResponsibility
    },
    {
        pathname: '/admin/admission/deduction/reportform', //一级菜单
        title: '扣款报表',
        roles: '',
        component: Reportform,
        exact: true
    },
{
        pathname: '/admin/admission/deduction', //一级菜单
        title: '缺件扣款',
        roles: '',
        component: ResponsibilityPadding
    }, {
        pathname: "/admin/checkSystem/quickEit",
        title: '快捷下发',
        exact: true,
        roles: '',
        component: QuickEmit

    },
    {
        pathname: "/admin/finance/residual/detail",
        title: "详情",
        exact: true,
        roles: "",
        component: CheckCarInfoDetail
    },
    {
        pathname: "/admin/finance/entrance/detail",
        title: "详情",
        exact: true,
        roles: "",
        component: CheckCarInfoDetail
    },
    {
        pathname: "/admin/finance/pay/detail",
        title: "详情",
        exact: true,
        roles: "",
        component: CheckCarInfoDetail
    }, {
        pathname: "/admin/finance/pay/print",
        title: "打印",
        roles: "",
        component: PrintTable
    },
    ...FinanceRoutes,
    ...CommonModuleRoutes,
    ...ReuseRoutes,
    ...OperationalRoutes
];