/**
  * @description: 拖运监管
  * @author: 漆红 2020-10-10
  */
  
 import Loadable from 'react-loadable'
 import { Loading } from 'components'
 
 const MessageList = Loadable({
     loader: () => import('./list/index'),
     loading: Loading
 })
 
 const MessageTemplate = Loadable({
     loader: () => import('./template/index'),
     loading: Loading
 })
 
 const MessageTypes = Loadable({
     loader: () => import('./types/index'),
     loading: Loading
 })

 
 export {
    MessageList,
    MessageTemplate,
    MessageTypes,
 }