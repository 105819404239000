/**
  * @description: 登录模块
  * @author: 漆红 2020-10-10
  */
import ajax from './index'

// 登录
export const loginRequests = (userInfo) => {
    return ajax.post('/login/webLogin',userInfo)
}

// 获取用户信息
export const getCurrentUserInfo = (userInfo) => {
    return ajax.get('/user/getCurrentUserInfo',userInfo)
}

// 获取生成二维码需要的信息
export const getQRMsg = () => {
    return ajax.get('login/getQRCodeUniqueInfo')
}

// 二维码登录轮询查看是否扫码登录
export const getLoginMsg = (scanInfoId) => {
    return ajax.get(`login/getQRCodeStatus/${scanInfoId}`)
}