/**
 * @description: 不需要权限的路由
 * @author: 漆红 2020-10-10
 */

import { Login, NotFound } from "views";

import { carDetail, weightDetail, PrintQr } from "views/WeighingPlatform";
import { PrintTable,PrintPayWater,UkeyManage } from "views/CommonPage";

import {
ReusePrintQr,
StorePrintQr
} from 'views/Reuse'
export const mainRoutes = [
  {
    pathname: "/login",
    component: Login,
  },
  {
    pathname: "/404",
    component: NotFound,
  },
  {
    pathname: "/carDetail",
    component: carDetail,
  },
  {
    pathname: "/weightDetail/detail",
    component: carDetail,
  },
  {
    pathname: "/weightDetail",
    component: weightDetail,
  },
  {
    pathname: "/printQr",
    component: PrintQr,
  },
  {
    pathname: '/reusePrintQr', //一级菜单
    title: '回用件二维码打印',
    component: ReusePrintQr,
  },
  {
    pathname: '/storePrintQr', //一级菜单
    title: '库为二维码打印',
    component: StorePrintQr,
  },
  {
    pathname: "/printTable",
    component: PrintTable,
  },
  {
      pathname: "/printPayWater",
      component: PrintPayWater,
  },
  {
      pathname: "/ukeyManage",
      component: UkeyManage,
  },

];

