
import Loadable from 'react-loadable'
import { Loading } from 'components'





const carDetail = Loadable({
    loader: () => import('./carDetail'),
    loading: Loading
})

const weightDetail = Loadable({
    loader: () => import('./WeighingRecord/weightDetail'),
    loading: Loading
})

const PrintQr = Loadable({
    loader: () => import('./PrintQr/index'),
    loading: Loading
})

export {
    carDetail,
    weightDetail,
    PrintQr
}