import { observable, action, makeObservable } from "mobx"
import { getCashBackCheckCar } from 'requests/channel'
import { hasAuth } from 'common'

class cashBack {
    constructor() {
        makeObservable(this)
    }
    // 待查验
    @observable toBeList = []
    @observable toBePageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable toBeCondition = {
        applyTime: "",
        channelName: "",
        channelType: null,
        companyName: "",
        principal: "",
        role: hasAuth('scrape_channel_cash_back_system_manager') ? 0 : 1,
        pageType: 0,
    }

    // 待返现
    @observable tobeConfirmList = []
    @observable tobeConfirmPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable tobeConfirmCondition = {
        applyTime: "",
        channelName: "",
        channelType: null,
        companyName: "",
        principal: "",
        role: hasAuth('scrape_channel_cash_back_system_manager') ? 0 : 1,
        pageType: 1,
    }

    // 已返现
    @observable confirmedList = []
    @observable confirmedPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable confirmedCondition = {
        applyTime: "",
        channelName: "",
        channelType: null,
        companyName: "",
        principal: "",
        role: hasAuth('scrape_channel_cash_back_system_manager') ? 0 : 1,
        pageType: 2,
    }

    // 返现失败
    @observable rejectedList = []
    @observable rejectedPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable rejectedCondition = {
        applyTime: "",
        channelName: "",
        channelType: null,
        companyName: "",
        principal: "",
        role: hasAuth('scrape_channel_cash_back_system_manager') ? 0 : 1,
        pageType: 3,
    }

    // 待查验
    @action getTobeVerifiedList(pageModal, condition) {
        console.log("待查验---")
        const { page, pageSize } = pageModal || this.toBePageModal

        getCashBackCheckCar({
            pageNum: page,
            pageSize,
            condition: condition || this.toBeCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.toBeList = resp.data ? resp.data : [];
                this.toBePageModal = resp.pageModal
            }
        })
    }

    // 待返现
    @action getTobeReturnedList(pageModal, condition) {
        console.log("待返现---")

        const { page, pageSize } = pageModal || this.tobeConfirmPageModal

        getCashBackCheckCar({
            pageNum: page,
            pageSize,
            condition: condition || this.tobeConfirmCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.tobeConfirmList = resp.data ? resp.data : [];
                this.tobeConfirmPageModal = resp.pageModal
            }
        })
    }

    // 已返现
    @action getReturnedList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.confirmedPageModal

        getCashBackCheckCar({
            pageNum: page,
            pageSize,
            condition: condition || this.confirmedCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.confirmedList = resp.data ? resp.data : [];
                this.confirmedPageModal = resp.pageModal
            }
        })
    }

    // 返现失败
    @action getReturnFailureList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.rejectedPageModal

        getCashBackCheckCar({
            pageNum: page,
            pageSize,
            condition: condition || this.rejectedCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.rejectedList = resp.data ? resp.data : [];
                this.rejectedPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.toBeCondition = {
                ...condition,
                pageType: 0
            }
            this.toBePageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.tobeConfirmCondition = {
                ...condition,
                pageType: 1
            }
            this.tobeConfirmPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 3) {
            this.confirmedCondition = {
                ...condition,
                pageType: 2
            }
            this.confirmedPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 4) {
            this.rejectedCondition = {
                ...condition,
                pageType: 3
            }
            this.rejectedPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default cashBack