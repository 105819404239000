/**
 * @description: 称重平台
 * @author: 漆红 2021-3-09
 */

import ajax from './index'

// 车辆入库分页列表
export const getInspection = (params) => {
    return ajax.post(`/v1/client/car/getInspectionStorageBucklesCarListByCondition/${params.pageSize}/${params.pageNum}`, params.condition)
}

// 车辆入库 - 扣杂
export const storageSaveBuckles = (params) => {
    return ajax.post(`/v1/client/car/inspectionStorageSaveBuckles`, params)
}

// 车辆入库 - 返件
export const saveRebates = (params) => {
    return ajax.post(`/v1/client/car/inspectionStorageSaveRebates`, params)
}

// 车辆入库 - 入库
export const saveWarehouse = (params) => {
    return ajax.post(`/v1/client/car/inspectionStorageSaveWarehouse`, params)
}

// 快速称重 - 查询条件
export const getClientCarOrOrder = (params) => {
    return ajax.post(`/v1/client/car/getInspectionClientCarOrOrder`, params)
}

// 快速称重 - 保存称重重量
export const saveWeight = (params) => {
    return ajax.post(`/v1/client/car/saveInspectionClientSaveWeight`, params)
}

// 称重记录-上门拖运-获取称重详情
export const gethistoryDetailById = (id) => {
    return ajax.get(`/v1/client/car/getConsignInspectCheckHistoryDetailById/${id}`)
}

// 称重记录-上门拖运-查询列表
export const getConsignInspectList = (params) => {
    return ajax.post(`/v1/client/car/getConsignInspectCheckHistoryPageList`, params)
}

// 称重记录-自行回厂-查询列表
export const getOwnerInspectList = (params) => {
    return ajax.post(`/v1/client/car/getOwnerInspectCheckHistoryPageList`, params)
}