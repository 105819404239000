import { observable, action,makeObservable } from "mobx"
import { getInspection } from 'requests/weighingPlatform'
import { getCertificateCarListPage } from 'requests/entranceInspection'

class weighingRecord {
    constructor() {
        makeObservable(this)
    }
    // 待查验
    @observable pendingList = []
    @observable pendingPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable pendingCondition = {
        "carBrandName": "",
		"certificateCheck": 0,
		"handlerType": null,
		"licensePlate": ""
    }

    // 已查验
    @observable acceptList = []
    @observable acceptPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable acceptCondition = {
        "carBrandName": "",
		"certificateCheck": 1,
        "wareHouse":0,
		"handlerType": null,
		"licensePlate": ""
    }

    // 已入库
    @observable confirmedList = []
    @observable confirmedPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable confirmedCondition = {
        "boutiqueCar": null,
        "carBrand": "",
        "licensePlate": "",
        "inStorageTime": "",
        "warehouseId": "",
        "type": 3,
    }

    // 待查验
    @action getPendingList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.pendingPageModal

        getCertificateCarListPage({
            pageNum: page,
            pageSize,
            condition: condition || this.pendingCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.pendingList = resp.data ? resp.data : [];
                this.pendingPageModal = resp.pageModal
            }
        })
    }

    // 已查验
    @action getAcceptList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.acceptPageModal

        getCertificateCarListPage({
            pageNum: page,
            pageSize,
            condition: condition || this.acceptCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.acceptList = resp.data ? resp.data : [];
                this.acceptPageModal = resp.pageModal
            }
        })
    }

    // 已入库
    @action getConfirmedList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.confirmedPageModal

        getInspection({
            pageNum: page,
            pageSize,
            condition: condition || this.confirmedCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.confirmedList = resp.data ? resp.data : [];
                this.confirmedPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.pendingCondition = {
                ...condition,
                certificateCheck: 0
            }
            this.pendingPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.acceptCondition = {
                ...condition,
                certificateCheck: 1
            }
            this.acceptPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 3) {
            this.confirmedCondition = {
                ...condition,
                type: 3
            }
            this.confirmedPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default weighingRecord