import axios from "axios";
import { message } from "antd";
import store from "store";
import { formatResponse, formateReqData } from "../utils/utils";
import baseUrl from "common/config";

const request = axios.create({
  baseURL: baseUrl,
});

request.interceptors.request.use(
  (config) => {
    config.headers.token = sessionStorage.getItem("authToken") || "";
    config.data=formateReqData(config.data);
    store.loginStore.toggelIsLoading();
    config.timeout = 30000;
    return config;
  },
  (error) => {
    console.log("error----------------", error);
    store.loginStore.toggelIsLoading();
    message.error("网络请求失败");
  }
);

request.interceptors.response.use(
  (resp) => {
    store.loginStore.toggelIsLoading();
    if (resp.data.code === "00000") {
      resp = formatResponse(resp);
      let resData = resp.data;
      return resData;
    } else if (resp.data.code === "10003") {
      // token失效
      store.loginStore.loginOut();
      return false;
    } else {
      message.error(resp.data.msg);
      return resp.data;
    }
  },
  (error) => {
    if(error&&error.response){
        if (error.response.status == 504 || error.response.status == 404) {
          message.error("接口404报错");
        } else if (error.response.status == 403) {
          message.error("权限不足,请联系管理员!");
        } else if (error.response.status == 500) {
          message.error(`服务器内部错误${error.response.status}`);
        } else {
          message.error(`请求服务器错误${error.response.status}`);
        }
    }
    else{
        message.error("网络请求失败,请刷新重试");
    }
    console.log("error----------------", error);
    store.loginStore.toggelIsLoading();
    return { code: "-1" };
  }
);

export default request;
