import { observable, action, makeObservable } from "mobx"
import { carPage } from 'requests/recycle'

class carDispatch {
    constructor() {
        makeObservable(this)
    }
    // 待派遣
    @observable toBeList = []
    @observable toBePageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable toBeCondition = {
        applyTime: '',
        ownerType: null,
        handleType: null,
        name: null,
        type: 1,
        backType: null,
        boutiqueCar: null,
        carBrand: null,
        carStatus: null
    }

    // 待认定残值款
    @observable tobeConfirmList = []
    @observable tobeConfirmPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable tobeConfirmCondition = {
        applyTime: '',
        ownerType: null,
        handleType: null,
        name: null,
        backType: null,
        boutiqueCar: null,
        carBrand: null,
        carStatus: null,
        type: 2
    }

    // 已认定残值款
    @observable confirmedList = []
    @observable confirmedPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable confirmedCondition = {
        applyTime: '',
        ownerType: null,
        handleType: null,
        name: null,
        backType: null,
        boutiqueCar: null,
        carBrand: null,
        carStatus: null,
        type: 3,
    }

    // 拒绝回收
    @observable rejectedList = []
    @observable rejectedPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable rejectedCondition = {
        applyTime: '',
        ownerType: null,
        handleType: null,
        name: null,
        backType: null,
        boutiqueCar: null,
        carBrand: null,
        carStatus: null,
        type: 4,
    }

    // 待受理
    @observable pendingList = []
    @observable pendingPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable pendingCondition = {
        applyTime: '',
        ownerType: null,
        handleType: null,
        name: null,
        backType: null,
        boutiqueCar: null,
        carBrand: null,
        carStatus: null,
        type: 5,
    }

    // 已受理
    @observable acceptList = []
    @observable acceptPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable acceptCondition = {
        applyTime: '',
        ownerType: null,
        handleType: null,
        name: null,
        backType: 2,
        boutiqueCar: null,
        carBrand: null,
        carStatus: null,
        type: 6,
    }

    // 待派遣
    @action getToBeList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.toBePageModal

        carPage({
            pageNum: page,
            pageSize,
            condition: condition || this.toBeCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.toBeList = resp.data ? resp.data : [];
                this.toBePageModal = resp.pageModal
            }
        })
    }

    // 待认定残值款
    @action getTobeConfirmList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.tobeConfirmPageModal

        carPage({
            pageNum: page,
            pageSize,
            condition: condition || this.tobeConfirmCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.tobeConfirmList = resp.data ? resp.data : [];
                this.tobeConfirmPageModal = resp.pageModal
            }
        })
    }

    // 已认定残值款
    @action getConfirmedList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.confirmedPageModal

        carPage({
            pageNum: page,
            pageSize,
            condition: condition || this.confirmedCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.confirmedList = resp.data ? resp.data : [];
                this.confirmedPageModal = resp.pageModal
            }
        })
    }

    // 拒绝回收
    @action getRejectedList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.rejectedPageModal

        carPage({
            pageNum: page,
            pageSize,
            condition: condition || this.rejectedCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.rejectedList = resp.data ? resp.data : [];
                this.rejectedPageModal = resp.pageModal
            }
        })
    }

    // 待受理
    @action getPendingList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.pendingPageModal

        carPage({
            pageNum: page,
            pageSize,
            condition: condition || this.pendingCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.pendingList = resp.data ? resp.data : [];
                this.pendingPageModal = resp.pageModal
            }
        })
    }

    // 已受理
    @action getAcceptList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.acceptPageModal

        carPage({
            pageNum: page,
            pageSize,
            condition: condition || this.acceptCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.acceptList = resp.data ? resp.data : [];
                this.acceptPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.toBeCondition = {
                ...condition,
                type: 1
            }
            this.toBePageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.tobeConfirmCondition = {
                ...condition,
                type: 2
            }
            this.tobeConfirmPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 3) {
            this.confirmedCondition = {
                ...condition,
                type: 3
            }
            this.confirmedPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 4) {
            this.rejectedCondition = {
                ...condition,
                type: 4
            }
            this.rejectedPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 5) {
            this.pendingCondition = {
                ...condition,
                type: 5,
                backType:undefined,
            }
            this.pendingPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 6) {
            this.acceptCondition = {
                ...condition,
                type: 6
            }
            this.acceptPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default carDispatch