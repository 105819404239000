/**
  * @description: 用户管理
  * @author: 漆红 2020-10-10
  */

import Loadable from 'react-loadable'
import { Loading } from 'components'

const Org = Loadable({
    loader: () => import('./OrgManager'),
    loading: Loading
})

const OrgManagerAuthor = Loadable({
    loader: () => import('./OrgManager/OrgManagerAuthor'),
    loading: Loading
})

const PostAuthUpdate = Loadable({
    loader: () => import('./DepartmentManager/PostAuthUpdate'),
    loading: Loading
})

const UserAuthor = Loadable({
    loader: () => import('./UserManager/UserAuthor'),
    loading: Loading
})

const OrgAdd = Loadable({
    loader: () => import('./OrgManager/OrgAdd'),
    loading: Loading
})

const OrgUpdate = Loadable({
    loader: () => import('./OrgManager/OrgUpdate'),
    loading: Loading
})

const User = Loadable({
    loader: () => import('./UserManager'),
    loading: Loading
})
const UserReuse = Loadable({
    loader: () => import('./UserManager/userReuse'),
    loading: Loading
})

const UserAdd = Loadable({
    loader: () => import('./UserManager/UserAdd'),
    loading: Loading
})

const UserUpdate = Loadable({
    loader: () => import('./UserManager/UserUpdate'),
    loading: Loading
})

const Department = Loadable({
    loader: () => import('./DepartmentManager'),
    loading: Loading
})

const Auth = Loadable({
    loader: () => import('./AuthManager'),
    loading: Loading
})

const AuthAdd = Loadable({
    loader: () => import('./AuthManager/AuthAdd'),
    loading: Loading
})

const AuthUpdate = Loadable({
    loader: () => import('./AuthManager/AuthUpdate'),
    loading: Loading
})

const Role = Loadable({
    loader: () => import('./RoleManager'),
    loading: Loading
})

const RoleAdd = Loadable({
    loader: () => import('./RoleManager/RoleAdd'),
    loading: Loading
})

const RoleUpdate = Loadable({
    loader: () => import('./RoleManager/RoleUpdate'),
    loading: Loading
})

const RoleView = Loadable({
    loader: () => import('./RoleManager/RoleView'),
    loading: Loading
})


const UserReuseEdit = Loadable({
    loader: () => import('./UserManager/userReuse/edit'),
    loading: Loading
})

export {
    User,
    UserReuse,
    UserReuseEdit,
    UserAdd,
    UserUpdate,
    Auth,
    Role,
    RoleAdd,
    RoleUpdate,
    RoleView,
    Org,
    OrgAdd,
    OrgUpdate,
    OrgManagerAuthor,
    AuthAdd,
    UserAuthor,
    PostAuthUpdate,
    Department,
    AuthUpdate,
}