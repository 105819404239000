/**
 * @description: 系统配置
 * @author: 漆红 2020-11-5
 */

import Loadable from "react-loadable";
import { Loading } from "components";

const DistributionCenter = Loadable({
  loader: () => import("./DistributionCenter"),
  loading: Loading,
});

const DistributionAdd = Loadable({
  loader: () => import("./DistributionCenter/DistributionAdd"),
  loading: Loading,
});

const OemConfig = Loadable({
  loader: () => import("./Oem/Config"),
  loading: Loading,
});

const OemLack = Loadable({
  loader: () => import("./Oem/Config/lack"),
  loading: Loading,
});

const ReuseQuality = Loadable({
    loader: () => import("./ReuseQuality/index"),
    loading: Loading,
})

const OemExtra = Loadable({
  loader: () => import("./Oem/Extra"),
  loading: Loading,
});

const OemExtraDetail = Loadable({
  loader: () => import("./Oem/Extra/detail"),
  loading: Loading,
});


const StorageRoom = Loadable({
  loader: () => import("./StorageRoom"),
  loading: Loading,
});

const StorageRoomAdd = Loadable({
  loader: () => import("./StorageRoom/StorageRoomAdd"),
  loading: Loading,
});

const PriceIndex = Loadable({
  loader: () => import("./PriceControl"),
  loading: Loading,
});

const PriceAdd = Loadable({
  loader: () => import("./PriceControl/add"),
  loading: Loading,
});

const CarCategory = Loadable({
  loader: () => import("./CarCategory"),
  loading: Loading,
});

const BocdpayConfig = Loadable({
  loader: () => import("./BocdpayConfig"),
  loading: Loading,
});
const CarCategoryAdd = Loadable({
  loader: () => import("./CarCategory/add"),
  loading: Loading,
});

const CheckSubsidy = Loadable({
  loader: () => import("./CheckSubsidy"),
  loading: Loading,
});

const CheckSubsidyAdd = Loadable({
  loader: () => import("./CheckSubsidy/add"),
  loading: Loading,
});

const PayLimit = Loadable({
  loader: () => import("./PayLimit"),
  loading: Loading,
});

const RemoteFreight = Loadable({
  loader: () => import("./RemoteFreight/index"),
  loading: Loading,
});
const RemoteFreightAdd = Loadable({
  loader: () => import("./RemoteFreight/add"),
  loading: Loading,
});

const ReuseCategory = Loadable({
  loader: () => import("./ReuseCategory/index"),
  loading: Loading,
});
const ReuseCategoryAdd = Loadable({
  loader: () => import("./ReuseCategory/add"),
  loading: Loading,
});

const DistributionProof = Loadable({
  loader: () => import("./distributionProof/index"),
  loading: Loading,
});

const LogManager = Loadable({
  loader: () => import("./logManager/index"),
  loading: Loading,
});


const routes = [
  {
    pathname: "/admin/commonModule/distributionCenter",
    component: DistributionCenter,
    title: "集散地管理",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/distributionCenter/distributionAdd",
    component: DistributionAdd,
    title: "新增/编辑集散地",
  },
  {
    pathname: "/admin/commonModule/storageRoom",
    component: StorageRoom,
    title: "库房设置",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/logManager",
    component: LogManager,
    title: "日志管理",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/storageRoom/add",
    component: StorageRoomAdd,
    title: "新增/编辑库房",
  },
  {
    pathname: "/admin/commonModule/oemConfig",
    component: OemConfig,
    title: "OEM配置",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/oemConfig/oemLack",
    component: OemLack,
    title: "缺件查验配置",
    exact: true,
  },

  {
    pathname: "/admin/commonModule/oemExtra",
    component: OemExtra,
    title: "额外检测设置",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/oemExtra/detail",
    component: OemExtraDetail,
    title: "详情",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/priceControl",
    component: PriceIndex,
    title: "回收价格管理",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/priceControl/edit",
    component: PriceAdd,
    title: "添加/编辑",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/carCategory",
    component: CarCategory,
    title: "车辆类型管理",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/carCategory/edit",
    component: CarCategoryAdd,
    title: "添加/编辑",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/payLimit",
    component: PayLimit,
    title: "支付限额配置",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/bocdpayConfig",
    component: BocdpayConfig,
    title: "成都银行支付管理",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/checkSubsidy",
    component: CheckSubsidy,
    title: "查验补贴设置",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/checkSubsidy/edit",
    component: CheckSubsidyAdd,
    title: "添加/编辑",
    exact: true,
  },  
  {
    pathname: "/admin/commonModule/remoteFreight",
    component: RemoteFreight,
    title: "远距离运费管理",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/remoteFreight/edit",
    component: RemoteFreightAdd,
    title: "添加/编辑",
    exact: true,
  },  
  {
    pathname: "/admin/commonModule/reuseCategory",
    component: ReuseCategory,
    title: "回用件类型管理",
    exact: true,
  },
  {
    pathname: "/admin/commonModule/reuseQuality",
    component: ReuseQuality,
    title: "回用件等级管理",
  },
  {
    pathname: "/admin/commonModule/reuseCategory/edit",
    component: ReuseCategoryAdd,
    title: "添加/编辑",
    exact: true,
  },  
];

export default routes;
