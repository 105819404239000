import { observable, action, makeObservable } from "mobx"
import { getConsignInspectList, getOwnerInspectList } from 'requests/weighingPlatform'

class weighingRecord {
    constructor() {
        makeObservable(this)
    }
    // 上门拖运
    @observable pendingList = []
    @observable pendingPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable pendingCondition = {
        "licensePlate": "",
		"poundDateTime": ""
    }

    // 自行回厂
    @observable acceptList = []
    @observable acceptPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable acceptCondition = {
        "boutiqueCar": null,
		"carLicensePlate": "",
		"licensePlate": "",
		"poundDateTime": "",
        "carBrand": ""
    }

    // 上门拖运
    @action getPendingList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.pendingPageModal

        getConsignInspectList({
            pageNum: page,
            pageSize,
            condition: condition || this.pendingCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.pendingList = resp.data ? resp.data : [];
                this.pendingPageModal = resp.pageModal
            }
        })
    }

    // 自行回厂
    @action getAcceptList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.acceptPageModal

        getOwnerInspectList({
            pageNum: page,
            pageSize,
            condition: condition || this.acceptCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.acceptList = resp.data ? resp.data : [];
                this.acceptPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.pendingCondition = condition
            this.pendingPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.acceptCondition = condition
            this.acceptPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default weighingRecord