/**
 * @description: 菜单配置
 * @author: 漆红 2020-10-10
 */


/**
 * 渠道编码
 * scrapManage_operator -- 渠道管理
 * scrapManage_manager -- 第三方渠道管理
 */

//界面显示的菜单 一个大括号就是一个模块的菜单
export const adminRoutes = [
    // {
    //     pathname: '/admin/home', //模块
    //     title: '首页',
    //     roles: '',
    //     menus: [{
    //         pathname: '/admin/home', //一级菜单
    //         title: '首页',
    //         icon:'iconshouye',
    //         roles: '',
    //         children: [],
    //     }]
    // },
    {
        pathname: '/admin/usermanagement', //模块
        title: '用户管理',
        roles: ['user'],
        menus: [{
                pathname: '/admin/usermanagement/org', //一级菜单
                title: '合作伙伴管理',
                icon: "icon-qc-client",
                roles: ['user_org_query'],
            }, {
                pathname: '/admin/usermanagement/department', //一级菜单
                title: '部门管理',
                // icon:"icon-Appsfilledicon",
                icon: 'icon-channel-list',
                roles: ['user_department_query'],
            }, {
                pathname: '/admin/usermanagement/role', //一级菜单
                title: '角色管理',
                icon: "icon-sbcwitness",
                roles: ['user_role_query'],
            }, {
                pathname: '/admin/usermanagement/auth', //一级菜单
                title: '权限管理',
                icon: "icon-open-verify",
                roles: ['user_auth_query'],
            },
            // {
            //     pathname: '/admin/usermanagement/post', //一级菜单
            //     title: '岗位管理',
            //     icon:"iconzuzhiguanli",
            //     roles: ['user_post_query'],
            // }, 
            {
                pathname: '/admin/usermanagement/user', //一级菜单
                title: '用户管理',
                icon: "icon-user-add",
                roles: ['user_user_query'],
            },{
                pathname: '/admin/usermanagement/reuseuser', //一级菜单
                title: '回用件拆解人员',
                icon: "icon-user-add",
                roles: ['web_reuse_user_management'],
            }
        ],

    },
    {
        pathname: '/admin/scrapchannel',
        title: '报废渠道',
        icon: 'ApartmentOutlined',
        roles: ['scrape_channel'],
        menus: [{
                pathname: '/admin/scrapchannel/channelOverview',
                title: '渠道概览',
                icon: "icon-contentAudit",
                roles: ['scrape_channel_company']
            },
            {
                pathname: '/admin/scrapchannel/channelmanagement',
                title: '企业管理',
                icon: "icon-contentAudit",
                roles: ['scrape_channel_company']
            },
            {
                pathname: '/admin/scrapchannel/channel',
                title: '我的渠道',
                icon: "icon-agreement-manage",
                roles: ['scrape_channel_channel'],
                children: [{
                        pathname: '/admin/scrapchannel/business',
                        title: '我的企业',
                        roles: ['scrape_channel_channel_company_detail'],
                    },
                    {
                        pathname: '/admin/scrapchannel/channelManage',
                        title: '渠道管理',
                        roles: ['scrape_channel_channel_manager'],

                    },
                ]
            },
            {
                pathname: '/admin/scrapchannel/cashback',
                title: '渠道返现',
                icon: "icon-commission",
                roles: ['scrape_channel_cash_back']
            }, {
                pathname: '/admin/scrapchannel/cashbacks',
                title: '渠道返现',
                icon: "icon-commission",
                roles: ['scrape_channel_cash_back1']
            },
            {
                pathname: '/admin/scrapchannel/userConfig',
                title: '提交车辆账户管理',
                icon: "icon-commission",
                roles: ['scrape_channel_car']
            },
            {
                pathname: '/admin/scrapchannel/cars',
                title: '渠道车辆',
                icon: "icon-manage-suit",
                roles: ['scrape_channel_car'],
                children: [{
                    pathname: '/admin/scrapchannel/online',
                    title: '线上提交',
                    roles: ['scrape_channel_car_online'],
                },
                {
                    pathname: '/admin/scrapchannel/store',
                    title: '线下提交',
                    roles: ['scrape_channel_car_offline'],
                },
                {
                    pathname: '/admin/scrapchannel/refuse',
                    title: '用户拒绝',
                    roles: ['scrape_channel_car_refuse'],
                },
               {
                   pathname: '/admin/scrapchannel/pushlog',
                   title: '提交记录',
                   roles: ['scrape_channel_car_second_push_car'],
               },
            ]
            },
            // , {
            //     pathname: '/admin/scrapchannel/eventmanagement',
            //     title: '活动管理',
            //     icon: "icon-operation-analysis",
            //     roles: ['scrape_active_query']
            // }, {
            //     pathname: '/admin/scrapchannel/eventlist',
            //     title: '活动列表',
            //     icon: "icon-order",
            //     roles: ['scrape_active_list_query']
            // }, {
            //     pathname: '/admin/scrapchannel/reward',
            //     title: '奖励详情',
            //     icon: 'icon-ipGroupRank',
            //     roles: ['scrape_cash_detail_query']
            // }, {
            //     pathname: '/admin/scrapchannel/principalmsg',
            //     title: '负责人信息',
            //     icon: "icon-broker",
            //     roles: ['scrape_manager_info_query']
            // }
        ]
    },
    {
        pathname: '/admin/recycle',
        title: '车辆回收',
        icon: 'ApartmentOutlined',
        roles: ['recycle_manager'],
        menus: [{
                pathname: '/admin/recycle/car',
                title: '管理申请报废',
                icon: "icon-agreement-manage",
                roles: ['recycle_manager_car'],
                children: [
                    // {
                    //     pathname: '/admin/recycle/appointment',
                    //     title: '管理预约报废',
                    //     roles: ['scrape_manager_car_appointment'],
                    // },
                    {
                        pathname: '/admin/recycle/dispatch',
                        title: '待派遣',
                        roles: ['recycle_manager_dispatch'],
                    },
                    {
                        pathname: '/admin/recycle/applied',
                        title: '残值款认定',
                        roles: ['recycle_manager_apply'],
                    },
                ]
            },
            {
                pathname: '/admin/recycle/accepted',
                title: '受理报废车辆',
                icon: "icon-manage-suit",
                roles: ['recycle_manager_accepted'],
            },
            {
                pathname: '/admin/recycle/firstCheck',
                title: '渠道车辆',
                icon: "icon-manage-suit",
                roles: ['recycle_manager_firstCheck'],
            },
            // {
            //     pathname: '/admin/recycle/balance',
            //     title: '结算残值款',
            //     icon: "icon-commission",
            //     roles: ['scrape_manager_settle'],
            // },
            // {
            //     pathname: '/admin/recycle/onlineMessage',
            //     title: '在线留言',
            //     icon: "icon-config",
            //     roles: ['scrape_manager_guestbook'],
            // },
        ]
    },
    {
        pathname: '/admin/checkSystem', //模块
        title: '拖运监管',
        roles: ['check'],
        menus: [{
                pathname: '/admin/checkSystem/home', //一级菜单
                title: '公司概览',
                roles: ['check_car_company_list'],
                icon: 'icon-index-archive-manage',
            },
            {
                pathname: '/admin/checkSystem/abandonedVehicel', //一级菜单
                title: '报废车管理',
                roles: ['check_car', 'check_car_company_list'], // 平台运营
                icon: 'icon-agreement-manage',
                children: [{
                        pathname: '/admin/checkSystem/CarInfo', //一级菜单
                        title: '报废车信息',
                        roles: ['check_car_list'], // 平台运营

                    },
                    // {
                    //     pathname: '/admin/checkSystem/centralize', //一级菜单
                    //     title: '集中式拖运管理',
                    //     roles: ['check_car_centralize'], // 平台运营
                    // },
                    {
                        pathname: '/admin/checkSystem/companyCarInfo', //一级菜单
                        title: '派遣报废车辆',
                        roles: ['check_car_company_list'], // 公司运营
                    },
                    {
                        pathname: '/admin/checkSystem/checkCarQuickPublish', //一级菜单
                        title: '快速派遣',
                        roles: ['check_car_company_list', 'check_car_company_quickPublish'], // 公司运营
                    },
                    // {
                    //     pathname: '/admin/checkSystem/centralizeComp', //一级菜单
                    //     title: '集中式拖运管理',
                    //     roles: ['check_car_company_centralize'], // 公司运营
                    // },
                    // {
                    //     pathname: '/admin/checkSystem/capture', //一级菜单
                    //     title: '罚没车管理',
                    //     roles: ['check_confiscate_car'], // 平台运营
                    // },
                    {
                        pathname: '/admin/checkSystem/returnCar',
                        title: '退回审批',
                        roles: ['check_back_approva'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/quickEit',
                        title: '快捷下发',
                        roles: ['check_car_list']
                    }
                    // {
                    //     pathname: '/admin/checkSystem/timeout', //一级菜单
                    //     title: '超时预警',
                    //     roles: ['check_overtime_car'], // 平台运营

                    // },
                    // {
                    //     pathname: '/admin/checkSystem/timeoutCompany', //一级菜单
                    //     title: '超时预警',
                    //     roles: ['check_company_overtime_car'], // 公司运营
                    // },
                ]
            },
            {
                pathname: '/admin/checkSystem/order', //一级菜单
                title: '运单管理',
                roles: ['check_orders'],
                icon: 'icon-index-archive-quality',
                children: [{
                        pathname: '/admin/checkSystem/allOrder', //二级菜单
                        title: '运单列表',
                        roles: ['check_order', 'check_company_order'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/allOrderForce', //二级菜单
                        title: '运单强制结束',
                        roles: ['check_order_force_finish'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/exceptional',
                        title: '问题车辆',
                        roles: ['check_exception_car', 'check_car_company_list'], // 平台运营
                    },
                    // {
                    //     pathname: '/admin/checkSystem/approval',
                    //     title: '异常审批',
                    //     roles: ['check_exception_audit'], // 平台运营
                    // },
                    {
                        pathname: '/admin/checkSystem/deadhead',
                        title: '放空审批',
                        roles: ['check_exception_deadhead', 'check_exception_audit'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/deadheadCompany',
                        title: '增补审批',
                        roles: ['check_exception_deadhead_company', 'check_company_order'], // 平台运营
                    },

                    {
                        pathname: '/admin/checkSystem/hangUp', //二级菜单
                        title: '挂起审批',
                        roles: ["check_hangup_audit", "check_company_order"], // 公司运营
                    },
                    {
                        pathname: '/admin/checkSystem/handOver',
                        title: '转出拖运',
                        roles: ['check_company_order'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/loadFail',
                        title: '装车日志',
                        roles: ['check_order_system_exception', 'check_order_company_exception'], // 平台运营
                    },
                ]
            },
            {
                pathname: '/admin/checkSystem/schedule', //一级菜单
                title: '拖运监控',
                roles: ['check_supervise'],
                icon: 'icon-wt-statistics',
                children: [{
                        pathname: '/admin/checkSystem/task', //二级菜单
                        title: '行程轨迹',
                        roles: ['check_route'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/task', //二级菜单
                        title: '行程轨迹',
                        roles: ['check_company_route'], // 公司运营
                    },
                    {
                        pathname: '/admin/checkSystem/scheduleStatistics', //二级菜单
                        title: '行程统计',
                        roles: ['check_route_count'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/scheduleStatistics', //二级菜单
                        title: '行程统计',
                        roles: ['check_company_route_count'], // 公司运营
                    },
                    {
                        pathname: '/admin/checkSystem/scheduleDifferenceStatistics', //二级菜单
                        title: '路程差值统计',
                        roles: ['check_mileage_subtract', 'check_company_mileage_subtract'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/operationLogList', //二级菜单
                        title: '操作日志',
                        roles: ['check_order_log'],
                    },
                    // {
                    //     pathname: '/admin/checkSystem/scheduleDifferenceStatistics', //二级菜单
                    //     title: '路程差值统计',
                    //     roles: ['check_company_mileage_subtract'], // 公司运营
                    //     roles: '', // 公司运营
                    // }
                ]
            },
            // {
            //     pathname: '/admin/checkSystem/nvrVideoBack', //一级菜单
            //     title: '监控视频备份',
            //     roles: [],
            //     icon: 'ApartmentOutlined'
            // },
            {
                pathname: '/admin/checkSystem/carriage', //一级菜单
                title: '运费结算系统',
                roles: ['check_carriage'],
                icon: 'icon-small-loan',
                children: [
                    // {
                    //     pathname: '/admin/checkSystem/carriageIndex', //二级菜单
                    //     title: '运费管理', // 平台运营
                    //     roles: ['check_carriage_settle'],
                    // },

                    {
                        pathname: '/admin/checkSystem/carriageList', //二级菜单
                        title: '结算车辆', // 平台运营
                        roles: ['check_carriage_settle'],
                    },
                    {
                        pathname: '/admin/checkSystem/carriageSerial', //二级菜单
                        title: '生成流水', // 平台运营
                        roles: ['check_carriage_settle'],
                    },
                    {
                        pathname: '/admin/checkSystem/carriageSerialApprove', //二级菜单
                        title: '发起流水审批', // 公司管理员
                        roles: ['check_carriage_settle'],
                    },
                    {
                        pathname: '/admin/checkSystem/carriageException', //二级菜单
                        title: '异常车辆', // 平台运营
                        roles: ['check_carriage_settle'],
                    },
                    {
                        pathname: '/admin/checkSystem/carriageDeduct', //二级菜单
                        title: '扣款车辆', // 公司管理员
                        roles: ['check_carriage_settle'],
                    },
                    // {
                    //     pathname: '/admin/checkSystem/operationLogList', //二级菜单
                    //     title: '操作日志', 
                    //     roles: ['check_order_log'],
                    // },
                ]
            },
            {
                pathname: '/admin/checkSystem/data', //一级菜单
                title: '数据中心',
                roles: ['check_data_center'],
                icon: 'icon-orderStatistcs',
                children: [{
                    pathname: '/admin/checkSystem/dataIndicators',
                    title: '公司绩效',
                    roles: ['check_data_center_company'],
                }, ]
            },
            {
                pathname: '/admin/checkSystem/systemConfig', //一级菜单
                title: '系统配置',
                roles: ['check_config', 'check_address', 'check_collect_address'],
                icon: 'icon-para-sets',
                children: [{
                        pathname: '/admin/checkSystem/checkSystemConfig', //二级菜单
                        title: '拖运信息配置', // 平台运营
                        roles: ['check_config'],
                    },
                    {
                        pathname: '/admin/checkSystem/checkAddress', //二级菜单
                        title: '拖运终点配置', // 平台运营
                        roles: ['check_address'],
                    },
                    {
                        pathname: '/admin/checkSystem/carAreaSetting', //二级菜单
                        title: '车辆区域配置', // 平台运营
                        roles: ['check_address'],
                    },
                    // {
                    //     pathname: '/admin/checkSystem/distributeAddress', //二级菜单
                    //     title: '集散地管理', // 平台运营
                    //     roles: ['check_collect_address'],
                    // }
                ]
            },
            {
                pathname: '/admin/checkSystem/basicInfo', //一级菜单
                title: '资源管理',
                roles: ['check_basic'],
                icon: 'icon-business-search-statistics',
                children: [{
                        pathname: '/admin/checkSystem/company', //二级菜单
                        title: '拖运公司',
                        roles: ['check_company'], // 平台运营
                    },
                    {
                        pathname: '/admin/checkSystem/driver', //二级菜单
                        title: '司机信息',
                        roles: ['check_driver'], // 公司运营
                    },
                    {
                        pathname: '/admin/checkSystem/trailer', //二级菜单
                        title: '货车信息',
                        roles: ['check_trailer'], // 公司运营
                    },
                    {
                        pathname: '/admin/checkSystem/nvr', //二级菜单
                        title: 'NVR设备管理',
                        roles: ['check_nvr'], // 公司运营
                    },
                    {
                        pathname: '/admin/checkSystem/sim', //二级菜单
                        title: 'SIM卡信息',
                        roles: ['check_nvr'], // 公司运营
                    }
                ]
            }
        ],
    },
    {
        pathname: '/admin/message', //模块
        title: '模板消息',
        roles: ['message_news_center', 'message_news', 'message_news_category'],
        menus: [{
                pathname: '/admin/message/list', //一级菜单
                title: '消息中心',
                icon: 'icon-system-notice',
                roles: ['message_news_center'],
                children: [],
            },
            {
                pathname: '/admin/message/template', //一级菜单
                title: '模板消息',
                icon: 'icon-processes',
                roles: ['message_news'],
                children: [],
            },
            {
                pathname: '/admin/message/types', //一级菜单
                title: '模板消息类型',
                icon: 'icon-serviceProduct',
                roles: ['message_news_category  '],
                children: [],
            },
        ]
    },
    {
        pathname: '/admin/admission', //模块
        title: '入场查验',
        roles: ['inspection'],
        menus: [{
                pathname: '/admin/admission/certificates', //一级菜单
                title: '证件查验',
                icon: 'icon-business-operation1',
                roles: ['inspection-certificate-check'],
                children: [{
                        pathname: '/admin/admission/certificates/unchek', //二级菜单
                        title: '未查验', // 平台运营
                        roles: ['inspection-certificate-check'],
                    },
                    {
                        pathname: '/admin/admission/certificates/unEntry', //二级菜单
                        title: '未入场', // 平台运营
                        roles: ['inspection-certificate-check'],
                    },
                    {
                        pathname: '/admin/admission/certificates/entry', //二级菜单
                        title: '已入场', // 平台运营
                        roles: ['inspection-certificate-check'],
                    },
                    // {
                    //     pathname: '/admin/admission/certificates/confiscation', //二级菜单
                    //     title: '罚没车核销', // 平台运营
                    //     roles: ['inspection-certificate-check'],
                    // },
                ],
            },
            {
                pathname: '/admin/admission/weigh', //一级菜单
                title: '称重管理',
                icon: 'icon-agreement-manage',
                roles: ['inspection-weight-manage'],
                children: [{
                        pathname: '/admin/admission/weight/unweighted', //二级菜单
                        title: '未称重', // 平台运营
                        roles: ['inspection-weight-manage-not'],
                    },
                    {
                        pathname: '/admin/admission/weight/record', //二级菜单
                        title: '称重记录', // 平台运营
                        roles: ['inspection-weight-manage-record'],
                    },
                ],
            },
            {
                pathname: '/admin/admission/recovery', //一级菜单
                title: '车辆查验',
                icon: 'icon-renwuguanli',
                roles: ['inspection-car-check'],
                children: [{
                        pathname: '/admin/admission/recovery/uncheck', //二级菜单
                        title: '未查验', // 平台运营
                        roles: ['inspection-car-check'],
                    },
                    {
                        pathname: '/admin/admission/recovery/check', //二级菜单
                        title: '已查验', // 平台运营
                        roles: ['inspection-car-check'],
                    },
                ],
            },
            {
                pathname: '/admin/admission/problem', //二级菜单
                title: '问题车辆', // 平台运营
                icon: 'icon-open-recheck',
                roles: ['inspection-problem-car'],
                children: [{
                        pathname: '/admin/admission/problem/undisposed', //二级菜单
                        title: '未处理', // 平台运营
                        roles: ['inspection-problem-car'],
                    },
                    {
                        pathname: '/admin/admission/problem/disposed', //二级菜单
                        title: '已处理', // 平台运营
                        roles: ['inspection-problem-car'],
                    },
                ],
            },
            {
                pathname: '/admin/admission/deduction', //一级菜单
                title: '缺件扣款',
                icon: 'icon-user-protocol',
                roles: ['inspection-miss-deduction'],
                children: [{
                        pathname: '/admin/admission/deduction/unIdentified', //二级菜单
                        title: '待认定', // 平台运营
                        roles: ['inspection-miss-deduction-identified'],
                    },
                    {
                        pathname: '/admin/admission/deduction/identified', //二级菜单
                        title: '已认定', // 平台运营
                        roles: ['inspection-miss-deduction-identified'],
                    },
                    {
                        pathname: '/admin/admission/deduction/reportform', //二级菜单
                        title: '扣款报表', // 平台运营
                        roles: ['inspection-miss-deduction-report'],
                    },
                ],
            },
        ]
    },
    {
        pathname: '/admin/website', //模块
        title: '官网管理',
        roles: ["portal_manager"],
        menus: [{
                pathname: '/admin/website/banner', //一级菜单
                title: 'banner管理',
                icon: 'icon-rule-management',
                roles: ["portal_manager_banner"],
                children: [],
            },
            {
                pathname: '/admin/website/service', //一级菜单
                title: '服务点滴',
                icon: 'icon-qc-report',
                roles: ["portal_manager_service"],
                children: [],
            },
            {
                pathname: '/admin/website/fansFeedback', //一级菜单
                title: '车友故事',
                icon: 'icon-business-verify',
                roles: ["portal_manager_story"],
                children: [],
            },
            {
                pathname: '/admin/website/news', //一级菜单
                title: '新闻资讯',
                icon: 'icon-business-operation',
                roles: ["portal_manager_news"],
                children: [],
            },
            // {
            //     pathname: '/admin/website/recycledparts', //一级菜单
            //     title: '回用件管理',
            //     icon:'icon-marketing-channels',
            //     roles: "",
            //     children: [
            //         {
            //             pathname: '/admin/website/recycledPartsList', //二级菜单
            //             title: '回用件列表', // 平台运营
            //             roles: '',
            //         },
            //         {
            //             pathname: '/admin/website/recycledPartsType', //二级菜单
            //             title: '回用件类别', // 平台运营
            //             roles: '',
            //         },
            //     ],
            // },
            {
                pathname: '/admin/website/company', //一级菜单
                title: '走进兴原',
                icon: 'icon-channel-manage',
                roles: ["portal_manager_enter"],
                children: [{
                        pathname: '/admin/website/professionalTeam', //二级菜单
                        title: '专业团队', // 平台运营             
                        roles: ["portal_manager_enter_profession"],
                    },
                    {
                        pathname: '/admin/website/companyProfile', //二级菜单
                        title: '公司简介', // 平台运营                
                        roles: ["portal_manager_enter_introduce"],
                    },
                    {
                        pathname: '/admin/website/certification', //二级菜单
                        title: '荣誉资质', // 平台运营                
                        roles: ["portal_manager_enter_introduce"],
                    },
                ],
            },
            // {
            //     pathname: '/admin/website/appointmentCar', //一级菜单
            //     title: '预约申报',
            //     icon:'icon-index-archive-query',
            //     roles: "",
            //     children: [],
            // },
            {
                pathname: '/admin/website/onlineMessage', //一级菜单
                title: '在线留言',
                icon: 'icon-process-monitor',
                roles: ["portal_manager_guestbook"],
                children: [],
            },
        ]
    },
    {
        pathname: '/admin/reuse', //模块
        title: '回用件管理',
        roles: ['reuse'],
        menus: [{
                pathname: '/admin/reuse/production', //一级菜单
                title: '生产任务',
                icon: 'icon-rule-management',
                roles: ["reuse_car_manage"],
                children: [
                    {
                        pathname: '/admin/reuse/index', //一级菜单
                        title: '初判车辆',
                        icon: 'icon-rule-management',
                        roles: ["reuse_car_manage_first_identify_car"],
                        children: [],
                    },{
                        pathname: '/admin/reuse/secondCheck', //一级菜单
                        title: '精判车辆',
                        icon: 'icon-rule-management',
                        roles: ["reuse_car_manage_pro_identify_car"],
                        children: [],
                    }
                ],
            },
            {
                pathname: '/admin/reuse/dismantle', //一级菜单
                title: '拆解工单',
                icon: 'icon-rule-management',
                roles: ["reuse_disassemble"],
                children: [],
            },

            {
                pathname: '/admin/reuse/inventory', //一级菜单
                title: '库存管理',
                icon: 'icon-rule-management',
                roles: ["reuse_stock"],
                children: [{
                        pathname: '/admin/reuse/import', //一级菜单
                        title: '入库管理',
                        icon: 'icon-rule-management',
                        roles: ["reuse_in_stock"],
                        children: [],
                    },
                    {
                        pathname: '/admin/reuse/export', //一级菜单
                        title: '出库管理',
                        icon: 'icon-rule-management',
                        roles: ["reuse_out_stock"],
                        children: [],
                    },
                    {
                        pathname: '/admin/reuse/statistics', //一级菜单
                        title: '库存统计',
                        icon: 'icon-rule-management',
                        roles: ["reuse_stock_statistics"],
                        children: [],
                    },
                ],
            },
            {
                pathname: '/admin/reuse/saleManage', //一级菜单
                title: '销售单',
                icon: 'icon-rule-management',
                roles: ["reuse_sale_statistics"],
                children: [{
                    pathname: '/admin/reuse/sales', //一级菜单
                    title: '待记账',
                    icon: 'icon-rule-management',
                    roles: ["reuse_sale_statistics_wait_charge"],
                    children: [],
                },
                {
                    pathname: '/admin/reuse/waitPost', //一级菜单
                    title: '待发货',
                    icon: 'icon-rule-management',
                    roles: ["reuse_sale_statistics_wait_delivery"],
                    children: [],
                },
                {
                    pathname: '/admin/reuse/hasPost', //一级菜单
                    title: '已发货',
                    icon: 'icon-rule-management',
                    roles: ["reuse_sale_statistics_already_delivery"],
                    children: [],
                },

                ],
            },
        ]
    },
    {
        pathname: '/admin/storeHouse', //模块
        title: '库房管理',
        roles: ['web_position'],
        menus: [{
                pathname: '/admin/storeHouse/index', //一级菜单
                title: '库房管理',
                icon: 'icon-rule-management',
                roles: ["web_position_position"],
                children: [],
            },
            {
                pathname: '/admin/storeHouse/position', //一级菜单
                title: '库位设置',
                icon: 'icon-rule-management',
                roles: ["web_position_warehouse"],
                children: [],
            },
        ]
    },
    {
        pathname: '/admin/finance', //模块
        title: '财务工作台',
        roles: ['pay'],
        menus: [{
                pathname: '/admin/finance/residual', //二级菜单
                title: '残值审核', // 平台运营             
                roles: ["pay_residual_value_audit"],
            },
            // {
            //     pathname: '/admin/finance/paypre', //二级菜单
            //     title: '支付审核', // 平台运营
            //     roles: ["pay_esidual_value_pay"],
            // },
            {
                pathname: '/admin/finance/pay', //二级菜单
                title: '残值支付', // 平台运营                
                roles: ["pay_esidual_value_pay"],
            },
            {
                pathname: '/admin/finance/paySerial', //二级菜单
                title: '残值流水', // 平台运营                
                roles: ["pay_esidual_recovery_value"],
            },
            {
                pathname: '/admin/finance/entrance', //二级菜单
                title: '入场车辆', // 平台运营                
                roles: ["pay_entry_car"],
            },
            {
                pathname: '/admin/finance/deduct', //一级菜单
                title: '缺件核对',
                // icon: 'icon-open-recheck',
                roles: ['pay_esidual_short_item'],
            },
        ]
    },
    {
        pathname: '/admin/operational', //模块
        title: '证明分发',
        roles: ['prove_distribute'],
        menus: [
            {
                pathname: '/admin/operational/distribute', //一级菜单
                title: '手续文档列表',
                icon: 'icon-index-archive-task-manage',
                roles: ['prove_distribute_wait_confirm_list'],
                children: [],
            },
            {
                pathname: '/admin/operational/hasDistribute', //一级菜单
                title: '线上分发列表',
                icon: 'icon-index-archive-task-manage',
                roles: ['prove_distribute_already_wait_confirm_list'],
                children: [],
            },{
                pathname: '/admin/operational/proveOffline', //一级菜单
                title: '线下分发列表',
                icon: 'icon-index-archive-task-manage',
                roles: ['prove_distribute_offline_list'],
                children: [],
            },
        ]
    },
    {
        pathname: '/admin/commonModule', //模块
        title: '系统配置',
        roles: ['public'],
        menus: [
            {
                pathname: '/admin/commonModule/distributionCenter', //一级菜单
                title: '集散地管理',
                icon: 'icon-index-archive-task-manage',
                roles: ['public_ditribute_center'],
                children: [],
            },
            {
                pathname: '/admin/commonModule/oem', //一级菜单
                title: 'OEM配置',
                roles: ['public_oem_config'],
                icon: 'icon-open-sets',
                children: [{
                        pathname: '/admin/commonModule/oemConfig', //二级菜单
                        title: '缺件检测设置', // 平台运营
                        roles: ['public_oem_lack_config'],
                    },
                    {
                        pathname: '/admin/commonModule/oemExtra', //二级菜单
                        title: '额外检测设置', // 平台运营
                        roles: ['public_oem_extra_config'],
                    },
                ]
            },
            {
                pathname: '/admin/commonModule/storageRoom', //一级菜单
                title: '库房设置',
                icon: 'icon-marketing-channels1',
                roles: ['web_warehouse_config'],
                children: [],
            },
            {
                pathname: '/admin/commonModule/priceControl', //二级菜单
                title: '回收价格管理', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['config_price_manage'],
            },
            {
                pathname: '/admin/commonModule/carCategory', //二级菜单
                title: '车辆类型管理', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['web_car_type_manage'],
            },
            {
                pathname: '/admin/commonModule/payLimit', //二级菜单
                title: '支付限额配置', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['config_limit'],
            },
            {
                pathname: '/admin/commonModule/checkSubsidy', //二级菜单
                title: '查验补贴设置', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['web_check_inspection_subsidy'],
            },
            {
                pathname: '/admin/commonModule/remoteFreight', //二级菜单
                title: '远距离运费管理', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['web_check_distance_freight_config'],
            },
            {
                pathname: '/admin/commonModule/ReuseCategory', //二级菜单
                title: '回用件类别管理', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['web_reuse_car_part_type_category_config'],
            },
            {
                pathname: '/admin/commonModule/ReuseQuality', //二级菜单
                title: '回用件等级管理', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['web_reuse_car_part_level_config'],
            },
            {
                pathname: '/admin/commonModule/logManager', //二级菜单
                title: '日志管理', // 平台运营
                icon: 'icon-index-archive-task-manage',
                roles: ['web_log_config'],
            },
            {
                pathname: '/admin/commonModule/bocdpayConfig', //二级菜单
                title: '成都银行支付管理', // 成都银行支付管理
                icon: 'icon-index-archive-task-manage',
                roles: ['common_bocdpay_config'],
            },
        ]
    }
];