/**
 * @description: 系统配置
 * @author: 漆红 2020-11-5
 */

import Axios from "axios"
import ajax from './index'
import request from './request'
import qs from "qs"


// 集散地管理 - 集散地列表
export const getDistributeCenterPage = (params) => {
    return ajax.post(`/v1/distributeCenter/getDistributeCenterPage`, params)
}

// 集散地管理 - 删除集散地
export const deleteDistributeCenter = (params) => {
    return ajax.get(`/v1/distributeCenter/deleteDistributeCenter/${params}`)
}

// 集散地管理 - 添加集散地
export const addDistributeCenter = (params) => {
    return ajax.post(`/v1/distributeCenter/addDistributeCenter`, params)
}

// 获取集散地列表
export const getDistributeCenterList = (params) => {
    return request.get(`/v1/distributeCenter/getDistributeCenterList`)
}

// 集散地管理 - 修改集散地
export const updateDistributeCenter = (params) => {
    return ajax.post(`/v1/distributeCenter/updateDistributeCenter`, params)
}

// 集散地管理 - 集散地详情
export const distributeCenterDetail = (params) => {
    return ajax.get(`/v1/distributeCenter/queryDistributeCenterDetail/${params}`)
}

// 集散地管理 - 启用禁用
export const changeEnable = (params) => {
    return ajax.get(`/v1/distributeCenter/changeEnable/${params.id}/${params.enable}`)
}

// 上传图片
export const uploadImg = (params) => {
    return ajax.post(`/xyidc-basic/v1/file/upload`, params)
}

// 仓库管理 - 列表
export const listWareHouse = (params) => {
    return ajax.post(`/v1/wareHouse/listWareHouse`, params)
}

// 仓库管理 - 新增
export const addOrUpdateWareHouse = (params) => {
    return ajax.post(`/v1/wareHouse/addOrUpdateWareHouse`, params)
}

// 仓库管理 - 详情
export const getWareHouse = (id) => {
    return ajax.get(`/v1/wareHouse/getWareHouse/${id}`)
}

// 仓库管理 - 删除
export const deleteWareHouse = (id) => {
    return ajax.get(`/v1/wareHouse/deleteWareHouse/${id}`)
}

// 仓库管理 - 共用列表
export const getWareHouseList = (params) => {
    return ajax.get(`/v1/wareHouse/getWareHouseList`, params)
}

// 报废系统 -> 第三方车辆信息列表 -> 获取车辆类型
export const getCarTypeList = () => {
    return request.get(`/v1/carType/getCarTypeSelectList`)
}

//系统配置-12901_获取区域划分列表
export const getCarRegionDivided = () => {
    return request.get(`/v1/carRegionDivided/getAllCarRegionDivided`)
}

//12902_添加/更新区域划分
export const addCarRegionDivided = (params) => {
    return ajax.post(`/v1/carRegionDivided/addCarRegionDivided`, params)
}

//删除区域划
export const removeCarRegionDivided = (params) => {
    return ajax.post(`/v1/carRegionDivided/removeCarRegionDivided/${params.id}`, params)
}


//获取行政区域
export const getCheckCarRegionList = () => {
    return request.get(`/v1/carRegion/getCheckCarRegionList`)
}

//绑定区域
export const addCheckCarRegion = (params) => {
    return ajax.post(`/v1/carRegion/addCheckCarRegion`, params)
}

//移除当前的绑定关系
export const removeCarRegionDividedInc = (params) => {
    return ajax.post(`/v1/carRegion/removeCarRegionDivided/${params.id}`, params)
}

export function download(url, fileName, params, methods, newConfig = {}) {
    const method = methods || 'post'
    return Axios({
        method,
        url: url,
        responseType: "blob",
        headers: {
            "token": sessionStorage.getItem('authToken') || '',
            ...newConfig
        },
        data: params
    }).then(res => {
        //这里res.data是返回的blob对象
        console.log("res", res)
        let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = fileName; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
    });




}
// 拖运-基础信息-货车信息-获取公司列表
export const getDispatchCompanyList = () => {
    return request.get(`v1/checkLogisticsCompany/getDispatchCompanyList`)
}

// 回收价格-新增/修改-所有分类名称
export const showCategoryList = () => {
    return request.get(`v1/payCarCategory/showCategoryList`)
}


// v1/payCarPriceConfig/showCarPriceConfigPage


// 回收价格列表-列出所有创建人信息
export const showCreateUserList = (params) => {
    return request.post(`v1/payCarPriceConfig/showCreateUserList`,params)
}

// 回收价格列表
export const showCarPriceConfigPage = (params) => {
    return request.post(`v1/payCarPriceConfig/showCarPriceConfigPage`,params)
}

// 回收价格列表-详情
export const showCarPriceConfigDetail = (params) => {
    return request.get(`v1/payCarPriceConfig/showCarPriceConfigDetail?${qs.stringify(params)}`)
}

// 回收价格-保存或者更新价格配置详细信息
export const saveOrUpdatePriceConfig = (params) => {
    return request.post(`v1/payCarPriceConfig/saveOrUpdatePriceConfig`,params)
}

// 回收价格-删除操作
export const deleteCarPriceConfigById = (params) => {
    return request.post(`v1/payCarPriceConfig/deleteCarPriceConfigById`,params)
}

// // 获取车辆类型的列表
// export const getCPayCarCategoryList = (carPriceConfigId) => {
//     return request.get(`v1/payCarCategory/getCPayCarCategoryList`)
// }


// 9102-添加或修改车辆类型
export const addPayCarCategory = (params) => {
    return request.post(`v1/payCarCategory/addPayCarCategory`,params)
}

// 9101-获取车辆类型的列表
export const getCPayCarCategoryList = (params) => {
    return request.post(`v1/payCarCategory/getCPayCarCategoryList`,params)
}

// 9103-删除车辆分类
export const removePayCarCategory = (params) => {
    return request.post(`v1/payCarCategory/removePayCarCategory`,params)
}

// 9104-修改分类状态
export const changePayCarCategoryStatus = (params) => {
    return request.post(`v1/payCarCategory/changePayCarCategoryStatus`,params)
}

// 查询分类详情
export const showPayCarCategoryDetail = (categoryId) => {
    return request.get(`v1/payCarCategory/showPayCarCategoryDetail/${categoryId}`)
}


// OEM配置-显示分页列表
export const showMissingConfigCategoryPage = (params) => {
    return request.post(`v1/bundlingConfigCategory/showMissingConfigCategoryPage`,params)
}

// OEM配置-显示分页列表-详情
export const showMissConfigCategoryDetail = (configCategoryId) => {
    return request.get(`v1/bundlingConfigCategory/showMissConfigCategoryDetail/${configCategoryId}`)
}

// OEM配置-显示分页列表-删除
export const deleteConfigCategoryById = (params) => {
    return request.post(`v1/bundlingConfigCategory/deleteConfigCategoryById`,params)
}

// OEM配置-显示分页列表-保存或者修改
export const saveOrUpdateConfigCategoryById = (params) => {
    return request.post(`v1/bundlingConfigCategory/saveOrUpdateConfigCategoryById`,params)
}



// 获取缺件配置未被选择车辆分类
export const showSelectedCategoryList = (params) => {
    return request.get(`v1/payCarCategory/showSelectedCategoryList`)
}

// ---
// 缺件查验配置-分页列表-增加/修改缺件查验配置
export const addOrChangeMissingPartConfigItem = (params) => {
    return request.post(`v1/bundlingMissingPartConfig/addOrChangeMissingPartConfigItem`,params)
}

// 缺件查验配置-分页列表-删除
export const removeMissingPartConfigItemById = (params) => {
    return request.post(`v1/bundlingMissingPartConfig/removeMissingPartConfigItemById`,params)
}

// 缺件查验配置-分页列表
export const showMissingPartConfigItemPage = (params) => {
    return request.get(`v1/bundlingMissingPartConfig/showMissingPartConfigItemPage/${params.partConfigId}/${params.pageNumber}/${params.pageSize}`)
}

// 缺件查验配置-分页列表-详细信息
export const showMissingPartConfigItemDetail = (missingPartConfigItemId) => {
    return request.get(`v1/bundlingMissingPartConfig/showMissingPartConfigItemDetail/${missingPartConfigItemId}`)
}
// 额外检测配置
// 9901-web-分页查询额外检测项配置列表
export const showExtendConfigPageAndConfig = (params) => {
    return request.post(`v1/bundlingExtendConfig/showExtendConfigPageAndConfig`,params)
}

// 9904-web-更新或者是修改配置项目
export const saveOrUpdateBundlingExtendConfigDetail = (params) => {
    return request.post(`v1/bundlingExtendConfig/saveOrUpdateBundlingExtendConfigDetail`,params)
}

// 9902-web-根据id获取额外检测项配置详情
export const showExtendConfigDetailByConfigId = (configId) => {
    return request.get(`v1/bundlingExtendConfig/showExtendConfigDetailByConfigId/${configId}`)
}

// 9903-web-根据id删除额外检测项配置
export const deleteExtendConfigById = (configId) => {
    return request.post(`v1/bundlingExtendConfig/deleteExtendConfigById/${configId}`)
}

// 额外检测项目


//获取检测项目分页
export const showExtendConfigItemPageAndCondition = (params) => {
    return request.post(`v1/bundlingExtendConfig/showExtendConfigItemPageAndCondition`,params)
}
export const saveOrUpdateExtendConfigItem = (params) => {
    return request.post(`v1/bundlingExtendConfig/saveOrUpdateExtendConfigItem`,params)
}
export const deleteExtendConfigItemByItemId = (configItemId) => {
    return request.post(`v1/bundlingExtendConfig/deleteExtendConfigItemByItemId/${configItemId}`)
}


// web-根據erpid获取车辆的额外检测项目的详情
export const showBundlingExtendConfigDetailByErpId = (erpId) => {
    return request.get(`v1/bundlingExtendConfig/showBundlingExtendConfigDetailByErpId/${erpId}`)
}

export const showEntryInfo = (erpId) => {
    return request.get(`v1/scrape/car/showEntryInfo/${erpId}`)
}

// 分页查询限额配置
export const showPayLimitationPage = (data) => {
    return request.post(`v1/payLimitationConfig/showPayLimitationPage`,data)
}

// 查询限额配置详情
export const showLimitationConfigByOperator = (userId) => {
    return request.get(`v1/payLimitationConfig/showLimitationConfigByOperator/${userId}`)
}

// 保存或者修改支付限额配置
export const saveOrChangePayLimitationConfig = (data) => {
    return request.post(`v1/payLimitationConfig/saveOrChangePayLimitationConfig`,data)
}

// 保存或者修改支付限额配置
export const removeLimittationConfig = (data) => {
    return request.post(`v1/payLimitationConfig/removeLimittationConfig`,data)
}


// WEB-5211-用户管理-获取用户信息
export const getUserList = () => {
    return request.get(`user/getUserList`)
}


// 页查验 的补贴配置列表
export const getCheckSub = (params) => {
    return request.post(`v1/inspectionSubsidy/getCheckInspectionSubsidyListVOPage`,params)
}


// 保存或者是修贴配置
export const saveCheckSub = (params) => {
    return request.post(`v1/inspectionSubsidy/saveOrUpdateCheckInspectionSubsidy`,params)
}

// 查询贴配置详情
export const getCheckSubById = (checkInspectionSubsidyId) => {
    return request.get(`v1/inspectionSubsidy/getCheckInspectionSubsidyDetailById/${checkInspectionSubsidyId}`)
}

// 修贴配置状态
export const ChangesubsidyEnable = (params) => {
    return request.post(`v1/inspectionSubsidy/changeCheckInspectionSubsidyEnable`,params)
}

// 修贴配置状态
export const getSubCategory = (params) => {
    return request.post(`v1/inspectionSubsidy/getCheckCarCategoryCarTypeVOS`,params)
}



// 分页展示远距离运费信息
export const freightConfig = (params) => {
    return request.post(`v1/checkDistanceFreightConfig/showFreightConfigPage`,params)
}


// 保存或者是修贴配置
export const saveFreightConfig = (params) => {
    return request.post(`v1/checkDistanceFreightConfig/saveOrChangeFreightConfig`,params)
}

// 查询贴配置详情
export const getFreightConfigDetailById = (id) => {
    return request.get(`v1/checkDistanceFreightConfig/showFreightConfigDetailById/${id}`)
}


// 分页展示远距离运费信息
export const changeFreightConfigEnable = (params) => {
    return request.post(`v1/checkDistanceFreightConfig/updateFreightConfigEnableStatus`,params)
}


// 获取一级分类的下拉列表
export const getReuseCarPartTypeCategorys = (params) => {
    return request.get(`v1/reuseCarPartTypeCategory/getReuseCarPartTypeCategorys`,params)
}


// 车辆回用件类别表
export const getReuseCarPartTypePageList = (params) => {
    return request.post(`v1/carPartTypeCategory/getReuseCarPartTypePageList`,params)
}


// 新增或者是修改类别
export const saveOrUpdateReuseCarPartType = (params) => {
    return request.post(`v1/carPartTypeCategory/saveOrUpdateReuseCarPartType`,params)
}

// 根据id获取类别的详情
export const getReuseCarPartTypeDetailById = (id) => {
    return request.get(`v1/carPartTypeCategory/getReuseCarPartTypeDetailById/${id}`)
}

// 修改类型的状态
export const changeCarPartTypeStatus = (params) => {
    return request.post(`v1/carPartTypeCategory/changeCarPartTypeStatus`,params)
}

// 根据一级类型的id获取二级类型的code
export const getTypeCodeByCategory = (categoryId,assembly) => {
    return request.get(`v1/carPartTypeCategory/getTypeCodeByCategory/${categoryId}/${assembly}`)
}

//回用件等级管理
//展示 showReuseCarPartLevelPage
export const showReuseCarPartLevelPage = (params) => {
    return request.post(`v1/carPartLevelStandard/showReuseCarPartLevelPage`,params)
}
//编辑or新增saveOrChangeReuseCarPartLevel
export const saveOrChangeReuseCarPartLevel = (params) => {
    return request.post(`v1/carPartLevelStandard/saveOrChangeReuseCarPartLevel`,params)
}

//删除
export const removeLeveById = (params) => {
    return request.post(`v1/carPartLevelStandard/removeLeveById`,params)
}

//日志
export const getLogInfoPage = (params) => {
    return request.post(`v1/basicUserOperationLog/getLogInfoPage`,params)
}
//获取配置列表
export const queryPayBocdpayConfigPageResult = (params) => {
    return request.get(`/v1/t-pay-bocdpay-config/queryPayBocdpayConfigPageResult/${params.pageSize}/${params.pageNum}`)
}

//启用配置
export const enable = (params) => {
    return request.post(`/v1/t-pay-bocdpay-config/enable`,params)
}