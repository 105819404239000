
import Loadable from 'react-loadable'
import { Loading } from 'components'

const certificatesCheck = Loadable({
    loader: () => import('./certificatesCheck'),
    loading: Loading
})

const certificatesCheckPeding = Loadable({
    loader: () => import('./certificatesCheck/pending.js'),
    loading: Loading
})

const certificatesCheckAccept = Loadable({
    loader: () => import('./certificatesCheck/accept.js'),
    loading: Loading
})


const certificatesCheckConfirmed = Loadable({
    loader: () => import('./certificatesCheck/confirmed.js'),
    loading: Loading
})


const check = Loadable({
    loader: () => import('./certificatesCheck/check'),
    loading: Loading
})

const carProblem = Loadable({
    loader: () => import('./carProblem'),
    loading: Loading
})
const carProblemPending = Loadable({
    loader: () => import('./carProblem/pending.js'),
    loading: Loading
})
const carProblemAccept = Loadable({
    loader: () => import('./carProblem/accept.js'),
    loading: Loading
})

const recoveryCar = Loadable({
    loader: () => import('./carCheck/recoveryCar'),
    loading: Loading
})
const recoveryCarPadding = Loadable({
    loader: () => import('./carCheck/padding.js'),
    loading: Loading
})

const unweighted = Loadable({
    loader: () => import('./WeighingManagement/unweighted/accept'),
    loading: Loading
})

const weightRecord = Loadable({
    loader: () => import('./WeighingManagement/WeighingRecord/accept'),
    loading: Loading
})

const Reportform = Loadable({
    loader: () => import('./DeductionMissParts/Reportform/accept'),
    loading: Loading
})

const Responsibility = Loadable({
    loader: () => import('./DeductionMissParts/Responsibility'),
    loading: Loading
})

const ResponsibilityPadding = Loadable({
    loader: () => import('./DeductionMissParts/Responsibility/pending'),
    loading: Loading
})

const ResponsibilityAccept = Loadable({
    loader: () => import('./DeductionMissParts/Responsibility/accept'),
    loading: Loading
})

const confirmResponsibility = Loadable({
    loader: () => import('./DeductionMissParts/Responsibility/confirmResponsibility'),
    loading: Loading
})

const checkCarDetails = Loadable({
    loader: () => import('./carCheck/checkCarDetails'),
    loading: Loading
})

export {
    certificatesCheck,
    certificatesCheckPeding,
    carProblem,
    carProblemPending,
    carProblemAccept,
    recoveryCar,
    unweighted,
    weightRecord,
    Responsibility,
    ResponsibilityPadding,
    ResponsibilityAccept,
    Reportform,
    confirmResponsibility,
    checkCarDetails,
    check,
    recoveryCarPadding,
    certificatesCheckAccept,
    certificatesCheckConfirmed
}