/**
 * @description: 回用件
 * @author: louis 
 */

import Loadable from 'react-loadable'
import { Loading } from 'components'

const StoreHoseManage = Loadable({
    loader: () =>
        import ('./StoreHouse/HouseManage'),
    loading: Loading
})
const PositionManage = Loadable({
    loader: () =>
        import ('./StoreHouse/PositionManage'),
    loading: Loading
})
const PositionPrint = Loadable({
    loader: () =>
        import ('./StoreHouse/PositionManage/print'),
    loading: Loading
})

const ReuseManage = Loadable({
    loader: () =>
        import ('./Manage'),
    loading: Loading
})
const ReuseDetail = Loadable({
    loader: () =>
        import ('./Manage/detail'),
    loading: Loading
})

const ReuseSales = Loadable({
    loader: () =>
        import ('./sales'),
    loading: Loading
})

const ReuseSalesDetail = Loadable({
    loader: () =>
        import ('./sales/detail'),
    loading: Loading
})



const ReuseDismantle = Loadable({
    loader: () =>
        import ('./Dismantle'),
    loading: Loading
})

const ReuseDismantleDetail = Loadable({
    loader: () =>
        import ('./Dismantle/detail'),
    loading: Loading
})
const ReuseImport = Loadable({
    loader: () =>
        import ('./Inventory/import'),
    loading: Loading
})

const ReuseImportDetail = Loadable({
  loader: () => import('./Inventory/importDetail'),
  loading: Loading
})

const ReuseExport = Loadable({
    loader: () =>
        import ('./Inventory/export'),
    loading: Loading
})

const ReuseExportDetail = Loadable({
  loader: () => import('./Inventory/exportDetail'),
  loading: Loading
})

const ReuseStatistic = Loadable({
    loader: () =>
        import ('./Inventory/statistic'),
    loading: Loading
})

const ReuseStatisticDetail = Loadable({
    loader: () =>
        import ('./Inventory/statisticDetail'),
    loading: Loading
})

const ReusePrintQr = Loadable({
    loader: () => import ('./Manage/PrintQr'),
    loading: Loading
})

const StorePrintQr = Loadable({
    loader: () => import ('./StoreHouse/PrintQr'),
    loading: Loading
})

const secondCheck = Loadable({
    loader:() => import ('./Manage/secondCheck'),
    loading:Loading
})

const waitPost = Loadable({
    loader:() => import ('./sales/waitPost'),
    loading:Loading
})

const hasPost = Loadable({
    loader:() => import ('./sales/hasPost'),
    loading:Loading
})

const   ReuseRoutes=[
    {
        pathname: '/admin/storeHouse/index', //一级菜单
        title: '库房管理',
        component: StoreHoseManage,
        exact: true

    },
    {
        pathname: '/admin/storeHouse/position', //一级菜单
        title: '库位设置',
        component: PositionManage,
        exact: true
    },
    {
        pathname: '/admin/storeHouse/print', //一级菜单
        title: '库位打印',
        component: PositionPrint,
        exact: true
    },
    {
        pathname: '/admin/reuse/index/:id', //一级菜单
        title: '回用件详情',
        component: ReuseDetail,
        exact: true
    },
    {
        pathname: '/admin/reuse/index', //一级菜单
        title: '初判车辆',
        component: ReuseManage,
        exact: true
    },{
        pathname: '/admin/reuse/secondCheck', //一级菜单
        title: '精判车辆',
        component: secondCheck,
        exact: true   
    },
    {
        pathname: '/admin/reuse/dismantle/:id', //一级菜单
        title: '拆解工单',
        component: ReuseDismantleDetail,
        exact: true
    },
    {
        pathname: '/admin/reuse/dismantle', //一级菜单
        title: '拆解工单',
        component: ReuseDismantle,
        exact: true
    },
    
    
    {
        pathname: '/admin/reuse/import/detail', //一级菜单
        title: '入库详情',
        component: ReuseImportDetail,
        exact: true
    },
    {
        pathname: '/admin/reuse/sales', //一级菜单
        title: '待记账',
        component: ReuseSales,
        exact: true
    },{
        pathname: '/admin/reuse/waitPost', //一级菜单
        title: '待发货',
        component: waitPost,
        exact: true
    },{
        pathname: '/admin/reuse/hasPost', //一级菜单
        title: '已发货',
        component: hasPost,
        exact: true
    },
    {
        pathname: '/admin/reuse/salesDetail/:id', //一级菜单
        title: '销售单详情',
        component: ReuseSalesDetail,
        exact: true
    },
    {
        pathname: '/admin/reuse', //一级菜单
        title: '回用件管理',
        icon: 'icon-rule-management',
        component: ReuseImport,
        exact: true
        
    },
    {
        pathname: '/admin/reuse/import', //一级菜单
        title: '入库管理',
        icon: 'icon-rule-management',
        component: ReuseImport,
        exact: true

    },
    
    {
        pathname: '/admin/reuse/export/:id', //一级菜单
        title: '出库详情',
        component: ReuseExportDetail,
        exact: true
    },
    {
        pathname: '/admin/reuse/export', //一级菜单
        title: '出库管理',
        component: ReuseExport,
        exact: true
    },
    {
        pathname: '/admin/reuse/statistics', //一级菜单
        title: '库存统计',
        icon: 'icon-rule-management',
        component: ReuseStatistic,
        exact: true
    },
    {
        pathname: '/admin/reuse/statistics/detail', //一级菜单
        title: '库存统计详情',
        icon: 'icon-rule-management',
        component: ReuseStatisticDetail,
        exact: true
    },
]

export {ReuseRoutes, ReusePrintQr, StorePrintQr}
