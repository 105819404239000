import { observable, action, makeObservable } from "mobx"
import { getDeductMoneyReport } from 'requests/entranceInspection'

class weighingRecord {
    constructor() {
        makeObservable(this)
    }
    // 残值款认定人
    @observable pendingList = []
    @observable pendingPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable pendingCondition = {
        "checkTime": "",
		"licencePlate": "",
		"responsibilityTime": "",
		"responsibilityUserName": "",
		"responsibilityUserType": 1
    }

    // 拖车司机
    @observable acceptList = []
    @observable acceptPageModal = {
        page: 1,
        pageSize: 10,
        total: 0
    }
    @observable acceptCondition = {
        "checkTime": "",
		"licencePlate": "",
		"responsibilityTime": "",
		"responsibilityUserName": "",
		"responsibilityUserType": 2
    }

    // 残值款认定人
    @action getPendingList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.pendingPageModal

        getDeductMoneyReport({
            pageNum: page,
            pageSize,
            condition: condition || this.pendingCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.pendingList = resp.data ? resp.data : [];
                this.pendingPageModal = resp.pageModal
            }
        })
    }

    // 拖车司机
    @action getAcceptList(pageModal, condition) {
        const { page, pageSize } = pageModal || this.acceptPageModal

        getDeductMoneyReport({
            pageNum: page,
            pageSize,
            condition: condition || this.acceptCondition
        }).then(resp => {
            if (resp && !resp.code) {
                this.acceptList = resp.data ? resp.data : [];
                this.acceptPageModal = resp.pageModal
            }
        })
    }

    //  重置或查询 修改condition
    @action resetForm = (condition, key) => {
        if (key === 1) {
            this.pendingCondition = {
                ...condition,
                responsibilityUserType: 1
            }
            this.pendingPageModal = {
                page: 1,
                pageSize: 10
            }
        }
        if (key === 2) {
            this.acceptCondition = {
                ...condition,
                responsibilityUserType: 2
            }
            this.acceptPageModal = {
                page: 1,
                pageSize: 10
            }
        }
    }
}

export default weighingRecord